import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICreateStatus, IStatus } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

export function StatusForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, txtAlerta, colorAlerta, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [nome, setNome] = useState("");
  const [ordem, setOrdem] = useState(0);

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0)
      return;

    setLoading(true);
    setTxtLoading("Carregando cadastrado");

    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`status/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IStatus;

        setNome(data.nome);
        setOrdem(data.ordem);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  };

  const handleFocusOutOrdem = (v: number) => {
    if (v > 9999)
      setOrdem(9999);

    if (v < 0)
      setOrdem(0);
  }

  const handleSalvar = async () => {
    if (nome.length <= 0)
      return;

    setLoading(true);
    setTxtLoading("Salvando status...");

    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let data: ICreateStatus = {
      nome,
      ordem
    };

    if (tipo === "Edit")
      data = { ...data, id: params.id };

    await api.post("status", { ...data })
      .then((result) => {
        setAlert("Status salvo com sucesso", "success");
        navigate("/status");
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  useEffect(() => {
    loadCadastro();
  }, [token]);

  return (
    <Topo page="Status" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Status`}>
      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form">
          <div className="contentInput" style={{ width: "75%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Ordem:</span>
            <Input
              mask="none"
              value={ordem}
              inputMaskChange={setOrdem}
              placeholder="Ordem"
              type="number"
              onBlur={(e) => handleFocusOutOrdem(parseInt(e.target.value))}
            />
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>
        </div>
      )}

    </Topo>
  );
}