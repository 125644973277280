import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICidades, IClientes, ICreateClientesDTO } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { maskCep, maskCpfCnpj, maskPhone } from "../../utils/masks";
import Modal from "react-modal";
import { ModalCidades } from "../../modal/ModalCidades";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../Input";
import { Load } from "../Load";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IProps = {
  tipo: "Novo" | "Edit";
  id?: string;
  onSave: (cliente: IClientes) => void;
  showBtnVoltar: boolean;
}

export function CadClientes({ tipo, id, onSave, showBtnVoltar }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, cacheCidades, saveCacheCidades, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [listCidades, setListCidades] = useState<ICidades[]>([]);
  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCidades, setModalCidades] = useState(false);
  const [cidadeSelected, setCidadeSelected] = useState<ICidades>({} as ICidades);

  const [fantasia, setFantasia] = useState("");
  const [razao, setRazao] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [rgIe, setRgIe] = useState("");
  const [email, setEmail] = useState("");
  const [cidade, setCidade] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [favorito, setFavorito] = useState(false);

  const handleSelectCidade = (cidade: ICidades) => {
    setCidadeSelected(cidade);
    setCidade(`${cidade.id}`);
  }

  const loadCidades = async () => {

    if (!token)
      return;

    if (cacheCidades.length > 0) {
      setListCidades(cacheCidades);
      loadCadastro();
      return;
    }

    setLoading(true);
    setTxtLoading("Carregando cidades...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("cidades/list")
      .then((result) => {
        if (!result.data)
          return;

        setListCidades(result.data);
        saveCacheCidades(result.data);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadCadastro();
      })
  };

  const loadCadastro = async () => {
    if (tipo === "Novo" || !id || id.length <= 0) {
      setLoading(false);
      setTxtLoading("");
      return;
    }

    setTxtLoading("Carregando cadastro...");

    await api.get(`clientes/${id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IClientes;

        setFantasia(data.fantasia);
        setRazao(data.razao_social);
        setCpfCnpj(maskCpfCnpj(`${data.cpf_cnpj}`));
        setRgIe(`${data.rg_ie}`);
        setEmail(data.email);
        setCidade(`${data.id_cidades}`);
        setEndereco(data.endereco);
        setNumero(`${data.numero}`);
        setComplemento(data.complemento);
        setBairro(data.bairro);
        setCep(maskCep(`${data.cep}`));
        setTelefone(maskPhone(`${data.telefone}`));
        setCelular(maskPhone(`${data.celular}`));
        setFavorito(data.favorito);

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    if (fantasia.length <= 0) return alert("Preencha o campo Nome Fantasia");
    if (razao.length <= 0) return alert("Preencha o campo Razão Social");
    if (cpfCnpj.length <= 0) return alert("Preencha seu CPF/CNPJ");
    if (rgIe.length <= 0) return alert("Preencha seu RG/IE");
    if (cidade.length <= 0) return alert("Preencha sua Cidade");
    if (endereco.length <= 3) return alert("Preencha seu Endereço corretamente");
    if (bairro.length <= 3) return alert("Preencha seu Bairro corretamente");
    if (cep.length != 9) return alert("Preencha seu CEP corretamente");

    setLoading(true);
    setTxtLoading("Salvando dados do Cliente...")

    let data: ICreateClientesDTO = {
      id_cidades: parseInt(cidade),
      fantasia,
      razao_social: razao,
      cpf_cnpj: parseInt(cpfCnpj.replace(/[^0-9]/g, "")),
      rg_ie: parseInt(rgIe.replace(/[^0-9]/g, "")),
      email,
      telefone: telefone.length > 0 ? parseInt(telefone.replace(/[^0-9]/g, "")) : 0,
      celular: celular.length > 0 ? parseInt(celular.replace(/[^0-9]/g, "")) : 0,
      endereco,
      numero: parseInt(numero),
      complemento,
      bairro,
      cep,
      favorito
    };

    if (tipo === "Edit")
      data = { ...data, id: id };

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let newCliente = {} as IClientes;

    await api.post("clientes", { ...data })
      .then((result) => {
        if (!result.data)
          return;

        newCliente = result.data;
      })
      .catch(async (err) => {
        // console.log(err.response.data.message);
        if (err.response.data.status != 401) {
          setAlert(err.response.data.message, "danger");

          if (err.response.data.message === "Cliente já cadastrado") {
            const cpfTemp = parseInt(cpfCnpj.replace(/[^0-9]/g, ""));
            await api.get(`clientes/cpf/${cpfTemp}`)
              .then((rs) => {
                if (!rs.data)
                  return;

                const data = rs.data as IClientes;
                alert(`CPF/CNPJ já cadastrado com o nome: ${data.fantasia}`);
              })
              .catch((err) => {
                if (err.response.data.status != 401)
                  setAlert(err.response.data.message, "danger");
              });
          }
        }
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        // navigate(-1);

        if (newCliente.id)
          onSave(newCliente);

      })
  }

  useEffect(() => {
    loadCidades();
  }, [token]);

  useEffect(() => {
    if (listCidades.length > 0 && cidade.length > 0) {
      let tempCidade: ICidades = {} as ICidades;

      listCidades.filter(i => `${i.id}` === cidade).map((item) => {
        tempCidade = item;
      });

      if (tempCidade.nome)
        handleSelectCidade(tempCidade);
    }
  }, [listCidades, cidade]);


  return (
    <div>
      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form">

          <div className="contentInput" style={{ width: "50%" }}>
            <span>Razão Social:</span>
            <Input
              mask="none"
              value={razao}
              inputMaskChange={setRazao}
              placeholder="Razão Social"
            />
          </div>

          <div className="contentInput" style={{ width: "50%" }}>
            <span>Fantasia:</span>
            <Input
              mask="none"
              value={fantasia}
              inputMaskChange={setFantasia}
              placeholder="Nome Fantasia"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>CNPJ/CPF:</span>
            <Input
              mask="cpf_cnpj"
              value={cpfCnpj}
              inputMaskChange={setCpfCnpj}
              placeholder="CNPJ / CPF"
              maxLength={18}
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>IE/RG:</span>
            <Input
              mask="none"
              value={rgIe}
              inputMaskChange={setRgIe}
              placeholder="IE / RG"
            />
          </div>

          <div className="contentInput" style={{ width: "34%" }}>
            <span>E-mail:</span>
            <Input
              mask="none"
              value={email}
              inputMaskChange={setEmail}
              placeholder="E-mail"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>Cidade:</span>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
              <Input
                mask="none"
                value={cidadeSelected.nome ? `${cidadeSelected.nome}/${cidadeSelected.uf.uf}` : ""}
                inputMaskChange={null}
                placeholder="Cidade/SP"
                readOnly
              />
              <button className="btn-blue-dark btnSelectCidade" onClick={() => setModalCidades(true)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </button>
            </div>
          </div>

          <div className="contentInput" style={{ width: "67%" }}>
            <span>Endereço:</span>
            <Input
              mask="none"
              value={endereco}
              inputMaskChange={setEndereco}
              placeholder="Endereço"
            />
          </div>

          <div className="contentInput" style={{ width: "20%" }}>
            <span>Número:</span>
            <Input
              mask="none"
              type="number"
              min={0}
              value={numero}
              inputMaskChange={setNumero}
              placeholder="Número"
            />
          </div>

          <div className="contentInput" style={{ width: "30%" }}>
            <span>Complemento:</span>
            <Input
              mask="none"
              value={complemento}
              inputMaskChange={setComplemento}
              placeholder="Complemento"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Bairro:</span>
            <Input
              mask="none"
              value={bairro}
              inputMaskChange={setBairro}
              placeholder="Bairro"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>CEP:</span>
            <Input
              mask="cep"
              value={cep}
              inputMaskChange={setCep}
              placeholder="CEP"
              maxLength={9}
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Telefone:</span>
            <Input
              mask="phone"
              value={telefone}
              inputMaskChange={setTelefone}
              placeholder="Telefone"
              maxLength={15}
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Celular:</span>
            <Input
              mask="phone"
              value={celular}
              inputMaskChange={setCelular}
              placeholder="Celular"
              maxLength={15}
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Favorito:</span>
            <select value={favorito ? "1" : "0"} onChange={(e) => setFavorito(e.target.value === "1" ? true : false)}>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            {showBtnVoltar && (
              <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faUndo} />
                CANCELAR
              </button>
            )}

          </div>

        </div>
      )}

      <Modal isOpen={modalCidades} style={styleModal}>
        <ModalCidades closeModal={() => setModalCidades(false)} handleSelectCidade={handleSelectCidade} />
      </Modal>
    </div>
  );
}