import { faPen, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IStatus } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";

export function Status() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pesquisa, setPesquisa] = useState("");

  const [listStatus, setListStatus] = useState<IStatus[]>([]);

  const loadStatus = async (canPesquisa = false) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de status");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest = {};

    if (canPesquisa)
      dataRequest = { pesquisa };

    await api.post("status/findAll", { dataRequest })
      .then((response) => {
        if (!response.data)
          return;

        setListStatus(response.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const handlePesquisa = () => {
    loadStatus(true);
  }

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handleDelete = async (id: string) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir este status?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo status");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete('status', { data: { id } })
      .then((result) => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadStatus(true);
      });
  };

  useEffect(() => {
    loadStatus();
  }, [token]);

  return (
    <Topo page="Status" title="Status">
      <>
        <div className="divPesquisa">
          <Input
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa..."
            onKeyUp={handleKeyPesquisa}
          />
          <button onClick={handlePesquisa}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button className="btn-green" onClick={() => { navigate("/status/novo") }}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <div className="gridContext">
          <div className="headTable">
            <span style={{ width: "200px" }}>Nome</span>
            <span style={{ width: "150px", textAlign: "right" }}>Ordem</span>
            <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
          </div>

          <div className="contentTable">
            {loading && (
              <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
            )}

            {!loading && listStatus.length > 0 && (
              listStatus.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span style={{ width: "200px" }}>{item.nome}</span>
                  <span style={{ width: "150px", textAlign: "right" }}>{item.ordem}</span>
                  <span style={{ width: "200px", textAlign: "center" }}>
                    <button className="btn-orange" onClick={() => { navigate(`/status/${item.id}`) }}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button className="btn-red" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </span>
                </div>
              ))
            )}

          </div>

        </div>
      </>
    </Topo>
  );
}