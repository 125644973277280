import { faPlus, faSave, faTrash, faUndo, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICreateFormaPgtoBandDTO, ICreateFormaPgtoBandTaxasDTO, ICreateFormaPgtoDTO, IFormaPgto, IFormaPgtoBand, IFormaPgtoBandTaxa } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

import "./style.css";
import { FormaPgtoModalTaxas } from "../../modal/FormaPgtoModalTaxas";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

type ITempBandTaxas = {
  nrParcela: number;
  porcentagem: number;
  taxaAdiantamento: number;
}

type ITempBandeira = {
  nome: string;
  taxaRecebimento: number;
  diasRecebimento: number;
  taxas: ITempBandTaxas[];
}

export function FormaPgtoForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [loadingBand, setLoadingBand] = useState(false);
  const [txtLoadingBand, setTxtLoadingBand] = useState("");

  const [nome, setNome] = useState("");
  const [maxParcelas, setMaxParcelas] = useState(1);
  const [intervaloParcelas, setIntervaloParcelas] = useState(0);
  const [primeiraParcelaDias, setPrimeiraParcelaDias] = useState(0);
  const [tipoRecebimento, setTipoRecebimento] = useState("");
  const [tipoCaixa, setTipoCaixa] = useState("1");

  const [nomeBandeira, setNomeBandeira] = useState("");
  const [taxaRecebimento, setTaxaRecebimento] = useState(0);
  const [recebimentoDias, setRecebimentoDias] = useState(0);

  const [listBandeiras, setListBandeiras] = useState<IFormaPgtoBand[]>([]);
  const [tempListBandeiras, setTempListBandeiras] = useState<ITempBandeira[]>([]);

  const [modalBandeira, setModalBandeira] = useState(false);
  const [modalTempBandeira, setModalTempBandeira] = useState(false);

  const [bandeiraSelected, setBandeiraSelected] = useState<IFormaPgtoBand>({} as IFormaPgtoBand);
  const [tempBandeiraSelected, setTempBandeiraSelected] = useState<ITempBandeira>({} as ITempBandeira);

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0)
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando Forma de Pagamento...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`formaPgto/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IFormaPgto;

        setNome(data.nome);
        setMaxParcelas(data.max_parcelas);
        setIntervaloParcelas(data.intervalo_parcelas);
        setPrimeiraParcelaDias(data.primeira_parcela_dias);
        setTipoRecebimento(data.tipo_recebimento);
        setTipoCaixa(`${data.tipo_caixa}`);

        setListBandeiras(data.bandeiras);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleFocusOutNumber = (min: number, value: number, campo: any) => {
    if (value < min)
      campo(min);
  }

  const handleAddBandeira = () => {
    if (nomeBandeira.length < 3)
      return;

    const tempExists = tempListBandeiras.find(i => i.nome === nomeBandeira);
    const bandeiraExists = listBandeiras.find(i => i.nome === nomeBandeira);

    if (tempExists || bandeiraExists) {
      setAlert("Bandeira já cadastrada", "danger");
      return;
    }

    const newBandeira: ITempBandeira = {
      nome: nomeBandeira,
      taxaRecebimento: taxaRecebimento,
      diasRecebimento: recebimentoDias,
      taxas: []
    };

    setTempListBandeiras(i => [newBandeira, ...i]);
    setNomeBandeira("");
    setTaxaRecebimento(0);
    setRecebimentoDias(0);
  }

  const handleDelTempBandeira = (item: ITempBandeira) => {
    if (!window.confirm(`Deseja realmente remover ${item.nome}?`))
      return;

    const newList: ITempBandeira[] = [];

    tempListBandeiras.map((rs) => {
      if (rs.nome !== item.nome)
        newList.push(rs);
    })

    setTempListBandeiras(newList);
  }

  const handleDelBandeira = async (item: IFormaPgtoBand) => {
    if (!token) return;

    if (!window.confirm(`Deseja realmente excluir ${item.nome}`))
      return;

    setLoadingBand(true);
    setTxtLoading("Excluindo bandeira...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("formaPgto/bandeiras", { data: { id: item.id } })
      .then(() => {
        const newList: IFormaPgtoBand[] = [];

        listBandeiras.map((rs) => {
          if (rs.id !== item.id)
            newList.push(rs);
        })

        setListBandeiras(newList);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoadingBand(false);
        setTxtLoadingBand("");
      })

  }

  const handleOpenModalBandeira = (item: IFormaPgtoBand) => {
    setBandeiraSelected(item);
    setModalBandeira(true);
  }

  const handleOpenModalTempBandeira = (item: ITempBandeira) => {
    setTempBandeiraSelected(item);
    setModalTempBandeira(true);
  }

  const handleAddTempTaxa = (taxa: ITempBandTaxas) => {
    const exists = tempListBandeiras.find(i => i.nome === tempBandeiraSelected.nome)?.taxas.find(i => parseInt(`${taxa.nrParcela}`) === parseInt(`${i.nrParcela}`));

    if (exists)
      return;

    const newListTempBandeira: ITempBandeira[] = [];

    tempListBandeiras.map((item) => {
      if (item.nome === tempBandeiraSelected.nome) {
        const band = {
          ...item,
          taxas: [...item.taxas, taxa]
        };
        newListTempBandeira.push(band);
        setTempBandeiraSelected(band);
      } else
        newListTempBandeira.push(item);
    });

    setTempListBandeiras(newListTempBandeira);
  }

  const handleAddTaxa = (taxa: ICreateFormaPgtoBandTaxasDTO) => {
    const tempExists = listBandeiras.find(i => i.id === bandeiraSelected.id)?.newTaxas?.find(i => parseInt(`${i.nr_parcela}`) === parseInt(`${taxa.nr_parcela}`));
    const exists = listBandeiras.find(i => i.id === bandeiraSelected.id)?.taxas.find(i => parseInt(`${i.nr_parcela}`) === parseInt(`${taxa.nr_parcela}`));

    if (tempExists || exists)
      return;

    const newList: IFormaPgtoBand[] = [];

    listBandeiras.map((item) => {
      if (item.id === bandeiraSelected.id) {
        const oldNewTaxas: ICreateFormaPgtoBandTaxasDTO[] = item.newTaxas ? item.newTaxas : [];
        const band = {
          ...item,
          newTaxas: [...oldNewTaxas, taxa]
        };
        newList.push(band);
        setBandeiraSelected(band);
      } else
        newList.push(item);
    })

    setListBandeiras(newList);
  }

  const handleDelTaxa = async (taxa: IFormaPgtoBandTaxa | null, tempTaxa: ICreateFormaPgtoBandTaxasDTO | null) => {
    const newList: IFormaPgtoBand[] = [];

    if (taxa !== null) {
      await api.delete("formaPgto/taxas", { data: { id: taxa.id } })
        .then(() => {

          listBandeiras.map((item) => {
            if (item.id === bandeiraSelected.id) {
              const newListTaxa: IFormaPgtoBandTaxa[] = [];

              item.taxas.map((rs) => {
                if (rs.id !== taxa.id)
                  newListTaxa.push(rs);
              });

              const band = { ...item, taxas: newListTaxa };
              newList.push(band);
              setBandeiraSelected(band);
            } else
              newList.push(item);
          })

          setListBandeiras(newList);
        })
        .catch((err) => {
          if (err.response.status !== 401)
            alert(err.response.data.message);
        });
    } else if (tempTaxa !== null) {
      listBandeiras.map((item) => {
        if (item.id === bandeiraSelected.id) {
          const newListTaxa: ICreateFormaPgtoBandTaxasDTO[] = [];

          item.newTaxas?.map((rs) => {
            if (rs.nr_parcela !== tempTaxa.nr_parcela)
              newListTaxa.push(rs);
          });

          const band = { ...item, newTaxas: newListTaxa };
          newList.push(band);
          setBandeiraSelected(band);
        } else
          newList.push(item);
      });
      setListBandeiras(newList);
    }
  }

  const handleDelTempTaxa = (taxa: ITempBandTaxas) => {
    const newList: ITempBandeira[] = [];

    tempListBandeiras.map((item) => {
      if (item.nome === tempBandeiraSelected.nome) {

        const newListTaxa: ITempBandTaxas[] = [];

        item.taxas.map((rs) => {
          if (rs.nrParcela !== taxa.nrParcela)
            newListTaxa.push(rs);
        });

        const band = { ...item, taxas: newListTaxa };

        newList.push(band);
        setTempBandeiraSelected(band);
      } else
        newList.push(item);
    });

    setTempListBandeiras(newList);
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Salvando Forma de Pagamento");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    try {
      let data: ICreateFormaPgtoDTO = {
        nome,
        intervalo_parcelas: intervaloParcelas,
        max_parcelas: maxParcelas,
        primeira_parcela_dias: primeiraParcelaDias,
        tipo_recebimento: tipoRecebimento,
        tipo_caixa: parseInt(tipoCaixa)
      };

      if (tipo === "Edit")
        data = { ...data, id: params.id };

      const requestFormaPgto = await api.post("formaPgto", { ...data });

      if (!requestFormaPgto.data)
        return;

      let formaPgto: IFormaPgto = requestFormaPgto.data;

      const dataReqBandeiras: ICreateFormaPgtoBandDTO[] = [];

      tempListBandeiras.map((item) => {
        dataReqBandeiras.push({
          id_forma_pgto: formaPgto.id,
          nome: item.nome,
          recebimento_dias: item.diasRecebimento,
          taxa_recebimento: item.taxaRecebimento
        });
      })

      setTxtLoading("Salvando Bandeiras...");
      await api.post("formaPgto/bandeiras", { data: dataReqBandeiras });

      setTimeout(async () => {
        setTxtLoading("Recuperando informações da forma de pgto...");
        const reqGetFormaPgto = await api.get(`formaPgto/${formaPgto.id}`);
        formaPgto = reqGetFormaPgto.data;

        const dataReqBandeirasTaxas: ICreateFormaPgtoBandTaxasDTO[] = [];

        console.log(formaPgto.bandeiras);

        tempListBandeiras.map((item) => {
          const dbBandeira = formaPgto.bandeiras.find(i => i.nome === item.nome);
          if (dbBandeira) {
            item.taxas.map((rs) => {
              dataReqBandeirasTaxas.push({
                id_forma_pgto_band: dbBandeira.id,
                nr_parcela: rs.nrParcela,
                porcentagem: rs.porcentagem,
                taxa_adiantamento: rs.taxaAdiantamento
              });
            });
          }
        });

        listBandeiras.map((item) => {
          if (item.newTaxas) {
            item.newTaxas.map((rs) => {
              dataReqBandeirasTaxas.push({
                id_forma_pgto_band: item.id,
                nr_parcela: rs.nr_parcela,
                porcentagem: rs.porcentagem,
                taxa_adiantamento: rs.taxa_adiantamento
              });
            })
          }
        })

        setTxtLoading("Salvando taxas...");
        await api.post("formaPgto/taxas", { data: dataReqBandeirasTaxas });

        navigate("/formaPgto");
        setAlert("Forma de Pagamento salvo com sucesso", "success");
      }, 1000);

    } catch (err) {
      console.log(err);
      const teste: any = err;

      if (teste.response.data.message)
        setAlert(teste.response.data.message, "danger");
      else
        setAlert("Erro ao salvar Forma de Pagamento", "danger");

      setLoading(false);
      setTxtLoading("");
    }

  }

  useEffect(() => {
    loadCadastro();
  }, [token]);

  return (
    <Topo page="FormaPgto" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Forma de Pagamento`}>
      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form" id="divFormaPgtoForm">
          <div className="contentInput" style={{ width: "75%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome"
            />
          </div>
          <div className="contentInput" style={{ width: "25%" }}>
            <span>Tipo Recebimento:</span>
            <select value={tipoRecebimento} onChange={(e) => setTipoRecebimento(e.target.value)}>
              <option value="">Selecione</option>
              <option value="parcial">Parcial</option>
              <option value="total">Total</option>
            </select>
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Tipo Caixa:</span>
            <select value={tipoCaixa} onChange={(e) => setTipoCaixa(e.target.value)}>
              <option value="1">Dinheiro</option>
              <option value="2">Cartão de Crédito</option>
              <option value="3">Cartão de Débito</option>
              <option value="4">Outros</option>
            </select>
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Máximo de Parcelas:</span>
            <Input
              mask="none"
              value={maxParcelas}
              inputMaskChange={setMaxParcelas}
              placeholder="Máximo de parcelas"
              type="number"
              min={1}
              onBlur={(e) => handleFocusOutNumber(1, parseInt(e.target.value), setMaxParcelas)}
            />
          </div>
          <div className="contentInput" style={{ width: "25%" }}>
            <span>Intervalo entre Parcelas:</span>
            <Input
              mask="none"
              value={intervaloParcelas}
              inputMaskChange={setIntervaloParcelas}
              placeholder="Intervalo entre Parcelas"
              type="number"
              min={1}
              onBlur={(e) => handleFocusOutNumber(1, parseInt(e.target.value), setIntervaloParcelas)}
            />
          </div>
          <div className="contentInput" style={{ width: "25%" }}>
            <span>Primeira Parcela (dias):</span>
            <Input
              mask="none"
              value={primeiraParcelaDias}
              inputMaskChange={setPrimeiraParcelaDias}
              placeholder="Primeira parcela (dias)"
              type="number"
              min={0}
              onBlur={(e) => handleFocusOutNumber(0, parseInt(e.target.value), setPrimeiraParcelaDias)}
            />
          </div>

          <h3 className="contentInput" style={{ width: "100%", marginTop: "15px" }}>Bandeiras</h3>

          <div className="contentInput" style={{ width: "40%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nomeBandeira}
              inputMaskChange={setNomeBandeira}
              placeholder="Nome"
            />
          </div>

          <div className="contentInput" style={{ width: "30%" }}>
            <span>Taxa Recebimento:</span>
            <Input
              mask="none"
              value={taxaRecebimento}
              inputMaskChange={setTaxaRecebimento}
              placeholder="Taxa de Recebimento"
              type="number"
              min={0}
              onBlur={(e) => handleFocusOutNumber(0, parseInt(e.target.value), setTaxaRecebimento)}
            />
          </div>

          <div className="contentInput" style={{ width: "30%" }}>
            <span>Dias Recebimento (T.R.: Total):</span>
            <Input
              mask="none"
              value={recebimentoDias}
              inputMaskChange={setRecebimentoDias}
              placeholder="Taxa de Recebimento"
              type="number"
              min={0}
              onBlur={(e) => handleFocusOutNumber(0, parseInt(e.target.value), setRecebimentoDias)}
            />
          </div>

          <div className="contentInput" style={{ width: "100%" }}>
            <button className="btn-green btnAdd" onClick={() => handleAddBandeira()}>
              <FontAwesomeIcon icon={faPlus} />
              Adicionar
            </button>
          </div>

          <div className="contentInput" style={{ width: "100%" }}>
            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "200px" }}>Nome</span>
                <span style={{ width: "150px", textAlign: "right" }}>Taxa Recebimento</span>
                <span style={{ width: "150px", textAlign: "right" }}>Dias Recebimento</span>
                <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable" style={{ minHeight: "50px" }}>
                {loadingBand && (
                  <Load txtLoading={txtLoadingBand} options={{ height: 120, width: 120 }} />
                )}

                {!loadingBand && (
                  <>
                    {tempListBandeiras.map((item, i) => (
                      <div className="rowTable" key={`nc-${i}`}>
                        <span style={{ minWidth: "200px" }}>{item.nome}</span>
                        <span style={{ width: "150px", textAlign: "right" }}>{item.taxaRecebimento}%</span>
                        <span style={{ width: "150px", textAlign: "right" }}>{item.diasRecebimento}</span>
                        <span className="divBtns">
                          <button className="btn-red" onClick={() => handleDelTempBandeira(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button className="btn-blue-dark" onClick={() => handleOpenModalTempBandeira(item)}>
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </button>
                        </span>
                      </div>
                    ))}

                    {listBandeiras.map((item) => (
                      <div className="rowTable" key={item.id}>
                        <span style={{ width: "200px" }}>{item.nome}</span>
                        <span style={{ width: "150px", textAlign: "right" }}>{item.taxa_recebimento}%</span>
                        <span style={{ width: "150px", textAlign: "right" }}>{item.recebimento_dias}</span>
                        <span className="divBtns">
                          <button className="btn-red" onClick={() => handleDelBandeira(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button className="btn-blue-dark" onClick={() => handleOpenModalBandeira(item)}>
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </button>
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </div>

            </div>
          </div>


          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={() => handleSalvar()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>

          <Modal isOpen={modalBandeira} style={styleModal}>
            <FormaPgtoModalTaxas
              closeModal={() => setModalBandeira(false)}
              bandeira={bandeiraSelected}
              addTempTaxa={() => { }}
              addTaxa={(t) => handleAddTaxa(t)}
              delTaxa={(t, tt) => handleDelTaxa(t, tt)}
              delTempTaxa={() => { }}
            />
          </Modal>

          <Modal isOpen={modalTempBandeira} style={styleModal}>
            <FormaPgtoModalTaxas
              closeModal={() => setModalTempBandeira(false)}
              bandeira={tempBandeiraSelected}
              addTempTaxa={(t) => handleAddTempTaxa(t)}
              addTaxa={() => { }}
              delTaxa={(t, tt) => handleDelTaxa(t, tt)}
              delTempTaxa={(t) => handleDelTempTaxa(t)}
            />
          </Modal>

        </div>
      )}

    </Topo>
  )
}