import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { ICreateNfeDTO, ICreateNfePgtosDTO, ICreateNfeProdutosDTO, IListPedido, IPedido, IPedidoPgto, IPedidoProdutos } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { Load } from "../../components/Load";
import { checkToken } from "../../utils/checkToken";
import { api } from "../../services/api";

import "./style.css";
import { getNomeFormaPgto } from "../../utils/formaPgto";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { Input } from "../../components/Input";

type IProps = {
  closeModal: () => void;
  pedidos: IListPedido[];
}

export function PedidosModalNfe({ closeModal, pedidos }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [produtos, setProdutos] = useState<IPedidoProdutos[]>([]);
  const [pgtos, setPgtos] = useState<IPedidoPgto[]>([]);
  const [desconto, setDesconto] = useState(0);

  const [totalProdutos, setTotalProdutos] = useState(0);
  const [totalPgto, setTotalPgto] = useState(0);

  const [nfePorc, setNfePorc] = useState(100);

  const [tipoEmissao, setTipoEmissao] = useState("Separado");
  const [listPedidos, setListPedidos] = useState<IPedido[]>([]);

  const loadPedidos = async () => {
    if (!token)
      return;

    if (pedidos.length === 0)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const newPed: IPedidoProdutos[] = [];
    const newPgto: IPedidoPgto[] = [];
    let nrPedidoAtual = 0;

    const tempListPedidos: IPedido[] = [];

    pedidos.map(async (item) => {
      try {
        nrPedidoAtual++;

        setLoading(true);
        setTxtLoading(`Carregando Pedido ${nrPedidoAtual}/${pedidos.length}...`);

        const req = await api.get(`pedidos/${item.id}`);

        if (req.data) {
          const p: IPedido = req.data;
          tempListPedidos.push(p);

          p.pedidos.map((itemPed) => {
            if (!itemPed.excluir) {
              let alredyExists: IPedidoProdutos = {} as IPedidoProdutos;
              newPed.map((rs) => {
                if (rs.id_produto === itemPed.id_produto && rs.valor_unit === itemPed.valor_unit)
                  alredyExists = rs;
              });

              if (alredyExists.id)
                newPed[newPed.indexOf(alredyExists)] = { ...itemPed, qtd: parseFloat(`${itemPed.qtd}`) + parseFloat(`${alredyExists.qtd}`) };
              else
                newPed.push(itemPed);
            }
          });

          p.pgtos.map((itemPgto) => {
            if (!itemPgto.excluir) {
              let alredyExists: IPedidoPgto = {} as IPedidoPgto;
              newPgto.map((rs) => {
                if (parseInt(`${rs.forma_pgto}`) === parseInt(`${itemPgto.forma_pgto}`))
                  alredyExists = rs;
              });

              if (alredyExists.id)
                newPgto[newPgto.indexOf(alredyExists)] = { ...itemPgto, valor: parseFloat(`${itemPgto.valor}`) + parseFloat(`${alredyExists.valor}`) };
              else
                newPgto.push({ ...itemPgto, valor: parseFloat(`${itemPgto.valor}`) });
            }
          });

          setDesconto(e => parseFloat(`${e}`) + parseFloat(`${p.desconto}`));
          setTotalProdutos(e => parseFloat(`${e}`) + parseFloat(`${p.total}`));
          setTotalPgto(e => parseFloat(`${e}`) + parseFloat(`${p.valor_pago}`));
        }

        setProdutos(newPed);
        setPgtos(newPgto);

        setLoading(false);
        setTxtLoading("");

      } catch (err) { }
    })

    setListPedidos(tempListPedidos);
  }

  const handleFocusOut = (valor: number) => {
    if (valor > 100)
      setNfePorc(100);

    if (valor < 1)
      setNfePorc(1);
  }

  const handleEnviar = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Enviando NFe...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const percent = parseFloat(`${nfePorc}`) / parseFloat("100");

    const request: ICreateNfeDTO = {
      id_cliente: pedidos[0].id_cliente,
      id_empresa: pedidos[0].id_empresa,
      desconto: parseFloat(`${desconto}`) * percent,
      total: parseFloat(`${totalProdutos}`)
    };

    let idNFe = "";

    await api.post("nfe", { ...request })
      .then((result) => {
        if (!result.data)
          return;

        idNFe = result.data.id;
      })
      .catch((err) => { })
      .finally(() => {
        if (idNFe.length > 0)
          handleSalvaProdutos(idNFe);
        else {
          setLoading(false);
          setTxtLoading("");
        }
      });
  }

  const handleSalvaProdutos = async (idNFe: string) => {
    setTxtLoading("Salvando produtos da NFe...");

    const newListProdutos: ICreateNfeProdutosDTO[] = [];
    const percent = parseFloat(`${nfePorc}`) / parseFloat("100");

    produtos.map((item) => {
      newListProdutos.push({
        id_nfe: idNFe,
        id_produto: item.id_produto,
        qtd: item.qtd,
        valor_unit: parseFloat(`${item.valor_unit}`) * percent
      });
    });

    await api.post("nfe/produtos", { data: newListProdutos })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Produtos: " + err.response.data.message);
      })
      .finally(() => {
        handleSalvaPgtos(idNFe);
      });
  }

  const handleSalvaPgtos = async (idNFe: string) => {
    setTxtLoading("Salvando pagamentos ao NFe...");

    const newListPgtos: ICreateNfePgtosDTO[] = [];
    const percent = parseFloat(`${nfePorc}`) / parseFloat("100");

    pgtos.map((item) => {
      newListPgtos.push({
        id_nfe: idNFe,
        forma_pgto: item.forma_pgto,
        valor: parseFloat(`${item.valor}`) * percent
      });
    });

    await api.post("nfe/pgtos", { data: newListPgtos })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pgtos: " + err.response.data.message);
      })
      .finally(() => {
        handleSolicitaEmisao(idNFe);
      });
  }

  const handleSolicitaEmisao = async (idNFe: string) => {
    setTxtLoading("Solicitando emissão de NFe");

    await api.post("nfe/emitir", { id: idNFe })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pedido de emissão: " + err.response.data.message);
      })
      .finally(() => {
        closeModal();
      });
  }


  const handleEnviarSeparado = async () => {
    if (!window.confirm("Deseja realmente emitir uma NFe para cada pedido?"))
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Verificando conexão com o servidor...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let nrPedidoAtual = 0;

    await Promise.all(
      listPedidos.map(async (p) => {
        nrPedidoAtual++;
        setTxtLoading(`Solicitando emissão de NFe ${nrPedidoAtual}/${listPedidos.length}`);

        try {
          const request: ICreateNfeDTO = {
            id_cliente: p.id_cliente,
            id_empresa: p.id_empresa,
            desconto: parseFloat(`${p.desconto}`),
            total: parseFloat(`${p.total}`)
          };

          const resNfe = await api.post("nfe", { ...request });

          if (!resNfe.data) throw new Error("Erro ao recuperar resposta do servidor.");

          const idNFe = resNfe.data.id;

          const newListProdutos: ICreateNfeProdutosDTO[] = [];

          p.pedidos.map((item) => {
            newListProdutos.push({
              id_nfe: idNFe,
              id_produto: item.id_produto,
              qtd: item.qtd,
              valor_unit: parseFloat(`${item.valor_unit}`)
            });
          });

          await api.post("nfe/produtos", { data: newListProdutos });

          const newListPgtos: ICreateNfePgtosDTO[] = [];

          p.pgtos.map((item) => {
            newListPgtos.push({
              id_nfe: idNFe,
              forma_pgto: item.forma_pgto,
              valor: parseFloat(`${item.valor}`)
            });
          });

          await api.post("nfe/pgtos", { data: newListPgtos });

          await api.post("nfe/emitir", { id: idNFe })

        } catch (err) {
          console.log(p.id, err);
          alert(`Erro ao enviar NFe de ${currencyFormatter(p.valor_pago)}`);
        }
      })
    );

    closeModal();
  };

  useEffect(() => {
    loadPedidos();
  }, [token, pedidos]);

  return (
    <div className="divModalNfe">
      <div className="title">
        <p>Pedido de Emissão de NFe</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="divTipoEmissao">
              <label>
                <input
                  type="radio"
                  value="Junto"
                  checked={tipoEmissao === "Junto"}
                  onChange={(e) => setTipoEmissao(e.target.value)}
                />
                Emitir uma única NFe
              </label>
              <label>
                <input
                  type="radio"
                  value="Separado"
                  checked={tipoEmissao === "Separado"}
                  onChange={(e) => setTipoEmissao(e.target.value)}
                />
                Emitir uma NFe para cada pedido
              </label>
            </div>


            {tipoEmissao === "Junto" && (
              <div className="divForm">
                <div style={{ width: "100%" }}>
                  <span>PORCENTAGEM DA NFe:</span>
                  <Input
                    mask="none"
                    value={nfePorc}
                    inputMaskChange={setNfePorc}
                    placeholder="0%"
                    style={{ textAlign: "right" }}
                    onBlur={(e) => handleFocusOut(parseInt(e.target.value))}
                    type="number"
                    min={1}
                    max={100}
                  />
                </div>

                <button className="btn-green" onClick={() => handleEnviar()}>
                  <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "10px" }} />
                  ENVIAR
                </button>
              </div>
            )}

            {tipoEmissao === "Separado" && (
              <div className="divForm" style={{ marginTop: "10px" }}>
                <button className="btn-green" style={{ margin: "0 auto" }} onClick={() => handleEnviarSeparado()}>
                  <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "10px" }} />
                  ENVIAR
                </button>
              </div>
            )}

            <div className="divGrid">
              <div className="headTable">
                <span style={{ minWidth: "200px" }}>Produto</span>
                <span style={{ width: "50px", textAlign: "right" }}>Qtd</span>
                <span style={{ width: "100px", textAlign: "right" }}>Valor Unit</span>
              </div>

              <div className="contentTable">
                {produtos?.map((item) => (
                  <div className="rowTable" key={`${item.produto.id}`}>
                    <span style={{ minWidth: "200px" }}>{item.produto.nome}</span>
                    <span style={{ width: "50px", textAlign: "right" }}>{parseFloat(`${item.qtd}`)}</span>
                    <span style={{ width: "100px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit}`))}</span>
                  </div>
                ))}

                <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                  <span style={{ fontSize: "16px" }}>TOTAL PRODUTOS: {currencyFormatter(parseFloat(`${totalProdutos}`))}</span>
                </div>
              </div>
            </div>

            <div className="divGrid">
              <div className="headTable">
                <span style={{ minWidth: "200px" }}>Forma de Pagamento</span>
                <span style={{ width: "100px", textAlign: "right" }}>Valor</span>
              </div>

              <div className="contentTable">
                {pgtos?.map((item) => (
                  <div className="rowTable" key={`${item.forma_pgto}`}>
                    <span style={{ minWidth: "200px" }}>{getNomeFormaPgto(item.forma_pgto)}</span>
                    <span style={{ width: "100px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                  </div>
                ))}

                <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                  <span style={{ fontSize: "16px" }}>DESCONTO: {currencyFormatter(parseFloat(`${desconto}`))}</span>
                  <span style={{ fontSize: "16px" }}>TOTAL PAGO: {currencyFormatter(parseFloat(`${totalPgto}`))}</span>
                </div>
              </div>
            </div>
          </>
        )}

      </div>

    </div>
  );
}