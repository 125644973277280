import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { KeyboardEvent, useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { useCache } from "../../hooks/useCache";
import "./style.css";
import { ICidades } from "../../global/types";
import { TiraAcentos } from "../../utils/tiraAcentos";

type IProps = {
  closeModal: () => void;
  handleSelectCidade: (cidade: ICidades) => void;
}

export function ModalCidades({ closeModal, handleSelectCidade }: IProps) {

  const { cacheCidades } = useCache();

  const [pesquisa, setPesquisa] = useState("");
  const [listCidades, setListCidades] = useState<ICidades[]>([]);

  const [carregandoLista, setCarregandoLista] = useState(true);

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handlePesquisa = async () => {
    if (pesquisa.length < 3)
      return alert("Mínimo de 3 letras para pesquisa");

    const newList: ICidades[] = [];

    cacheCidades.filter(i => TiraAcentos(i.nome).toLowerCase().includes(TiraAcentos(pesquisa).toLowerCase().trim())).map((item) => {
      newList.push(item);
    });

    setListCidades(newList);
  }

  const handleClick = (cidade: ICidades) => {
    handleSelectCidade(cidade);
    closeModal();
  }

  useEffect(() => {
    setCarregandoLista(cacheCidades.length <= 0);
  }, [cacheCidades]);

  return (
    <div className="modalCidades">
      <div className="title">
        <p>Lista de Cidades</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        <div className="info">
          <h4>Pesquisa</h4>
        </div>

        <div className="divFormModal">
          <Input
            autoFocus
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa..."
            onKeyUp={handleKeyPesquisa}
            readOnly={carregandoLista}
          />

          <button className="btn-green" onClick={handlePesquisa}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>

        <div className="headTable">
          <span>Cidade</span>
          <span>Estado</span>
        </div>
        <div className="contentTable">
          {listCidades.map((item) => (
            <div className="rowTable" key={item.id} onClick={() => handleClick(item)}>
              <span>{item.nome}</span>
              <span>{item.uf.nome}</span>
            </div>
          ))}

          {listCidades.length === 0 && (
            <h4 style={{ color: "#7E7E7E", fontWeight: "500", textAlign: "center", marginTop: "10px" }}>Pesquise o nome da cidade</h4>
          )}

        </div>

      </div>

    </div>
  );
}