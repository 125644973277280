import { faTimes, faClone, faFileCode, faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { IListNfe, INfeInfo } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

import "./style.css";

type IProps = {
  nfe: IListNfe;
  closeModal: () => void;
}

export function NfeModalInfo({ closeModal, nfe }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(true);
  const [txtLoading, setTxtLoading] = useState("");

  const [info, setInfo] = useState({} as INfeInfo);
  const [urlPdf, setUrlPdf] = useState("");

  const loadInfoNfe = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Carregando informações da NFe");

    await api.get(`nfe/${nfe.id}`)
      .then((res) => {
        if (!res.data)
          return alert("Erro ao recuperar dados do servidor");

        setInfo(res.data);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const loadPDF = async () => {
    const formData = new FormData();
    formData.append("xml[]", `${info.list_xml.find(i => i.acao === "xml")?.xml}`);

    const response = await axios.post("https://dcifiscalphp.dcisuporte.com.br/danfe.php", formData, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" }
    });

    const file = new Blob([response.data], { type: 'application/pdf' })

    const fileURL = URL.createObjectURL(file)

    setUrlPdf(fileURL);
  }

  const handleDownloadXml = () => {
    const xml = info.list_xml.find(i => i.acao === "xml")?.xml;

    if (!xml)
      return;

    const file = new Blob([xml], { type: 'application/xml' })
    const fileURL = URL.createObjectURL(file);

    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", `${info.chave}.xml`);
    tempLink.click();
  }

  const handleDownloadXmlCancel = () => {
    const xml = info.list_xml.find(i => i.acao === "cancelamento")?.xml;

    if (!xml)
      return;

    const file = new Blob([xml], { type: 'application/xml' })
    const fileURL = URL.createObjectURL(file);

    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", `cancelamento-${info.chave}.xml`);
    tempLink.click();
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(info.chave);
    const divCopy = document.getElementById("divCopy");

    if (!divCopy)
      return;

    if (divCopy.style.opacity === "1")
      return;

    divCopy.style.opacity = "1";
    setTimeout(() => {
      divCopy.style.opacity = "0";
    }, 1000);
  }

  const handleResetEnvia = async () => {
    if (!window.confirm("Deseja realmente resetar as informações dessa NFe?"))
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Verificando token");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setTxtLoading("Resetando NFe");

    try {
      await api.post("nfe/", {
        ...nfe,
        chave: "",
        status: 0,
        situacao: "",
        motivo: "",
      });

      setTxtLoading("Incluindo na lista de emissões");
      await api.post("nfe/emitir", { id: nfe.id })

      window.location.reload();

    } catch (err: any) {
      if (err.response.status !== 401)
        setAlert(err.response.data.message, "danger");
    }
  }

  useEffect(() => {
    if (!nfe.id)
      return;

    loadInfoNfe();
  }, [nfe]);

  useEffect(() => {
    if (!info || !info.list_xml || info.list_xml.length <= 0)
      return;

    loadPDF();
  }, [info]);

  return (
    <div id="modalInfoNfe">
      <div className="title">
        <p>Informações da NFe</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">

        {loading && (
          <div style={{ height: "100%" }}>
            <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
          </div>
        )}

        {!loading && info && info.id && (
          <>
            <div className="divBtns">
              <button className="btn-green" onClick={() => handleDownloadXml()}>
                <FontAwesomeIcon icon={faFileCode} />
                Download XML
              </button>

              {info.cancelado && (
                <button className="btn-red" onClick={() => handleDownloadXmlCancel()}>
                  <FontAwesomeIcon icon={faFileCode} />
                  XML Cancelamento
                </button>
              )}

              {info.situacao !== "AUTORIZADA" && info.situacao !== "CANCELADA" && (
                <button className="btn-red" onClick={() => handleResetEnvia()}>
                  <FontAwesomeIcon icon={faEraser} />
                  RESETAR E ENVIAR
                </button>
              )}

            </div>

            <div>
              <span>Chave:</span>
              <p>
                {info.chave}
                <FontAwesomeIcon
                  icon={faClone}
                  style={{ cursor: "pointer", color: "#C0C0C0", marginLeft: "5px" }}
                  onClick={() => handleCopy()}
                />

                <span id="divCopy">
                  copiado
                </span>

              </p>
            </div>

            <div>
              <span>Situação:</span>
              <p>{info.situacao}</p>
            </div>

            {info.situacao !== "AUTORIZADA" && info.situacao !== "CANCELADA" && (
              <div>
                <span>Motivo</span>
                <p>{info.motivo}</p>
              </div>
            )}

            {info.cancelado && (
              <div>
                <span>Justificativa do Cancelamento</span>
                <p>{info.cancel_motivo}</p>
              </div>
            )}

            <div style={{ flex: "1" }}>
              <iframe src={urlPdf} style={{ width: "100%", height: "100%", border: "0px" }}></iframe>
            </div>

          </>
        )}

      </div>

    </div >
  );
}