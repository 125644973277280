import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Input } from "../../components/Input";

import "./style.css";

type IProps = {
  tipo: "" | "reforco" | "sangria";
  closeModal: () => void;
  handleEnviar: (valor: number, tipo: "reforco" | "sangria", descricao: string) => void;
};

export function CaixaModalReforcoSangria({ closeModal, tipo, handleEnviar }: IProps) {

  const [valor, setValor] = useState("");
  const [descricao, setDescricao] = useState("");

  const handleSalvar = () => {
    if (valor.length <= 0)
      return;

    if (tipo === "")
      return;

    const newValor = parseFloat(valor.replace(".", "").replace(",", "."));

    if (newValor <= 0)
      return;

    handleEnviar(newValor, tipo, descricao);
  }

  return (
    <div id="modalReforcoSangria">
      <div className="title">
        <p>{tipo === "reforco" ? "Reforço" : "Sangria"}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        <span>Valor</span>
        <Input
          mask="currency"
          value={valor}
          inputMaskChange={setValor}
          placeholder="R$ 0,00"
        />

        <span style={{ marginTop: "15px" }}>Descrição</span>
        <Input
          mask="none"
          value={descricao}
          inputMaskChange={setDescricao}
          placeholder={`Digite aqui a descrição ${tipo === "reforco" ? 'do Reforço' : 'da Sangria'}`}
        />

        <button className="btn-green" onClick={() => handleSalvar()}>
          <FontAwesomeIcon icon={faCheck} /> SALVAR
        </button>

      </div>

    </div>
  )
}