import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICreateOrdemServicoObsDTO, IOrdemServicoObs } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { convertToUTC } from "../../utils/dateProvider";
import { dynamicSort } from "../../utils/dynamicSort";

import "./style.css";

type IProps = {
  closeModal: () => void;
  refresh: () => Promise<void>;
  idOrdemServico: string;
  listObs: IOrdemServicoObs[] | null;
}

export function OrdemServicoModalObs({ closeModal, listObs, idOrdemServico, refresh }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [obs, setObs] = useState("");

  const handleKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleSalvar();
  }

  const handleSalvar = async () => {
    if (obs.length <= 3)
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Salvando observação");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const newObs: ICreateOrdemServicoObsDTO[] = [];

    newObs.push({
      observacao: obs,
      id_ordem_servico: idOrdemServico
    });

    try {
      await api.post("ordemServico/obs", { data: newObs });

      setTimeout(async () => {
        await refresh();

        setLoading(false);
        setTxtLoading("");
      }, 1000);

    } catch (err) {
      console.log(err);
      const teste: any = err;

      if (teste.response.data.message)
        alert(teste.response.data.message);
      else
        alert("Erro ao salvar a observação");

      setLoading(false);
      setTxtLoading("");
    }

  }

  return (
    <div className="modal modalObsOrdemServico">
      <div className="title">
        <p>Observações</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <div style={{ height: "100%" }}>
            <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
          </div>
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <Input
                autoFocus
                mask="none"
                value={obs}
                inputMaskChange={setObs}
                placeholder="Digite a nova observação..."
                onKeyUp={handleKey}
              />

              <button className="btn-green" onClick={handleSalvar}>
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>

            <div className="headTable">
              <span>Observações</span>
            </div>
            <div className="contentTable">
              {listObs !== null && listObs.sort(dynamicSort("-created_at")).map((item) => (
                <div className="rowTable" key={item.id}>
                  <span style={{ width: "100%", flexDirection: "column", alignItems: "start" }}>
                    <p><b>{item.usuario.nome}:</b> {item.observacao}</p>
                    <p className="date">{convertToUTC(item.created_at)}</p>
                  </span>
                </div>
              ))}
            </div>
          </>
        )}

      </div>

    </div>
  );
}