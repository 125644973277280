import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Input } from "../../components/Input";

import "./style.css";

type IProps = {
  closeModal: () => void;
  abrirCaixa: (valor: number) => void;
};

export function CaixaModalValorInicial({ closeModal, abrirCaixa }: IProps) {

  const [valorInicial, setValorInicial] = useState("");

  const handleAbrirCaixa = () => {
    let valor = 0;

    if (valorInicial.length >= 1)
      valor = parseFloat(valorInicial.replace(".", "").replace(",", "."));

    abrirCaixa(valor);
  }

  return (
    <div id="modalValorInicial">
      <div className="title">
        <p>Abrir Caixa</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        <span>Valor Inicial</span>
        <Input
          mask="currency"
          value={valorInicial}
          inputMaskChange={setValorInicial}
          placeholder="R$ 0,00"
        />

        <button className="btn-green" onClick={() => handleAbrirCaixa()}>
          <FontAwesomeIcon icon={faCheck} /> ABRIR CAIXA
        </button>

      </div>

    </div>
  );
}