import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPlus,
  faSearch,
  faTimes,
  faUsers,
  faComments,
  faDollarSign,
  faEye,
  faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IClientes, IEmpresa, IFiltersOrdemServico, IListRequest, IOrdemServico, IStatus } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { convertToUs, convertToUTC } from "../../utils/dateProvider";
import Modal from "react-modal";

import "./style.css";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { OrdemServicoModalObs } from "../../modal/OrdemServicoModalObs";
import { OrdemServicoModalStatus } from "../../modal/OrdemServicoModalStatus";
import { OrdemServicoModalPgtos } from "../../modal/OrdemServicoModalPgtos";
import { dynamicSort } from "../../utils/dynamicSort";

const defaultFilterOrdemServico: IFiltersOrdemServico = {
  empresa: "",
  usuario: "",
  cliente: {} as IClientes,
  date_ini: "",
  date_fin: "",
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function OrdemServico() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [limitPage, setLimitPage] = useState(25);

  const [pesquisa, setPesquisa] = useState<IFiltersOrdemServico>(defaultFilterOrdemServico);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listOrdemServico, setListOrdemServico] = useState<IOrdemServico[]>([]);
  const [listStatus, setListStatus] = useState<IStatus[]>([]);

  const [ordemServicoSeleted, setOrdemServicoSelected] = useState<IOrdemServico>({} as IOrdemServico);

  const [modalCliente, setModalCliente] = useState(false);
  const [modalObs, setModalObs] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalPgtos, setModalPgtos] = useState(false);

  const [caixaAberto, setCaixaAberto] = useState(false);

  const getCaixaAberto = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando informações do caixa");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("caixa/getCaixaAberto")
      .then((result) => {
        if (!result.data)
          return;

        setCaixaAberto(true);
      })
      .catch(() => {
        setCaixaAberto(false);
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        loadOrdemServico();
      });
  }

  const loadOrdemServico = async (canPesquisa: boolean = false) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de ordem de serviço...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa) {
      dataRequest = {
        ...dataRequest,
        pesquisa: {
          cliente: pesquisa.cliente ? pesquisa.cliente.id : null,
          date_ini: pesquisa.date_ini ? `${convertToUs(pesquisa.date_ini)} 00:00:00` : null,
          date_fin: pesquisa.date_fin ? `${convertToUs(pesquisa.date_fin)} 23:59:59` : null,
          empresa: pesquisa.empresa ? pesquisa.empresa : null,
          id_status: pesquisa.status ? pesquisa.status : null
        }
      };
    }

    await api.post("ordemServico/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);

        const newList: IOrdemServico[] = [];
        const listResult: IOrdemServico[] = response.data.result;

        listResult.map((item) => {
          let total = 0;

          item.produtos.map((rs) => { total += rs.valor_unit * rs.quantidade; })
          item.servicos.map((rs) => { total += rs.valor_unit * rs.quantidade; })

          newList.push({ ...item, total });
        });

        setListOrdemServico(newList);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");

        if (listEmpresas.length <= 0)
          loadEmpresas();
      });
  }

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setPesquisa(y => ({ ...y, empresa: result.data.result[0].id }))
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadStatus();
      });
  }

  const loadStatus = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("status/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListStatus(result.data.result);

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
      });
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setPesquisa(e => ({ ...e, cliente }));
  }

  const changeDateIni = (value: string) => {
    setPesquisa(e => ({ ...e, date_ini: value }));
  }

  const changeDateFin = (value: string) => {
    setPesquisa(e => ({ ...e, date_fin: value }));
  }

  const handleOpenObs = (item: IOrdemServico) => {
    setOrdemServicoSelected(item);
    setModalObs(true);
  }

  const handleOpenStatus = (item: IOrdemServico) => {
    setOrdemServicoSelected(item);
    setModalStatus(true);
  }

  const handleOpenPgtos = (item: IOrdemServico) => {
    if (!caixaAberto) {
      setAlert("Caixa Fechado!!!", "danger");
      return;
    }

    setOrdemServicoSelected(item);
    setModalPgtos(true);
  }

  useEffect(() => {
    getCaixaAberto();
  }, [token]);

  useEffect(() => {
    if (maxPage > 1)
      loadOrdemServico(true);
  }, [page, maxPage]);

  useEffect(() => {
    if (!ordemServicoSeleted.id || listOrdemServico.length <= 0)
      return;

    listOrdemServico.map((item) => {
      if (item.id === ordemServicoSeleted.id)
        setOrdemServicoSelected(item);
    })

  }, [ordemServicoSeleted, listOrdemServico]);

  return (
    <Topo page="OrdemServico" title="Ordem de Serviço">
      <div id="OrdemServicoPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            <div className="divPesquisa">
              <div style={{ width: "100%", textAlign: "right", marginTop: "5px" }}>
                <button className="btn-green" onClick={() => navigate("/ordemServico/novo")}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} /> NOVA ORDEM DE SERVIÇO
                </button>
              </div>

              <div className="pesqEmpresa">
                <span>Empresa</span>
                <select value={pesquisa.empresa} onChange={(e) => setPesquisa(y => ({ ...y, empresa: e.target.value }))}>
                  <option value="">Pesquisa por Empresa</option>
                  {listEmpresas.length > 0 && listEmpresas.map((item) => (
                    <option key={item.id} value={item.id}>{item.fantasia}</option>
                  ))}
                </select>
              </div>

              <div className="pesqCliente">
                <span>Cliente</span>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                  <Input
                    mask="none"
                    value={pesquisa.cliente?.fantasia ? pesquisa.cliente?.fantasia : ""}
                    inputMaskChange={null}
                    placeholder="Pesquisa por Cliente"
                    readOnly
                  />
                  {pesquisa.cliente?.fantasia && (
                    <button className="btn-orange" onClick={() => setPesquisa(e => ({ ...e, cliente: {} as IClientes }))}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                    <FontAwesomeIcon icon={faUsers} />
                  </button>
                </div>
              </div>

              <div className="pesqDate">
                <span>Status</span>
                <select value={pesquisa.status} onChange={(e) => setPesquisa(y => ({ ...y, status: e.target.value }))}>
                  <option value="">Pesquisa por Stauts</option>
                  {listStatus.length > 0 && listStatus.sort(dynamicSort("ordem")).map((item) => (
                    <option key={item.id} value={item.id}>{item.nome}</option>
                  ))}
                </select>
              </div>

              <div className="pesqDate">
                <span>Data Início</span>
                <Input
                  mask="date"
                  value={pesquisa.date_ini}
                  inputMaskChange={changeDateIni}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div className="pesqDate">
                <span>Data Final</span>
                <Input
                  mask="date"
                  value={pesquisa.date_fin}
                  inputMaskChange={changeDateFin}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div>
                <span>Pedidos por Página</span>
                <select value={limitPage} onChange={(e) => setLimitPage(parseInt(e.target.value))}>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
                <button className="btn-blue-dark" onClick={() => loadOrdemServico(true)}>
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: "5px" }} /> PESQUISAR
                </button>
              </div>

            </div>

            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "200px" }}>Cliente</span>
                <span style={{ width: "200px" }}>Empresa</span>
                <span style={{ width: "50px", textAlign: "center" }}>Data/Hora</span>
                <span style={{ width: "100px", textAlign: "right" }}>Valor</span>
                <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable">
                {listOrdemServico.length > 0 && listOrdemServico.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "200px" }}>
                      {item.cliente.fantasia}
                    </span>
                    <span style={{ width: "200px" }}>{item.empresa.fantasia}</span>
                    <span style={{ width: "50px", textAlign: "right" }}>{convertToUTC(item.created_at)}</span>
                    <span style={{ width: "100px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.total}`))}</span>
                    <span style={{ width: "200px", textAlign: "center" }}>
                      <button onClick={() => navigate(`/ordemServico/${item.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </button>

                      <button className="btn-orange" onClick={() => handleOpenObs(item)}>
                        <FontAwesomeIcon icon={faComments} />
                      </button>

                      <button className="btn-blue-dark" onClick={() => handleOpenStatus(item)}>
                        <FontAwesomeIcon icon={faClipboardCheck} />
                      </button>

                      <button className="btn-green" onClick={() => handleOpenPgtos(item)}>
                        <FontAwesomeIcon icon={faDollarSign} />
                      </button>
                    </span>
                  </div>
                ))}
              </div>

              <div className="pagination">
                {page !== 1 && (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
                )}
                <p>
                  {page}/{maxPage}
                </p>
                {page !== maxPage && (
                  <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
                )}
              </div>

            </div>

          </>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>

        <Modal isOpen={modalObs} style={styleModal}>
          <OrdemServicoModalObs
            closeModal={() => setModalObs(false)}
            listObs={ordemServicoSeleted.observacoes ? ordemServicoSeleted.observacoes : null}
            idOrdemServico={ordemServicoSeleted.id ? ordemServicoSeleted.id : ""}
            refresh={() => loadOrdemServico(true)}
          />
        </Modal>

        <Modal isOpen={modalStatus} style={styleModal}>
          <OrdemServicoModalStatus
            closeModal={() => setModalStatus(false)}
            listStatusOrdemServico={ordemServicoSeleted.status ? ordemServicoSeleted.status : null}
            idOrdemServico={ordemServicoSeleted.id ? ordemServicoSeleted.id : ""}
            refresh={() => loadOrdemServico(true)}
          />
        </Modal>

        <Modal isOpen={modalPgtos} style={styleModal}>
          <OrdemServicoModalPgtos
            closeModal={() => setModalPgtos(false)}
            listPgtos={ordemServicoSeleted.pgtos ? ordemServicoSeleted.pgtos : null}
            totalOrdemServico={ordemServicoSeleted.total}
            idOrdemServico={ordemServicoSeleted.id ? ordemServicoSeleted.id : ""}
            refresh={() => loadOrdemServico(true)}
          />
        </Modal>

      </div>
    </Topo>
  )
}