import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IEmpresa } from "../../global/types";
import { api } from "../../services/api";
import { addMonths } from "../../utils/dateProvider";

import "./style.css"

type IProps = {
  closeModal: () => void;
}

export function NfeModalEnviaContabilidade({ closeModal }: IProps) {
  const minAno = 1900;
  const maxAno = 3000;

  const [mes, setMes] = useState(new Date(addMonths(-1)).getMonth() + 1);
  const [ano, setAno] = useState(new Date(addMonths(-1)).getFullYear());

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("Carregando empresas cadastradas");

  const handleFocusOutAno = (v: number) => {
    if (v > maxAno)
      setAno(maxAno);

    if (v < minAno)
      setAno(minAno);
  }

  const handleEnvia = async () => {
    setLoading(true);
    setTxtLoading("Carregando empresas cadastradas");

    try {

      const resEmpresas = await api.get("/empresas");

      if (!resEmpresas.data)
        return;

      const empresas = resEmpresas.data.result as IEmpresa[];
      let empresaAtual = 0;

      await Promise.all(
        empresas.map(async (rsEmpresa) => {
          empresaAtual++;
          setTxtLoading(`Enviando e-mail (${empresaAtual}/${resEmpresas.data.total})...`)

          if (rsEmpresa.email_contabilidade.length > 10) {
            await api.post("/nfe/envia_contabilidade", {
              ano,
              mes: ("00" + mes).slice(-2),
              idEmpresa: rsEmpresa.id
            });
          }
        })
      );

      alert("Envio realizado com sucesso!");
      closeModal();

    } catch (err) {
      alert("Erro ao enviar email para a contabilidade.");
      setLoading(false);
      setTxtLoading("");
    }

  }

  return (
    <div id="modalEnviaContabilidade">
      <div className="title">
        <p>Envio para Contabilidade</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      {loading && (
        <div style={{ height: "100%" }}>
          <Load txtLoading={txtLoading} options={{ height: 100, width: 100 }} />
        </div>
      )}

      {!loading && (
        <div className="body">
          <span>Mês</span>

          <div>
            <select value={mes} onChange={(e) => setMes(parseInt(e.target.value))}>
              <option value={1}>Janeiro</option>
              <option value={2}>Fevereiro</option>
              <option value={3}>Março</option>
              <option value={4}>Abril</option>
              <option value={5}>Maio</option>
              <option value={6}>Junho</option>
              <option value={7}>Julho</option>
              <option value={8}>Agosto</option>
              <option value={9}>Setembro</option>
              <option value={10}>Outubro</option>
              <option value={11}>Novembro</option>
              <option value={12}>Dezembro</option>
            </select>

            <Input
              mask="none"
              value={ano}
              inputMaskChange={setAno}
              placeholder="Ano"
              type="number"
              min={minAno}
              max={maxAno}
              onBlur={(e) => handleFocusOutAno(parseInt(e.target.value))}
            />

          </div>

          <button className="btn-green" onClick={() => handleEnvia()}>
            <FontAwesomeIcon icon={faPaperPlane} /> ENVIAR
          </button>
        </div>
      )}

    </div >
  )
}