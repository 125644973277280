import { faMapMarkerAlt, faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICidades, ICreateEmpresasDTO, IEmpresa } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { maskCep, maskCpfCnpj, maskPhone } from "../../utils/masks";
import { ModalCidades } from "../../modal/ModalCidades";
import "./style.css";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IParams = {
  id: string;
}

export function EmpresasForm() {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, cacheCidades, saveCacheCidades, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [listCidades, setListCidades] = useState<ICidades[]>([]);
  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCidades, setModalCidades] = useState(false);
  const [cidadeSelected, setCidadeSelected] = useState<ICidades>({} as ICidades);

  const [fantasia, setFantasia] = useState("");
  const [razao, setRazao] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [ie, setIe] = useState("");
  const [cidade, setCidade] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [ambiente, setAmbiente] = useState("");
  const [senhaCert, setSenhaCert] = useState("");
  const [crt, setCrt] = useState("");
  const [serieNfe, setSerieNfe] = useState("");
  const [nrNfe, setNrNfe] = useState("");
  const [emailContabilidade, setEmailContabilidade] = useState("");

  const handleSelectCidade = (cidade: ICidades) => {
    setCidadeSelected(cidade);
    setCidade(`${cidade.id}`);
  }

  const loadCidades = async () => {

    if (!token)
      return;

    if (cacheCidades.length > 0) {
      setListCidades(cacheCidades);
      loadCadastro();
      return;
    }

    setLoading(true);
    setTxtLoading("Carregando cidades...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("cidades/list")
      .then((result) => {
        if (!result.data)
          return;

        setListCidades(result.data);
        saveCacheCidades(result.data);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadCadastro();
      })
  };

  const loadCadastro = async () => {
    setTxtLoading("Carregando cadastro...");

    await api.get(`empresas/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IEmpresa;

        setFantasia(data.fantasia);
        setRazao(data.razao);
        setCpfCnpj(maskCpfCnpj(`${data.cnpj}`));
        setIe(`${data.ie}`);
        setCidade(`${data.id_cidades}`);
        setEndereco(data.endereco);
        setNumero(`${data.nr}`);
        setComplemento(data.complemento);
        setBairro(data.bairro);
        setCep(maskCep(`${data.cep}`));
        setTelefone(maskPhone(`${data.fone}`));

        setAmbiente(`${data.ambiente}`);
        setSenhaCert(`${data.senha_cert}`);
        setCrt(`${data.crt}`);
        setSerieNfe(`${data.serie_nfe}`);
        setNrNfe(`${data.nr_nfe}`);
        setEmailContabilidade(`${data.email_contabilidade}`);

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    if (fantasia.length <= 0) return alert("Preencha o campo Nome Fantasia");
    if (razao.length <= 0) return alert("Preencha o campo Razão Social");
    if (cpfCnpj.length <= 0) return alert("Preencha seu CPF/CNPJ");
    if (cidade.length <= 0) return alert("Preencha sua Cidade");
    if (endereco.length <= 3) return alert("Preencha seu Endereço corretamente");
    if (bairro.length <= 3) return alert("Preencha seu Bairro corretamente");
    if (cep.length != 9) return alert("Preencha seu CEP corretamente");

    setLoading(true);
    setTxtLoading("Salvando dados da Empresa...");

    let data: ICreateEmpresasDTO = {
      id: params.id,
      id_cidades: parseInt(cidade),
      fantasia,
      razao,
      cnpj: parseInt(cpfCnpj.replace(/[^0-9]/g, "")),
      ie: ie.length <= 3 ? 0 : parseInt(ie.replace(/[^0-9]/g, "")),
      fone: telefone.length > 0 ? parseInt(telefone.replace(/[^0-9]/g, "")) : 0,
      endereco,
      nr: parseInt(numero),
      complemento,
      bairro,
      cep: parseInt(cep.replace(/[^0-9]/g, "")),
      ambiente: parseInt(ambiente),
      crt: parseInt(crt),
      nr_nfe: parseInt(nrNfe),
      serie_nfe: parseInt(serieNfe),
      senha_cert: senhaCert,
      email_contabilidade: emailContabilidade
    };

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas", { ...data })
      .then((result) => { })
      .catch((err) => {
        if (err.response.data.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        navigate(-1);
      })
  }

  useEffect(() => {
    loadCidades();
  }, [token]);

  useEffect(() => {
    if (listCidades.length > 0 && cidade.length > 0) {
      let tempCidade: ICidades = {} as ICidades;

      listCidades.filter(i => `${i.id}` === cidade).map((item) => {
        tempCidade = item;
      });

      if (tempCidade.nome)
        handleSelectCidade(tempCidade);
    }
  }, [listCidades, cidade]);

  return (
    <Topo page="Empresas" title={`Edição de Empresa`}>
      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form">

          <div className="contentInput" style={{ width: "50%" }}>
            <span>Razão Social:</span>
            <Input
              mask="none"
              value={razao}
              inputMaskChange={setRazao}
              placeholder="Razão Social"
            />
          </div>

          <div className="contentInput" style={{ width: "50%" }}>
            <span>Fantasia:</span>
            <Input
              mask="none"
              value={fantasia}
              inputMaskChange={setFantasia}
              placeholder="Nome Fantasia"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>CNPJ:</span>
            <Input
              mask="cpf_cnpj"
              value={cpfCnpj}
              inputMaskChange={setCpfCnpj}
              placeholder="CNPJ"
              maxLength={18}
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>IE:</span>
            <Input
              mask="none"
              value={ie}
              inputMaskChange={setIe}
              placeholder="IE / RG"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>Cidade:</span>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
              <Input
                mask="none"
                value={cidadeSelected.nome ? `${cidadeSelected.nome}/${cidadeSelected.uf.uf}` : ""}
                inputMaskChange={null}
                placeholder="Cidade/SP"
                readOnly
              />
              <button className="btn-blue-dark btnSelectCidade" onClick={() => setModalCidades(true)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </button>
            </div>
          </div>

          <div className="contentInput" style={{ width: "80%" }}>
            <span>Endereço:</span>
            <Input
              mask="none"
              value={endereco}
              inputMaskChange={setEndereco}
              placeholder="Endereço"
            />
          </div>

          <div className="contentInput" style={{ width: "20%" }}>
            <span>Número:</span>
            <Input
              mask="none"
              type="number"
              min={0}
              value={numero}
              inputMaskChange={setNumero}
              placeholder="Número"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>Complemento:</span>
            <Input
              mask="none"
              value={complemento}
              inputMaskChange={setComplemento}
              placeholder="Complemento"
            />
          </div>

          <div className="contentInput" style={{ width: "34%" }}>
            <span>Bairro:</span>
            <Input
              mask="none"
              value={bairro}
              inputMaskChange={setBairro}
              placeholder="Bairro"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>CEP:</span>
            <Input
              mask="cep"
              value={cep}
              inputMaskChange={setCep}
              placeholder="CEP"
              maxLength={9}
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Telefone:</span>
            <Input
              mask="phone"
              value={telefone}
              inputMaskChange={setTelefone}
              placeholder="Telefone"
              maxLength={15}
            />
          </div>

          <div className="contentInput" style={{ width: "75%" }}>
            <span>E-mail Contabilidade:</span>
            <Input
              mask="none"
              value={emailContabilidade}
              inputMaskChange={setEmailContabilidade}
              placeholder="E-mail Contabilidade"
              type="email"
            />
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <h2>Configuração da NFe</h2>
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Regime Tributário:</span>
            <select value={crt} onChange={(e) => setCrt(e.target.value)}>
              <option value="0">Normal</option>
              <option value="1">Simples Nacional</option>
            </select>
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Ambiente:</span>
            <select value={ambiente} onChange={(e) => setAmbiente(e.target.value)}>
              <option value="1">Produção</option>
              <option value="2">Homologação</option>
            </select>
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Número de Série:</span>
            <Input
              mask="none"
              type="number"
              min={1}
              value={serieNfe}
              inputMaskChange={setSerieNfe}
              placeholder="Número de Série"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Última NFe:</span>
            <Input
              mask="none"
              type="number"
              min={0}
              value={nrNfe}
              inputMaskChange={setNrNfe}
              placeholder="Última NFe"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Senha do Certificado:</span>
            <Input
              mask="none"
              value={senhaCert}
              inputMaskChange={setSenhaCert}
              placeholder="Senha do Certificado"
            />
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>

        </div>
      )}

      <Modal isOpen={modalCidades} style={styleModal}>
        <ModalCidades closeModal={() => setModalCidades(false)} handleSelectCidade={handleSelectCidade} />
      </Modal>

    </Topo>
  )
}