import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPen,
  faPlus,
  faSearch,
  faTrash,
  faCodeBranch
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { IListRequest, IVariantes } from "../../global/types";
import { api } from "../../services/api";

import { Topo } from "../../components/Topo";
import { useCache } from "../../hooks/useCache";
import { Load } from "../../components/Load";
import { Input } from "../../components/Input";
import { checkToken } from "../../utils/checkToken";
import { VariantesValoresModal } from "../../modal/VariantesValoresModal";

const limitPage = 25;
const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 500 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Variantes() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [pesquisa, setPesquisa] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [varianteSelected, setVarianteSelected] = useState("");
  const [titleModal, setTitleModal] = useState("");

  const [listVariantes, setListVariantes] = useState<IVariantes[]>([]);

  const loadVariantes = async (canPesquisa = false) => {
    if (!token)
      return;

    setLoading(true);

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa)
      dataRequest = { ...dataRequest, pesquisa };

    await api.post("variantes/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        setListVariantes(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePesquisa = () => {
    setPage(1);
    loadVariantes(true);
  }

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handleDelete = async (id: string) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir?"))
      return;

    setLoading(true);

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete('clientes', { data: { id } })
      .then((result) => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadVariantes(true);
      });
  };

  const handleOpenModal = (id: string, nome: string) => {
    setVarianteSelected(id);
    setTitleModal(nome);
    setModalOpen(true);
  }

  useEffect(() => {
    loadVariantes();
  }, [token]);

  useEffect(() => {
    loadVariantes(true);
  }, [page]);

  return (
    <Topo page="Variantes" title="Variantes (produtos)">
      <>
        <div className="divPesquisa">
          <Input
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa..."
            onKeyUp={handleKeyPesquisa}
          />
          <button onClick={handlePesquisa}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button className="btn-green" onClick={() => { navigate("/variantes/novo") }}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <div className="gridContext">
          <div className="headTable">
            <span style={{ width: "200px" }}>Nome</span>
            <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
          </div>

          <div className="contentTable">
            {loading && (
              <Load txtLoading="Carregando..." options={{ height: 120, width: 120 }} />
            )}

            {!loading && listVariantes.length > 0 && (
              listVariantes.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span style={{ width: "200px" }}>{item.nome}</span>
                  <span style={{ width: "200px", textAlign: "center" }}>
                    <button className="btn-green" onClick={() => handleOpenModal(item.id, item.nome)}>
                      <FontAwesomeIcon icon={faCodeBranch} />
                    </button>
                    <button className="btn-orange" onClick={() => { navigate(`/variantes/${item.id}`) }}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button className="btn-red" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </span>
                </div>
              ))
            )}

          </div>

          <div className="pagination">
            {page !== 1 && (
              <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
            )}
            <p>
              {page}/{maxPage}
            </p>
            {page !== maxPage && (
              <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
            )}
          </div>

        </div>

        <Modal isOpen={modalOpen} style={styleModal}>
          <VariantesValoresModal id={varianteSelected} closeModal={() => setModalOpen(false)} title={titleModal} />
        </Modal>

      </>
    </Topo>
  );
}