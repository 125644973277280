import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IListRequest, IServico } from "../../global/types"
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Load } from "../../components/Load";
import { Input } from "../../components/Input";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";

type IProps = {
  handleSelectedServico: (servico: IServico) => void;
  closeModal: () => void;
}

export function ModalSelectServicos({ closeModal, handleSelectedServico }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pesquisa, setPesquisa] = useState("");

  const [listServicos, setListServicos] = useState<IServico[]>([]);

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handlePesquisa = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de produtos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: 0,
      limit: 25,
      pesquisa
    }

    await api.post("servicos/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        setListServicos(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (servico: IServico) => {
    handleSelectedServico(servico);
    closeModal();
  }

  const loadFavoritos = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de serviços favoritos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("servicos/favoritos")
      .then((response) => {
        if (!response.data)
          return;

        setListServicos(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadFavoritos();
  }, []);

  return (
    <div className="modal">
      <div className="title">
        <p>Lista de Serviços</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="info">
              <h4>Pesquisa</h4>
            </div>

            <div className="divFormModal">
              <Input
                autoFocus
                mask="none"
                value={pesquisa}
                inputMaskChange={setPesquisa}
                placeholder="Pesquisa..."
                onKeyUp={handleKeyPesquisa}
              />

              <button className="btn-green" onClick={handlePesquisa}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>

            <div className="headTable">
              <span>Nome</span>
              <span>Valor</span>
            </div>
            <div className="contentTable">
              {listServicos.map((item) => (
                <div className="rowTable" key={item.id} onClick={() => handleClick(item)}>
                  <span>{item.nome}</span>
                  <span>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                </div>
              ))}

              {listServicos.length === 0 && (
                <h4 style={{ color: "#7E7E7E", fontWeight: "500", textAlign: "center", marginTop: "10px" }}>Pesquise o nome do serviço</h4>
              )}

            </div>
          </>
        )}

      </div>
    </div>
  )
}