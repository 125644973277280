import { useParams, useNavigate } from "react-router-dom";
import { Topo } from "../../components/Topo";

import "./style.css";
import { CadClientes } from "../../components/CadClientes";

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

export function ClientesForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  return (
    <Topo page="Clientes" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Cliente`}>

      <CadClientes
        onSave={() => navigate(-1)}
        showBtnVoltar={true}
        tipo={tipo}
        id={params.id}
      />

    </Topo >
  );
}