import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";

type IProps = {
  totalProdutos: number;
  closeModal: () => void;
  handleDescontoPorcentagem: (valor: string) => void;
}

export function PedidosModalDesconto({ closeModal, totalProdutos, handleDescontoPorcentagem }: IProps) {
  const [porcentagem, setPorcentagem] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);

  useEffect(() => {

    if (porcentagem < 0)
      setPorcentagem(0);

    const desconto = totalProdutos * (porcentagem / 100);

    setValorDesconto(desconto);
  }, [porcentagem]);

  const handleAplica = () => {
    handleDescontoPorcentagem(
      currencyFormatter(
        parseFloat(`${valorDesconto}`),
        {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        }
      ),
    );
    closeModal();
  }

  return (
    <div className="modalDesconto">
      <div className="title">
        <p>Desconto por porcentagem</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        <span>Porcentagem</span>
        <Input
          mask="none"
          value={porcentagem}
          inputMaskChange={setPorcentagem}
          placeholder="0%"
          style={{ textAlign: "right" }}
          type="number"
          min={0}
        />

        <h2>{currencyFormatter(parseFloat(`${valorDesconto}`))}</h2>

        <button className="btn-green" onClick={handleAplica}>
          <FontAwesomeIcon icon={faCheck} /> APLICAR DESCONTO
        </button>

      </div>

    </div>
  );
}