import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";

import { api } from "../../services/api";
import { useCache } from "../../hooks/useCache";
import { checkToken } from "../../utils/checkToken";

import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICreateVarianteDTO, IVariantes } from "../../global/types";


type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

export function VariantesForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [nome, setNome] = useState("");

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0)
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando cadastro...");

    await api.get(`variantes/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IVariantes;

        setNome(data.nome);
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    if (nome.length <= 0) return alert("Preencha o campo Nome");

    setLoading(true);
    setTxtLoading("Salvando Variantes...")

    let data: ICreateVarianteDTO = { nome };

    if (tipo === "Edit")
      data = { ...data, id: params.id };

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("variantes", { ...data })
      .then((result) => { })
      .catch((err) => {
        if (err.response.data.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        navigate(-1);
      })
  }

  useEffect(() => {
    loadCadastro();
  }, [token]);

  return (
    <Topo page="Variantes" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Variantes`}>

      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form">
          <div className="contentInput" style={{ width: "100%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome"
            />
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>

        </div>
      )}

    </Topo>
  )
}