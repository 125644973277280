import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
  faTimes,
  faUsers,
  faPrint,
  faBan,
  faInfoCircle,
  faRetweet,
  faFileImport,
  faMailBulk
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IClientes, IEmpresa, IListNfe, IListRequest, INfeInfo } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { convertToUs, convertToUTC } from "../../utils/dateProvider";
import Modal from "react-modal";
import word_loading from "../../assets/word_loading.json";

import "./style.css";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { currencyFormatter } from "../../utils/currencyFormatter";
import Lottie from "lottie-react-web";
import axios from "axios";
import { NfeModalMotivo } from "../../modal/NfeModalMotivo";
import { NfeModalInfo } from "../../modal/NfeModalInfo";
import { NfeModalImportar } from "../../modal/NfeModalImportar";
import { NfeModalEnviaContabilidade } from "../../modal/nfeModalEnviaContabilidade";

type IFilterNfe = {
  empresa?: string;
  cliente?: IClientes;
  date_ini?: string;
  date_fin?: string;
}

const defaultFilterNfe: IFilterNfe = {
  empresa: "",
  cliente: {} as IClientes,
  date_ini: "",
  date_fin: ""
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Nfe() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [pesquisa, setPesquisa] = useState<IFilterNfe>(defaultFilterNfe);
  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listNfe, setListNfe] = useState<IListNfe[]>([]);

  const [nfeChecked, setNfeChecked] = useState<IListNfe[]>([]);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCliente, setModalCliente] = useState(false);
  const [modalMotivo, setModalMotivo] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalImportar, setModalImportar] = useState(false);
  const [modalEnviaContabilidade, setModalEnviaContabilidade] = useState(false);

  const [allChecked, setAllChecked] = useState(false);

  const [nfeSelected, setNfeSelected] = useState({} as IListNfe);

  const [limitPage, setLimitPage] = useState(25);

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setPesquisa(y => ({ ...y, empresa: result.data.result[0].id }))
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => { });
  }

  const loadListNfe = async (canPesquisa = false) => {

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de pedidos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa) {
      dataRequest = {
        ...dataRequest,
        pesquisa: {
          cliente: pesquisa.cliente ? pesquisa.cliente.id : null,
          date_ini: pesquisa.date_ini ? `${convertToUs(pesquisa.date_ini)} 00:00:00` : null,
          date_fin: pesquisa.date_fin ? `${convertToUs(pesquisa.date_fin)} 23:59:59` : null,
          empresa: pesquisa.empresa ? pesquisa.empresa : null,
        }
      };
    }

    await api.post("nfe/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        setListNfe(response.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");

        if (listEmpresas.length <= 0)
          loadEmpresas();
      });
  }

  const changeDateIni = (value: string) => {
    setPesquisa(e => ({ ...e, date_ini: value }));
  }

  const changeDateFin = (value: string) => {
    setPesquisa(e => ({ ...e, date_fin: value }));
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setPesquisa(e => ({ ...e, cliente }));
  }

  const handlePrintNfe = async (itemNfe: IListNfe) => {
    try {
      const tempListNfe: IListNfe[] = [];
      listNfe.map((item) => {
        if (item.id === itemNfe.id)
          tempListNfe.push({ ...item, loading: "Gerando Danfe..." });
        else
          tempListNfe.push(item);
      });
      setListNfe(tempListNfe);

      if (!token)
        return;

      const rsCheckToken = await checkToken(cacheError, token);

      if (rsCheckToken.redirectLogin)
        navigate("/login");

      const resInfoNfe = await api.get(`nfe/${itemNfe.id}`)
      const infoNfe = resInfoNfe.data as INfeInfo;

      const formData = new FormData();
      formData.append("xml[]", `${infoNfe.list_xml.find(i => i.acao === "xml")?.xml}`);

      const response = await axios.post("https://dcifiscalphp.dcisuporte.com.br/danfe.php", formData, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      });

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      const pdfWindow = window.open()

      if (pdfWindow !== null)
        pdfWindow.location.href = fileURL

    } catch (err) {
      console.log(err);
      alert("Erro ao gerar Danfe");
    }
    finally {
      const tempListNfe: IListNfe[] = [];
      listNfe.map((item) => {
        if (item.id === itemNfe.id)
          tempListNfe.push({ ...item, loading: "" });
        else
          tempListNfe.push(item);
      });

      setListNfe(tempListNfe);
    }
  }

  const handleCancelarNfe = async (itemNfe: IListNfe) => {
    setNfeSelected(itemNfe);
    setModalMotivo(true);
  }

  const handleInfoNfe = async (itemNfe: IListNfe) => {
    setNfeSelected(itemNfe);
    setModalInfo(true);
  }

  const handleEnviaCancelamento = async (motivo: string) => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setModalMotivo(false);
    const tempListNfe: IListNfe[] = [];
    listNfe.map((item) => {
      if (item.id === nfeSelected.id)
        tempListNfe.push({ ...item, loading: "Enviando solicitação para cancelamento de NFe..." });
      else
        tempListNfe.push(item);
    });
    setListNfe(tempListNfe);

    await api.delete("nfe/cancelar", { data: { id: nfeSelected.id, motivo } })
      .then(() => {
        alert("Pedido de cancelamento de NFe enviada... Em alguns instantes a resposta estará disponível.");
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pedido de cancelamento: " + err.response.data.message);
      })
      .finally(() => {
        const tempListNfe: IListNfe[] = [];
        listNfe.map((item) => {
          if (item.id === nfeSelected.id)
            tempListNfe.push({
              ...item,
              loading: "",
              hiddenBtn: true,
              situacao: ""
            });
          else
            tempListNfe.push(item);
        });

        setListNfe(tempListNfe);
      });
  }

  const handleTentarNovamente = async (itemNfe: IListNfe) => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const tempListNfe: IListNfe[] = [];
    listNfe.map((item) => {
      if (item.id === itemNfe.id)
        tempListNfe.push({ ...item, loading: "Incluindo na lista de trabalhos" });
      else
        tempListNfe.push(item);
    });
    setListNfe(tempListNfe);

    if (itemNfe.protocolo.length > 4)
      handleCancelarNfe(itemNfe);
    else {
      await api.post("nfe/emitir", { id: itemNfe.id })
        .then(() => { })
        .catch((err) => {
          if (err.response.status !== 401)
            alert("Pedido de emissão: " + err.response.data.message);
        })
        .finally(() => {
          const tempListNfe: IListNfe[] = [];
          listNfe.map((item) => {
            if (item.id === itemNfe.id)
              tempListNfe.push({ ...item, loading: "", hiddenBtn: true, situacao: "" });
            else
              tempListNfe.push(item);
          });
          setListNfe(tempListNfe);
        });
    }
  }

  const handleCheckbox = (pedido: IListNfe) => {
    const newList: IListNfe[] = [];
    let canAdd = true;

    nfeChecked.map((item) => {
      if (item.id !== pedido.id)
        newList.push(item);
      else
        canAdd = false;
    });

    if (canAdd)
      newList.push(pedido);

    setNfeChecked(newList);
  }

  const handlePrintAll = async () => {
    if (!window.confirm("Deseja realmente imprimir todos as NFe?"))
      return;

    try {

      if (!token)
        return;

      setLoading(true);
      setTxtLoading("Gerando Danfe das NFe selecionadas...");

      const rsCheckToken = await checkToken(cacheError, token);

      if (rsCheckToken.redirectLogin)
        navigate("/login");

      const formData = new FormData();

      await Promise.allSettled(
        nfeChecked.map(async (itemNfe) => {
          const resInfoNfe = await api.get(`nfe/${itemNfe.id}`)
          const infoNfe = resInfoNfe.data as INfeInfo;

          formData.append("xml[]", `${infoNfe.list_xml.find(i => i.acao === "xml")?.xml}`);
        })
      );

      const response = await axios.post("https://dcifiscalphp.dcisuporte.com.br/danfe.php", formData, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      });

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      const pdfWindow = window.open()

      if (pdfWindow !== null)
        pdfWindow.location.href = fileURL

    } catch (err) {
      console.log(err);
      setAlert("Erro ao gerar as Danfe", "danger");
    }
    finally {
      setLoading(false);
      setTxtLoading("");
    }


  }

  const handleAllChecked = () => {
    if (allChecked) {
      setNfeChecked([]);
      setAllChecked(false);
      return;
    }

    const newList: IListNfe[] = [];

    listNfe.map((item) => {
      if (!item.cancelado && item.situacao === "AUTORIZADA")
        newList.push(item);
    })

    setNfeChecked(newList);
  }

  useEffect(() => {
    loadListNfe();
  }, [token]);

  useEffect(() => {
    if (maxPage > 1)
      loadListNfe(true);
  }, [page, maxPage]);

  useEffect(() => {
    let qtdNfe = 0;

    listNfe.map(item => {
      if (!item.cancelado && item.situacao === "AUTORIZADA")
        qtdNfe++;
    });

    setAllChecked(nfeChecked.length >= qtdNfe ? true : false);

  }, [nfeChecked, listNfe])

  return (
    <Topo page="Nfe" title="NFe">
      <div id="NfePage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            <button className="btnImport" onClick={() => setModalImportar(true)}>
              <FontAwesomeIcon icon={faFileImport} /> Importar XML
            </button>

            <button className="btnImport" style={{ marginRight: "10px" }} onClick={() => setModalEnviaContabilidade(true)}>
              <FontAwesomeIcon icon={faMailBulk} /> Enviar para Contabilidade
            </button>

            <div className="divPesquisa">
              <div className="pesqEmpresa">
                <span>Empresa</span>
                <select value={pesquisa.empresa} onChange={(e) => setPesquisa(y => ({ ...y, empresa: e.target.value }))}>
                  <option value="">Pesquisa por Empresa</option>
                  {listEmpresas.length > 0 && listEmpresas.map((item) => (
                    <option key={item.id} value={item.id}>{item.fantasia}</option>
                  ))}
                </select>
              </div>

              <div className="pesqCliente">
                <span>Cliente</span>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                  <Input
                    mask="none"
                    value={pesquisa.cliente?.fantasia ? pesquisa.cliente?.fantasia : ""}
                    inputMaskChange={null}
                    placeholder="Pesquisa por Cliente"
                    readOnly
                  />
                  {pesquisa.cliente?.fantasia && (
                    <button className="btn-orange" onClick={() => setPesquisa(e => ({ ...e, cliente: {} as IClientes }))}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                    <FontAwesomeIcon icon={faUsers} />
                  </button>
                </div>
              </div>

              <div className="pesqDate">
                <span>Data Início</span>
                <Input
                  mask="date"
                  value={pesquisa.date_ini}
                  inputMaskChange={changeDateIni}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div className="pesqDate">
                <span>Data Final</span>
                <Input
                  mask="date"
                  value={pesquisa.date_fin}
                  inputMaskChange={changeDateFin}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div>
                <span>Pedidos por Página</span>
                <select value={limitPage} onChange={(e) => setLimitPage(parseInt(e.target.value))}>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
                <button className="btn-blue-dark" onClick={() => loadListNfe(true)}>
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: "5px" }} /> PESQUISAR
                </button>
              </div>

            </div>

            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "75px" }}>
                  <input
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    checked={allChecked}
                    onChange={handleAllChecked}
                  />
                  Nr.
                </span>
                <span style={{ width: "200px" }}>Cliente</span>
                <span style={{ width: "200px" }}>Empresa</span>
                <span style={{ width: "50px", textAlign: "center" }}>Data/Hora</span>
                <span style={{ width: "100px", textAlign: "right" }}>Valor</span>
                <span style={{ width: "70px", textAlign: "center" }}>Situação</span>
                <span style={{ width: "140px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable">
                {listNfe.map((item) => (
                  <div className={`rowTable ${item.cancelado ? 'cancelada' : ''}`} key={item.id}>
                    {item.loading && item.loading.length > 3 && (
                      <span style={{ width: "100%", textAlign: "center" }}>{item.loading}</span>
                    )}

                    {(!item.loading || item.loading.length < 3) && (
                      <>
                        <span style={{ width: "75px" }}>
                          {!item.hiddenBtn && !item.cancelado && item.situacao !== "ERRO" && (
                            <input
                              type="checkbox"
                              style={{ width: "20px", height: "20px" }}
                              checked={nfeChecked.find(i => i.id === item.id)?.id ? true : false}
                              onChange={() => handleCheckbox(item)}
                            />
                          )}
                          {item.nr_nfe}
                        </span>
                        <span style={{ width: "200px" }}>
                          {item.cliente.fantasia}
                        </span>
                        <span style={{ width: "200px" }}>{item.empresa.fantasia}</span>
                        <span style={{ width: "50px", textAlign: "right" }}>{convertToUTC(item.created_at)}</span>
                        <span style={{ width: "100px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.total - item.desconto}`))}</span>
                        <span style={{ width: "70px", textAlign: "center" }}>
                          {item.situacao.length > 3 && (
                            item.situacao
                          )}

                          {item.situacao.length <= 1 && (
                            <Lottie options={{ animationData: word_loading }} width={80} height={80} />
                          )}
                        </span>
                        <span style={{ width: "140px", textAlign: "center" }}>
                          {!item.hiddenBtn && !item.cancelado && item.situacao !== "ERRO" && (
                            <>
                              <button className="btn-green" onClick={() => handlePrintNfe(item)}>
                                <FontAwesomeIcon icon={faPrint} />
                              </button>

                              <button className="btn-red" onClick={() => handleCancelarNfe(item)}>
                                <FontAwesomeIcon icon={faBan} />
                              </button>
                            </>
                          )}

                          {item.situacao === "ERRO" && (
                            <button className="btn-orange" onClick={() => handleTentarNovamente(item)}>
                              <FontAwesomeIcon icon={faRetweet} />
                            </button>
                          )}

                          <button onClick={() => handleInfoNfe(item)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </button>
                        </span>
                      </>
                    )}
                  </div>
                ))}
              </div>

              {nfeChecked.length > 0 && (
                <button style={{ padding: "5px 15px", marginTop: "10px" }} onClick={() => handlePrintAll()}>
                  Imprimir NFe selecionadas ( {nfeChecked.length} )
                </button>
              )}

              <div className="pagination">
                {page !== 1 && (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
                )}
                <p>
                  {page}/{maxPage}
                </p>
                {page !== maxPage && (
                  <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
                )}
              </div>

            </div>

          </>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>

        <Modal isOpen={modalMotivo} style={{
          content: { ...styleModal.content, width: 335, height: 210 },
          overlay: { ...styleModal.overlay }
        }}>
          <NfeModalMotivo closeModal={() => setModalMotivo(false)} handleEnviar={(e) => handleEnviaCancelamento(e)} />
        </Modal>

        <Modal isOpen={modalInfo} style={styleModal}>
          <NfeModalInfo closeModal={() => setModalInfo(false)} nfe={nfeSelected} />
        </Modal>

        <Modal isOpen={modalImportar} style={{
          content: { ...styleModal.content, width: 335, height: 210 },
          overlay: { ...styleModal.overlay }
        }}>
          <NfeModalImportar closeModal={() => setModalImportar(false)} />
        </Modal>

        <Modal isOpen={modalEnviaContabilidade} style={{
          content: { ...styleModal.content, width: 335, height: 210 },
          overlay: { ...styleModal.overlay }
        }}>
          <NfeModalEnviaContabilidade closeModal={() => setModalEnviaContabilidade(false)} />
        </Modal>

      </div>
    </Topo >
  );
}