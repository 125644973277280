import { faCodeBranch, faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICreateProdutosDTO, ICreateProdutosVariantesDTO, IProduto, IProdutoVariante, IVariantes } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { VerificaNCM } from "../../services/apiNCM";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { dynamicSort } from "../../utils/dynamicSort";
import Modal from "react-modal";

import "./style.css";
import { VariantesValoresModal } from "../../modal/VariantesValoresModal";

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

type IVariantesSelected = {
  variant: string;
  selected: string;
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 500 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function ProdutosForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cacheProduto, setCacheProduto] = useState<IProduto>({} as IProduto);
  const [nome, setNome] = useState("");
  const [codBarras, setCodBarras] = useState("");
  const [ncm, setNcm] = useState("");
  const [cfop, setCfop] = useState("");
  const [unidMed, setUnidMed] = useState("");
  const [preco, setPreco] = useState("");
  const [favorito, setFavorito] = useState(false);

  const [variantes, setVariantes] = useState<IProdutoVariante[]>([]);
  const [variantesSelected, setVariantesSelected] = useState<IVariantesSelected[]>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [varianteSelected, setVarianteSelected] = useState("");
  const [titleModal, setTitleModal] = useState("");

  const [listVariantes, setListVariantes] = useState<IVariantes[]>([]);

  const loadVariantes = async (firstRun = true) => {
    if (!token)
      return;

    if (firstRun) {
      setLoading(true);
      setTxtLoading("Carregando variantes...");
    }

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("variantes/findAll", { cursor: 0, limit: 100 })
      .then((result) => {
        if (!result.data)
          return;

        setListVariantes(result.data.result);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        if (firstRun)
          loadCadastro();
      })
  }

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0) {
      setLoading(false);
      setTxtLoading("");
      return;
    }

    setTxtLoading("Carregando cadastro...");

    await api.get(`produtos/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IProduto;

        setCacheProduto(data);
        setVariantes(data.variantes);
        setNome(data.nome);
        setCodBarras(data.cod_barras);
        setNcm(data.ncm);
        setCfop(`${data.cfop}`);
        setUnidMed(data.unid_med);
        setPreco(currencyFormatter(parseFloat(`${data.preco}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        }));

        setFavorito(data.favorito);

        const newVariantesSelected: IVariantesSelected[] = [];

        data.variantes.map((v) => {
          if (!v.excluir)
            newVariantesSelected.push({
              variant: v.id_variante,
              selected: v.id_variante_valores
            });
        });

        setVariantesSelected(newVariantesSelected);

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleChangeValueVariant = (idVariante: string, idValue: string) => {
    const newListVariante = listVariantes.map((v) => {
      if (idVariante === v.id) {
        const newVariantesValores = v.variante_valores.map((vv) => {
          if (vv.id === idValue)
            return { ...vv, selected: true };
          else
            return { ...vv, selected: false };
        });
        return { ...v, variante_valores: newVariantesValores };
      } else {
        return v;
      }
    });

    setListVariantes(newListVariante);
  }

  const handleOpenModal = (id: string, nome: string) => {
    setVarianteSelected(id);
    setTitleModal(nome);
    setModalOpen(true);
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    if (nome.length < 3) alert("Preencha o campo Nome do Produto");
    if (preco.length < 1) alert("Informe o valor do produto");
    if (unidMed.length < 1) alert("Informe a unidade de Medida");

    setLoading(true);
    setTxtLoading("Verificando login...");

    const newPreco = parseFloat(preco.replace(".", "").replace(",", "."));

    let data: ICreateProdutosDTO = {
      nome,
      cod_barras: codBarras,
      ncm,
      cfop: parseInt(cfop),
      unid_med: unidMed,
      preco: newPreco,
      favorito
    }

    if (tipo === "Edit")
      data = { ...data, id: params.id };

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let
      idProduto: string = "",
      requestError: boolean = false;

    setTxtLoading("Verificando NCM...");

    const verifyNCM = await VerificaNCM(data.ncm);

    if (!verifyNCM) {
      setAlert("NCM incorreto", "danger");
      //setLoading(false);
      //setTxtLoading("");
      //return;
    }

    setTxtLoading("Salvando dados do Produtos");

    await api.post("produtos", { ...data })
      .then((result) => {
        if (!result.data)
          return;

        idProduto = result.data.id;
      })
      .catch((err) => {
        requestError = true;
        if (err.response.data.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        if (idProduto.length > 0)
          handleSaveVariantes(idProduto);
        else {
          if (!requestError)
            navigate(-1);
          else {
            setLoading(false)
            setTxtLoading("");
          }
        }
      });
  }

  const handleSaveVariantes = async (idProduto: string) => {
    if (!token)
      return;

    setTxtLoading("Salvando dados das variantes...");

    const requestVariantes: ICreateProdutosVariantesDTO[] = [];

    variantesSelected.map((item) => {
      let newRequestVariante: ICreateProdutosVariantesDTO = {
        id_produtos: idProduto,
        id_variante: item.variant,
        id_variante_valores: item.selected
      };

      if (tipo === "Edit") {
        const idProdutoVariante = cacheProduto.variantes.find(i => i.id_variante === item.variant && !i.excluir);
        if (idProdutoVariante)
          newRequestVariante = { ...newRequestVariante, id: idProdutoVariante.id }
      }

      requestVariantes.push(newRequestVariante);
    });

    if (requestVariantes.length <= 0)
      navigate(-1);
    else {
      const rsCheckToken = await checkToken(cacheError, token);
      if (rsCheckToken.redirectLogin)
        navigate("/login");

      await api.post("produtos/variantes", { data: [...requestVariantes] })
        .then(() => { })
        .catch((err) => {
          if (err.response.data.status != 401)
            setAlert(err.response.data.message, "danger");
        })
        .finally(() => {
          navigate(-1);
        });
    }

  }

  useEffect(() => {
    loadVariantes();
  }, [token]);

  useEffect(() => {
    const newVariantesSelected: IVariantesSelected[] = [];

    listVariantes.map((v) => {
      v.variante_valores.map((vv) => {
        if (vv.selected) {
          newVariantesSelected.push({
            variant: v.id,
            selected: vv.id
          });
        }
      })
    });

    setVariantesSelected(newVariantesSelected);
  }, [listVariantes]);

  useEffect(() => {
    if (!modalOpen && listVariantes.length > 0)
      loadVariantes(false);
  }, [modalOpen]);

  return (
    <Topo page="Produtos" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Produto`}>

      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form" id="produtosFormPage">

          <div className="contentInput" style={{ width: "50%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Preço:</span>
            <Input
              mask="currency"
              value={preco}
              inputMaskChange={setPreco}
              placeholder="R$0,00"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Código de Barras:</span>
            <Input
              mask="none"
              value={codBarras}
              inputMaskChange={setCodBarras}
              placeholder="Código de Barras"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>NCM:</span>
            <Input
              mask="none"
              value={ncm}
              inputMaskChange={setNcm}
              placeholder="NCM"
            />
          </div>

          <div className="contentInput" style={{ width: "33%" }}>
            <span>CFOP:</span>
            <Input
              mask="none"
              value={cfop}
              inputMaskChange={setCfop}
              placeholder="CFOP"
              type="number"
            />
          </div>

          <div className="contentInput" style={{ width: "34%" }}>
            <span>Unidade de Medida asd:</span>
            <select value={unidMed} onChange={(e) => setUnidMed(e.target.value)}>
              <option value="">SELECIONE</option>
              <option value="AMPOLA">AMPOLA</option>
              <option value="BALDE">BALDE</option>
              <option value="BANDEJ">BANDEJA</option>
              <option value="BARRA">BARRA</option>
              <option value="BISNAG">BISNAGA</option>
              <option value="BLOCO">BLOCO</option>
              <option value="BOBINA">BOBINA</option>
              <option value="BOMBEAR">BOMBONA</option>
              <option value="CÁPSULAS">CÁPSULA</option>
              <option value="CARRINHO">CARTELA</option>
              <option value="CENTO">CENTO</option>
              <option value="CJ">CONJUNTO</option>
              <option value="CM">CENTÍMETRO</option>
              <option value="CM2">CENTIMETRO QUADRADO</option>
              <option value="CX">CAIXA</option>
              <option value="CX2">CAIXA COM 2 UNIDADES</option>
              <option value="CX3">CAIXA COM 3 UNIDADES</option>
              <option value="CX5">CAIXA COM 5 UNIDADES</option>
              <option value="CX10">CAIXA COM 10 UNIDADES</option>
              <option value="CX15">CAIXA COM 15 UNIDADES</option>
              <option value="CX20">CAIXA COM 20 UNIDADES</option>
              <option value="CX25">CAIXA COM 25 UNIDADES</option>
              <option value="CX50">CAIXA COM 50 UNIDADES</option>
              <option value="CX100">CAIXA COM 100 UNIDADES</option>
              <option value="DISP">EXIBIÇÃO</option>
              <option value="DUZIA">DUZIA</option>
              <option value="EMBAL">EMBALAGEM</option>
              <option value="FARDO">FARDO</option>
              <option value="FOLHA">FOLHA</option>
              <option value="FRASCO">FRASCO</option>
              <option value="GALAO">GALÃO</option>
              <option value="GF">GARRAFA</option>
              <option value="GRAMAS">GRAMAS</option>
              <option value="JOGO">JOGO</option>
              <option value="KG">QUILOGRAMA</option>
              <option value="KIT">KIT</option>
              <option value="LATA">LATA</option>
              <option value="LITRO">LITRO</option>
              <option value="M">METRO</option>
              <option value="M2">METRO QUADRADO</option>
              <option value="M3">METRO CÚBICO</option>
              <option value="MILHEI">MILHEIRO</option>
              <option value="ML">MILILITRO</option>
              <option value="MWH">MEGAWATT HORA</option>
              <option value="PACOTE">PACOTE</option>
              <option value="PALETE">PALETE</option>
              <option value="PARES">PARES</option>
              <option value="PC">PEÇA</option>
              <option value="AMIGO">AMIGO</option>
              <option value="K">QUILATE</option>
              <option value="RESMA">RESMA</option>
              <option value="ROLO">ROLO</option>
              <option value="SACO">SACO</option>
              <option value="SACOLA">SACOLA</option>
              <option value="TAMBOR">TAMBOR</option>
              <option value="TANQUE">TANQUE</option>
              <option value="TON">TONELADA</option>
              <option value="TUBO">TUBO</option>
              <option value="UNID">UNIDADE</option>
              <option value="VASIL">VASILHAME</option>
              <option value="VIDRO">VIDRO</option>
            </select>
          </div>

          {listVariantes.length > 0 && listVariantes.map((v) =>
          (
            <div key={v.id} className="contentInput" style={{ width: "33.33%" }}>
              <span>{v.nome}:</span>
              <div className="flexRow">
                <select value={variantesSelected.find(i => i.variant === v.id)?.selected} onChange={(e) => handleChangeValueVariant(v.id, e.target.value)}>
                  <option value="">Selecione</option>
                  {v.variante_valores.sort(dynamicSort("nome")).map((vv) => {
                    if (!vv.excluir) {
                      return (<option key={vv.id} value={vv.id}>{vv.nome}</option>)
                    }
                  })}
                </select>
                <button className="btn-green" onClick={() => handleOpenModal(v.id, v.nome)}>
                  <FontAwesomeIcon icon={faCodeBranch} />
                </button>
              </div>
            </div>
          )
          )}

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Favorito:</span>
            <select value={favorito ? "1" : "0"} onChange={(e) => setFavorito(e.target.value === "1" ? true : false)}>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>

        </div>
      )}

      <Modal isOpen={modalOpen} style={styleModal}>
        <VariantesValoresModal id={varianteSelected} closeModal={() => setModalOpen(false)} title={titleModal} />
      </Modal>

    </Topo>
  )
}