import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Load } from "../../components/Load";
import { ICreateOrdemServicoDTO, ICreateOrdemServicoStatusDTO, IOrdemServico, IOrdemServicoStatus, IStatus } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { convertToUTC } from "../../utils/dateProvider";
import { dynamicSort } from "../../utils/dynamicSort";

type IProps = {
  closeModal: () => void;
  refresh: () => Promise<void>;
  idOrdemServico: string;
  listStatusOrdemServico: IOrdemServicoStatus[] | null;
}

export function OrdemServicoModalStatus({ closeModal, refresh, idOrdemServico, listStatusOrdemServico }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [status, setStatus] = useState("");

  const [listStatus, setListStatus] = useState<IStatus[]>([]);

  const loadStatus = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("status")
      .then((result) => {
        if (!result.data)
          return;

        setListStatus(result.data.result);
      })
  }

  const handleSalvar = async () => {
    if (status.length <= 3)
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Salvando status");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const newStatus: ICreateOrdemServicoStatusDTO[] = [];

    newStatus.push({
      id_status: status,
      id_ordem_servico: idOrdemServico
    });

    try {

      const reqOrdemServico = await api.get(`ordemServico/${idOrdemServico}`);

      if (!reqOrdemServico.data)
        return;

      const ordemServico = reqOrdemServico.data as IOrdemServico;

      const dataUpdateOrdemServico: ICreateOrdemServicoDTO = {
        id: ordemServico.id,
        id_cliente: ordemServico.id_cliente,
        id_empresa: ordemServico.id_empresa,
        id_status: status,
        id_user: ordemServico.id_user,
        descricao: ordemServico.descricao,
        previsao: ordemServico.previsao,
      };

      await api.post("ordemServico", { ...dataUpdateOrdemServico });

      await api.post("ordemServico/status", { data: newStatus });

      setTimeout(async () => {
        await refresh();

        setLoading(false);
        setTxtLoading("");
      }, 1000);
    } catch (err) {
      console.log(err);
      const teste: any = err;

      if (teste.response.data.message)
        alert(teste.response.data.message);
      else
        alert("Erro ao salvar o status");

      setLoading(false);
      setTxtLoading("");
    }

  }

  useEffect(() => {
    loadStatus();
  }, [token]);

  return (
    <div className="modal">
      <div className="title">
        <p>Status</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <div style={{ height: "100%" }}>
            <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
          </div>
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">Selecione</option>
                {listStatus.sort(dynamicSort("ordem")).map((item) => (
                  <option key={item.id} value={item.id}>{item.nome}</option>
                ))}
              </select>

              <button className="btn-green" onClick={handleSalvar}>
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>

            <div className="headTable">
              <span>Observações</span>
              <span>Data/Hora</span>
            </div>
            <div className="contentTable">
              {listStatusOrdemServico !== null && listStatusOrdemServico.sort(dynamicSort("-created_at")).map((item) => (
                <div className="rowTable" key={item.id}>
                  <span>{item.status.nome}</span>
                  <span>{convertToUTC(item.created_at)}</span>
                </div>
              ))}
            </div>

          </>
        )}

      </div>

    </div>
  )
}