import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { Load } from "../../components/Load";
import { IEmpresa } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

import "./style.css";
import { Input } from "../../components/Input";
import { addYears, convertToDate, dateNow } from "../../utils/dateProvider";

type IProps = {
  closeModal: () => void;
  empresa: IEmpresa;
}

export function EmpresaModalCertificado({ closeModal, empresa }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [vencimento, setVencimento] = useState(addYears(1));

  const handleSubmit = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Enviando certificado...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const formData = new FormData();
    formData.append("cert", selectedFile, selectedFile.name);
    formData.append("empresa_id", empresa.id);
    formData.append("venc_cert", convertToDate(vencimento).toString());

    await api.patch("empresas/certificado", formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        alert("Upload realizado com sucesso!");
        closeModal();
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  return (
    <div className="divModalCert">
      <div className="title">
        <p>Certificado: {empresa.fantasia}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <div className="divFormModal">
            <span>Certificado:</span>
            <input type="file" onChange={(e) => setSelectedFile(e.target.files ? e.target.files[0] : null)} />

            <span style={{ marginTop: "15px" }}>Data de Vencimento</span>
            <Input
              mask="date"
              value={vencimento}
              inputMaskChange={setVencimento}
              placeholder="dd/mm/aaaa"
              maxLength={10}
            />

            <button value="Enviar" onClick={handleSubmit} className="btn-green">
              <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "5px" }} />
              Enviar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}