import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICaixa, ICreateFinanceiroDTO, ICreateOrdemServicoPgtosDTO, IFormaPgto, IFormaPgtoBand, IFormaPgtoBandTaxa, IOrdemServicoPgtos } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { dynamicSort } from "../../utils/dynamicSort";

import "./style.css";

type IProps = {
  closeModal: () => void;
  refresh: () => Promise<void>;
  idOrdemServico: string;
  listPgtos: IOrdemServicoPgtos[] | null;
  totalOrdemServico: number | undefined;
};

export function OrdemServicoModalPgtos({ closeModal, refresh, idOrdemServico, listPgtos, totalOrdemServico }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listFormaPgtos, setListFormaPgto] = useState<IFormaPgto[]>([]);
  const [listBandeiras, setListBandeiras] = useState<IFormaPgtoBand[]>([]);
  const [listTaxas, setListTaxas] = useState<IFormaPgtoBandTaxa[]>([]);

  const [maxParcelas, setMaxParcelas] = useState(0);
  const [totalPago, setTotalPago] = useState(0);

  const [valor, setValor] = useState("");
  const [formaPgto, setFormaPgto] = useState("");
  const [bandeira, setBandeira] = useState("");
  const [taxa, setTaxa] = useState("");

  const loadFormaPgto = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("formaPgto")
      .then((result) => {
        if (!result.data)
          return;

        setListFormaPgto(result.data.result);
      })
  }

  const focusOutValor = (valor: string) => {
    const nValor = parseFloat(valor.replace(".", "").replace(",", "."));
    const valorRestante = parseFloat(`${totalOrdemServico}`) - parseFloat(`${totalPago}`);
    if (nValor > valorRestante)
      setValor(currencyFormatter(valorRestante, {
        significantDigits: 2,
        thousandsSeparator: ".",
        decimalSeparator: ",",
        symbol: "",
      }));
  }

  const handleSalvar = async () => {
    const nValor = parseFloat(valor.replace(".", "").replace(",", "."));

    if (nValor <= 0 || taxa.length <= 3)
      return;

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Salvando pagamento...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    // const newPgto: ICreateOrdemServicoPgtosDTO[] = [];

    let qtd_parcela = 0;
    let parc_procentagem = 0;
    let taxa_recebimento = 0;

    listFormaPgtos.map((item) => {
      if (item.id === formaPgto)
        item.bandeiras.map((rs) => {
          if (rs.id === bandeira)
            rs.taxas.map((rsT) => {
              if (rsT.id === taxa) {
                qtd_parcela = rsT.nr_parcela;
                parc_procentagem = rsT.porcentagem;
                taxa_recebimento = rs.taxa_recebimento;
              }
            })
        })
    });

    const newPgto = {
      qtd_parcela,
      valor: nValor,
      taxa_recebimento,
      parc_procentagem,
      id_forma_pgto: formaPgto,
      id_forma_pgto_band: bandeira,
      id_ordem_servico: idOrdemServico
    };

    try {
      const pgtoRequest = await api.post("ordemServico/pgto", { data: newPgto });

      if (!pgtoRequest.data)
        throw new Error("Erro ao salvar pgto.");

      const resultPgto = pgtoRequest.data as IOrdemServicoPgtos;

      setTxtLoading("Recuperando informações do caixa...");

      const caixaRequest = await api.get("caixa/getCaixaAberto");

      if (!caixaRequest.data)
        throw new Error("Erro ao recuperar informações do caixa");

      const caixa = caixaRequest.data as ICaixa;

      setTxtLoading("Adicionando pagamento ao caixa...");

      const requestFinanceiro: ICreateFinanceiroDTO = {
        id_caixa: caixa.id,
        id_ordem_servico_pgtos: resultPgto.id,
        descricao: "Pgto ordem de Serviço",
        credito: nValor,
        debito: 0
      };

      await api.post("financeiro", requestFinanceiro);

      setTimeout(async () => {
        await refresh();

        setLoading(false);
        setTxtLoading("");
      }, 1000);

    } catch (err) {
      console.log(err);
      const teste: any = err;

      if (teste.response.data.message)
        alert(teste.response.data.message);
      else
        alert("Erro ao salvar o pagamento");

      setLoading(false);
      setTxtLoading("");
    }

  }

  useEffect(() => {
    loadFormaPgto();
  }, [token]);

  useEffect(() => {
    let newTotalPago = 0;

    listPgtos?.map((item) => {
      newTotalPago += item.valor
    });

    setTotalPago(newTotalPago);
  }, [listPgtos]);

  useEffect(() => {
    setListTaxas([]);
    setBandeira("");
    setTaxa("");

    const newList: IFormaPgtoBand[] = [];

    listFormaPgtos.map((item) => {
      if (item.id === formaPgto) {
        setMaxParcelas(item.max_parcelas);
        item.bandeiras.map((rs) => {
          if (!rs.excluir)
            newList.push(rs);
        })
      }
    });

    setListBandeiras(newList);
  }, [formaPgto]);

  useEffect(() => {
    setTaxa("");

    const newList: IFormaPgtoBandTaxa[] = [];

    listFormaPgtos.map((item) => {
      if (item.id === formaPgto)
        item.bandeiras.map((rs) => {
          if (rs.id === bandeira)
            rs.taxas.map((rsT) => {
              if (!rsT.excluir)
                newList.push(rsT);
            })
        })
    });

    setListTaxas(newList);

  }, [bandeira]);

  return (
    <div className="modal ordemServicoModalPgtos">
      <div className="title">
        <p>Pagamentos</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <div style={{ height: "100%" }}>
            <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
          </div>
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <Input
                mask="currency"
                value={valor}
                inputMaskChange={setValor}
                placeholder="R$0,00"
                onBlur={(e) => focusOutValor(e.target.value)}
              />
            </div>

            <div className="divFormModal">
              <select value={formaPgto} onChange={(e) => setFormaPgto(e.target.value)}>
                <option value="">Selecione a forma de pgto</option>
                {listFormaPgtos.map((item) => (
                  <option key={item.id} value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>

            {listBandeiras.length > 0 && (
              <div className="divFormModal">
                <select value={bandeira} onChange={(e) => setBandeira(e.target.value)}>
                  <option value="">Selecione a bandeira</option>
                  {listBandeiras.map((item) => (
                    <option key={item.id} value={item.id}>{item.nome}</option>
                  ))}
                </select>
              </div>
            )}

            {listTaxas.length > 0 && (
              <div className="divFormModal">
                <select value={taxa} onChange={(e) => setTaxa(e.target.value)}>
                  <option value="">Selecione</option>
                  {listTaxas.sort(dynamicSort("nr_parcela")).map((item) => (
                    item.nr_parcela <= maxParcelas && (
                      <option key={item.id} value={item.id}>{item.nr_parcela}x</option>
                    )
                  ))}
                </select>


                <button className="btn-green" onClick={handleSalvar}>
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            )}

            <div className="headTable">
              <span>Forma Pgto</span>
              <span style={{ width: "100px", textAlign: "right" }}>Valor</span>
            </div>
            <div className="contentTable" style={{ minHeight: "10px" }}>
              {listPgtos !== null && listPgtos.sort(dynamicSort("-created_at")).map((item) => (
                <div className="rowTable" key={item.id}>
                  <span>{item.formaPgto.nome} - {item.bandeira.nome} - {item.qtd_parcela}x</span>
                  <span style={{ width: "100px", justifyContent: "end" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                </div>
              ))}
            </div>
            <div className="footerTable">
              <div>
                <span>TOTAL PAGO: {currencyFormatter(parseFloat(`${totalPago}`))}</span>
              </div>
            </div>

          </>
        )}

      </div>

    </div>
  )
}