import { faCoins, faDollarSign, faHandHoldingUsd, faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICaixa, ICreateCaixaDTO, ICreateFinanceiroDTO } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import Modal from "react-modal";

import "./style.css";
import { CaixaModalValorInicial } from "../../modal/CaixaModalValorInicial";
import { CaixaModalReforcoSangria } from "../../modal/CaixaModalReforcoSangria";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: 300,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Caixa() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [caixaAberto, setCaixaAberto] = useState(false);
  const [caixa, setCaixa] = useState<ICaixa>({} as ICaixa);

  const [tipoReforcoSangria, setTipoReforcoSangria] = useState<"" | "reforco" | "sangria">("");

  const [modalValorInicial, setModalValorInicial] = useState(false);
  const [modalSangriaReforco, setModalSangriaReforco] = useState(false);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const getCaixaAberto = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando informações do caixa");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("caixa/getCaixaAberto")
      .then((result) => {
        if (!result.data)
          return;

        setCaixa(result.data);
        setCaixaAberto(true);
      })
      .catch((err) => {
        setCaixa({} as ICaixa);
        setCaixaAberto(false);

        // if (err.response.status !== 401)
        //   setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleAbrirCaixa = async (valor_inicial: number) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando informações do caixa");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    const request: ICreateCaixaDTO = {
      valor_inicial: valor_inicial
    };

    setModalValorInicial(false);

    await api.post("caixa", { ...request })
      .then((result) => {
        if (!result.data)
          return;

        setCaixa(result.data);
        setCaixaAberto(true);
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const handleSangria = () => {
    setTipoReforcoSangria("sangria");
    setModalSangriaReforco(true);
  }

  const handleReforco = () => {
    setTipoReforcoSangria("reforco");
    setModalSangriaReforco(true);
  }

  const handleSalvarReforcoSangria = async (valor: number, tipo: "reforco" | "sangria", descricao: string) => {
    if (!token)
      return;

    setModalSangriaReforco(false);
    setLoading(true);
    setTxtLoading("Verificando...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setTxtLoading(`Salvando ${tipo === "reforco" ? "Reforço" : "Sangria"}...`);

    const request: ICreateFinanceiroDTO = {
      id_caixa: caixa.id,
      descricao,
      credito: tipo === "reforco" ? valor : 0,
      debito: tipo === "sangria" ? valor : 0
    }

    await api.post("financeiro", request)
      .then((result) => {
        if (!result.data)
          return;

        setAlert(`${tipo === "reforco" ? "Reforço cadastrado" : "Sangria cadastrada"} com sucesso`, "success");
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .then(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  useEffect(() => {
    getCaixaAberto();
  }, [token]);

  return (
    <Topo page="Caixa" title="Caixa">
      <div id="caixaPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            {caixaAberto && (
              <div className="divBtnContent">
                <button className="btn-green btn-xl" onClick={() => handleReforco()}>
                  <FontAwesomeIcon icon={faDollarSign} /> REFORÇO
                </button>
                <button className="btn-red btn-xl" onClick={() => handleSangria()}>
                  <FontAwesomeIcon icon={faHandHoldingUsd} /> SANGRIA
                </button>
                <button className="btn-blue-dark btn-xl" onClick={() => navigate(`/caixaFechamento/${caixa.id}`)}>
                  <FontAwesomeIcon icon={faMoneyCheckAlt} /> FECHAR CAIXA
                </button>
              </div>
            )}

            {!caixaAberto && (
              <>
                <button className="btn-green btn-xl" style={{ margin: "0 auto" }} onClick={() => setModalValorInicial(true)}>
                  <FontAwesomeIcon icon={faCoins} /> ABRIR CAIXA
                </button>
              </>
            )}

          </>
        )}

        <Modal isOpen={modalValorInicial} style={{
          content: { ...styleModal.content, width: 335, height: 210 },
          overlay: { ...styleModal.overlay }
        }}>
          <CaixaModalValorInicial closeModal={() => setModalValorInicial(false)} abrirCaixa={(v) => handleAbrirCaixa(v)} />
        </Modal>

        <Modal isOpen={modalSangriaReforco} style={{
          content: { ...styleModal.content, width: 400, height: 275 },
          overlay: { ...styleModal.overlay }
        }}>
          <CaixaModalReforcoSangria closeModal={() => setModalSangriaReforco(false)} handleEnviar={(v, t, d) => handleSalvarReforcoSangria(v, t, d)} tipo={tipoReforcoSangria} />
        </Modal>

      </div>
    </Topo>
  )
}