import axios from "axios";
import { IIbpt } from "../global/types";

export async function VerificaNCM(ncm: string): Promise<boolean> {
  const TOKEN_IBPT = "SyyDSflvxDwZvUcPGg9xQoHYCpPBbmT562WnltHZacRUF4idaNYpxX92sUU8DI2M";
  const CNPJ_IBPT = "12263597000146";
  const uf = "SP";
  const unid_med = "UNID";
  const valor_unit = "1.00";

  try {
    const res = await axios.get(`https://apidoni.ibpt.org.br/api/v1/produtos?token=${TOKEN_IBPT}&cnpj=${CNPJ_IBPT}&codigo=${ncm}&uf=${uf}&ex=0&descricao=produto&unidadeMedida=${unid_med}&valor=${valor_unit}&gtin=sem%20gtin`)

    const resIbpt = res.data as IIbpt;

    if (resIbpt.Codigo === null)
      return false;

    return true;
  } catch (err) {
    return false;
  }
}