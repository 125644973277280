import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CacheContextProvider } from "./contexts/CacheContext";
import { Agendamentos } from "./pages/Agendamento";
import { AgendamentoForm } from "./pages/AgendamentoForm";
import { Caixa } from "./pages/Caixa";
import { CaixaFechamento } from "./pages/CaixaFechamento";
import { Clientes } from "./pages/Clientes";
import { ClientesForm } from "./pages/ClientesForm";
import { Empresas } from "./pages/Empresas";
import { EmpresasForm } from "./pages/EmpresasForm";
import { FormaPgtoForm } from "./pages/FormaPgtoForm";
import { FormaPgtos } from "./pages/FormaPgtos";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Nfe } from "./pages/Nfe";
import { OrdemServico } from "./pages/OrdemServico";
import { OrdemServicoForm } from "./pages/OrdemServicoForm";
import { Pedidos } from "./pages/Pedidos";
import { PedidosDetalhe } from "./pages/PedidosDetalhe";
import { PedidosForm } from "./pages/PedidosForm";
import { Produtos } from "./pages/Produtos";
import { ProdutosForm } from "./pages/ProdutosForm";
import { RelatorioNFe } from "./pages/RelatorioNFe";
import { RelatorioOrdemServico } from "./pages/RelatorioOrdemServico";
import { Servicos } from "./pages/Servicos";
import { ServicosForm } from "./pages/ServicosForm";
import { Status } from "./pages/Status";
import { StatusForm } from "./pages/StatusForm";
import { Variantes } from "./pages/Variantes";
import { VariantesForm } from "./pages/VariantesForm";


function App() {
  return (
    <BrowserRouter>
      <CacheContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />

          <Route path="/clientes" element={<Clientes />} />
          <Route path="/clientes/novo" element={<ClientesForm tipo="Novo" />} />
          <Route path="/clientes/:id" element={<ClientesForm tipo="Edit" />} />

          <Route path="/variantes" element={<Variantes />} />
          <Route path="/variantes/novo" element={<VariantesForm tipo="Novo" />} />
          <Route path="/variantes/:id" element={<VariantesForm tipo="Edit" />} />

          <Route path="/produtos" element={<Produtos />} />
          <Route path="/produtos/novo" element={<ProdutosForm tipo="Novo" />} />
          <Route path="/produtos/:id" element={<ProdutosForm tipo="Edit" />} />

          <Route path="/pedidos" element={<Pedidos />} />
          <Route path="/pedidos/novo" element={<PedidosForm />} />
          <Route path="/pedidos/:id" element={<PedidosDetalhe />} />

          <Route path="/empresas" element={<Empresas />} />
          <Route path="/empresas/:id" element={<EmpresasForm />} />

          <Route path="/servicos" element={<Servicos />} />
          <Route path="/servicos/novo" element={<ServicosForm tipo="Novo" />} />
          <Route path="/servicos/:id" element={<ServicosForm tipo="Edit" />} />

          <Route path="/status" element={<Status />} />
          <Route path="/status/novo" element={<StatusForm tipo="Novo" />} />
          <Route path="/status/:id" element={<StatusForm tipo="Edit" />} />

          <Route path="/formaPgto" element={<FormaPgtos />} />
          <Route path="/formaPgto/novo" element={<FormaPgtoForm tipo="Novo" />} />
          <Route path="/formaPgto/:id" element={<FormaPgtoForm tipo="Edit" />} />

          <Route path="/ordemServico" element={<OrdemServico />} />
          <Route path="/ordemServico/novo" element={<OrdemServicoForm tipo="Novo" />} />
          <Route path="/ordemServico/relatorio" element={<RelatorioOrdemServico />} />
          <Route path="/ordemServico/:id" element={<OrdemServicoForm tipo="Edit" />} />

          <Route path="/agendamento" element={<Agendamentos />} />
          <Route path="/agendamento/novo" element={<AgendamentoForm tipo="Novo" />} />
          <Route path="/agendamento/:id" element={<AgendamentoForm tipo="Edit" />} />

          <Route path="/caixa" element={<Caixa />} />
          <Route path="/caixaFechamento/:id" element={<CaixaFechamento />} />

          <Route path="/nfe" element={<Nfe />} />
          <Route path="/nfe/relatorio" element={<RelatorioNFe />} />

        </Routes>
      </CacheContextProvider>
    </BrowserRouter>
  );
}

export default App;
