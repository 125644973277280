import { faPlus, faSave, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { useCache } from "../../hooks/useCache";
import { ICreateServicoCkeckListDTO, ICreateServicoComissaoDTO, ICreateServicoDTO, IServico, IServicoCheckList, IServicoComissao, IUsuario } from "../../global/types";

import "./style.css";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

type INewComissao = {
  idUsuario: string;
  nomeUsuario: string;
  comissao: number;
}

export function ServicosForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, txtAlerta, colorAlerta, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [excluindoCheckList, setExcluindoCheckList] = useState(false);
  const [excluindoComissao, setExcluindoComissao] = useState(false);

  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [recorrente, setRecorrente] = useState("0");
  const [dias, setDias] = useState(0);
  const [favorito, setFavorito] = useState(false);

  const [listCheckList, setListCheckList] = useState<IServicoCheckList[]>([]);
  const [listComissao, setListComissao] = useState<IServicoComissao[]>([]);
  const [listUsuarios, setListUsuarios] = useState<IUsuario[]>([]);

  const [descricaoCheckList, setDescricaoCheckList] = useState("");
  const [usuarioSelected, setUsuarioSelected] = useState("");
  const [comissaoUsuario, setComissaoUsuario] = useState("");

  const [newCheckList, setNewCheckList] = useState<string[]>([]);
  const [newComissaoList, setNewComissaoList] = useState<INewComissao[]>([]);

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0)
      return;

    setLoading(true);
    setTxtLoading("Carregando cadastrado");

    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`servicos/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IServico;

        setNome(data.nome);
        setRecorrente(data.recorrente ? "1" : "0");
        setDias(data.recorrente_dias);
        setValor(currencyFormatter(parseFloat(`${data.valor}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        }));

        setListCheckList(data.ckecklist);
        setListComissao(data.comissao);
        setFavorito(data.favorito);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const loadUsuarios = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("users/list")
      .then((result) => {
        if (!result.data)
          return;

        setListUsuarios(result.data.result);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
  }

  const handleAddCheckList = () => {

    const tempExists = newCheckList.find(i => i === descricaoCheckList);
    const checklistExists = listCheckList.find(i => i.nome === descricaoCheckList);

    if (tempExists || checklistExists)
      return alert("Checklist já cadastrada");

    setNewCheckList(i => [descricaoCheckList, ...i]);
    setDescricaoCheckList("");
  }

  const handleDelTempCheckList = (descricao: string) => {
    if (!window.confirm(`Deseja realmente remover ${descricao}?`))
      return;

    const newList: string[] = [];

    newCheckList.map((item) => {
      if (item !== descricao)
        newList.push(item);
    });

    setNewCheckList(newList);
  }

  const handleDelCheckList = async (item: IServicoCheckList) => {
    if (!token) return;

    if (!window.confirm(`Deseja realmente excluir ${item.nome}?`))
      return;

    setExcluindoCheckList(true);

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("/servicos/checklist", { data: { id: item.id } })
      .then(() => {
        const newList: IServicoCheckList[] = [];

        listCheckList.map((rs) => {
          if (item.id !== rs.id)
            newList.push(item);
        });

        setListCheckList(newList);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setExcluindoCheckList(false);
      });
  }

  const handleAddComissao = () => {
    const usuario = listUsuarios.find(i => i.id === usuarioSelected);

    if (!usuario)
      return;

    const tempExists = newComissaoList.find(i => i.idUsuario === usuarioSelected);
    const comissaoExists = listComissao.find(i => i.id_user === usuarioSelected);

    if (tempExists || comissaoExists)
      return alert("Usuário já cadastrado");

    setNewComissaoList(i => [{
      comissao: parseInt(comissaoUsuario),
      idUsuario: usuarioSelected,
      nomeUsuario: usuario.nome
    }, ...i]);

    setUsuarioSelected("");
    setComissaoUsuario("");
  }

  const handleDelTempComissao = (usuario: INewComissao) => {
    if (!window.confirm(`Deseja realmente remover a comissão do(a) ${usuario.nomeUsuario}`))
      return;

    const newList: INewComissao[] = [];

    newComissaoList.map((item) => {
      if (item.idUsuario !== usuario.idUsuario)
        newList.push(item);
    });

    setNewComissaoList(newList);
  }

  const handleDelComissao = async (item: IServicoComissao) => {
    if (!token) return;

    if (!window.confirm(`Deseja realmente excluir a comissão do(a) ${item.usuario.nome}?`))
      return;

    setExcluindoComissao(true);

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("/servicos/comissao", { data: { id: item.id } })
      .then(() => {
        const newList: IServicoComissao[] = [];

        listComissao.map((rs) => {
          if (item.id !== rs.id)
            newList.push(item);
        });

        setListComissao(newList);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setExcluindoCheckList(false);
      });
  }

  const handleSalvar = async () => {
    setLoading(true);
    setTxtLoading("Salvando serviço...");

    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    try {

      const newValor = parseFloat(valor.replace(".", "").replace(",", "."));

      let data: ICreateServicoDTO = {
        nome,
        recorrente: recorrente === "1",
        recorrente_dias: dias,
        valor: newValor,
        favorito
      };

      if (tipo === "Edit")
        data = { ...data, id: params.id };

      const requestServico = await api.post("servicos", { ...data });

      if (!requestServico.data)
        return;

      const servico: IServico = requestServico.data;

      const dataReqChecklist: ICreateServicoCkeckListDTO[] = [];
      const dataReqComissao: ICreateServicoComissaoDTO[] = [];

      if (newCheckList && newCheckList.length > 0)
        newCheckList.map((item) => {
          dataReqChecklist.push({
            id_servico: servico.id,
            nome: item
          });
        });

      if (newComissaoList && newComissaoList.length > 0)
        newComissaoList.map((item) => {
          dataReqComissao.push({
            id_servico: servico.id,
            comissao: item.comissao,
            id_user: item.idUsuario
          });
        });

      await api.post("servicos/checklist", { data: dataReqChecklist });
      await api.post("servicos/comissao", { data: dataReqComissao });

      navigate("/servicos");
      setAlert("Serviço salvo com sucesso", "success");

    } catch (err) {
      console.log(err);

      const teste: any = err;

      if (teste.response.data.message)
        setAlert(teste.response.data.message, "danger");
      else
        setAlert("Erro ao salvar serviço", "danger");

      setLoading(false);
      setTxtLoading("");
    }

  };

  useEffect(() => {
    loadCadastro();
    loadUsuarios();
  }, [token]);

  return (
    <Topo page="Servicos" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Serviço`}>

      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="form" id="divServicoForm">

          <div className="contentInput" style={{ width: "75%" }}>
            <span>Nome:</span>
            <Input
              mask="none"
              value={nome}
              inputMaskChange={setNome}
              placeholder="Nome"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Preço:</span>
            <Input
              mask="currency"
              value={valor}
              inputMaskChange={setValor}
              placeholder="R$0,00"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Recorrente:</span>
            <select value={recorrente} onChange={(e) => setRecorrente(e.target.value)}>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div className="contentInput" style={{ width: "25%", display: recorrente === "1" ? "block" : "none" }}>
            <span>Dias:</span>
            <Input
              mask="none"
              value={dias}
              inputMaskChange={setDias}
              placeholder="Dias"
              type="number"
            />
          </div>

          <div className="contentInput" style={{ width: "25%" }}>
            <span>Favorito:</span>
            <select value={favorito ? "1" : "0"} onChange={(e) => setFavorito(e.target.value === "1" ? true : false)}>
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div className="tableGrid">
            <div className="tableContent">

              <h3>Checklist</h3>

              {excluindoCheckList && (
                <Load txtLoading="Excluindo..." options={{ height: 120, width: 120 }} />
              )}

              {!excluindoCheckList && (
                <>
                  <div className="tableContentForm">
                    <div className="contentInput" style={{ minWidth: "200px", flex: "1" }}>
                      <span>Nome:</span>
                      <Input
                        mask="none"
                        value={descricaoCheckList}
                        inputMaskChange={setDescricaoCheckList}
                        placeholder="Nome"
                      />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "35px" }}>
                      <button className="btn-green" style={{ width: "125px" }} onClick={() => handleAddCheckList()}>
                        <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                      </button>
                    </div>
                  </div>

                  <div className="headTable">
                    <span style={{ width: "100%" }}>Nome</span>
                    <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                  </div>

                  <div className="contentTable">
                    {newCheckList.map((item, i) => (
                      <div className="rowTable" key={`ncl-${i}`}>
                        <span style={{ width: "100%" }}>{item}</span>
                        <span style={{ width: "100px", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelTempCheckList(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                      </div>
                    ))}

                    {listCheckList.map((item) => (
                      <div className="rowTable" key={item.id}>
                        <span style={{ width: "100%" }}>{item.nome}</span>
                        <span style={{ width: "100px", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelCheckList(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}

            </div>

            <div className="tableContent">

              <h3>Comissão</h3>

              {excluindoComissao && (
                <Load txtLoading="Excluindo..." options={{ height: 120, width: 120 }} />
              )}

              {!excluindoComissao && (
                <>
                  <div className="tableContentForm">

                    <div className="contentInput" style={{ minWidth: "200px", flex: "1" }}>
                      <span>Usuário:</span>
                      <select value={usuarioSelected} onChange={(e) => setUsuarioSelected(e.target.value)}>
                        <option value="0">Selecione...</option>
                        {listUsuarios.map((item) => (
                          <option key={item.id} value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                    </div>

                    <div className="contentInput" style={{ minWidth: "200px", flex: "1" }}>
                      <span>Comissão (%):</span>
                      <Input
                        mask="none"
                        value={comissaoUsuario}
                        inputMaskChange={setComissaoUsuario}
                        placeholder="0%"
                        type="number"
                      />
                    </div>

                    <div className="contentInput" style={{ width: "100%" }}>
                      <button className="btn-green" style={{ width: "125px", margin: "0 auto" }} onClick={() => handleAddComissao()}>
                        <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                      </button>
                    </div>

                  </div>
                  <div className="headTable">
                    <span style={{ width: "100%" }}>Usuário</span>
                    <span style={{ width: "100px" }}>Comissão</span>
                    <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                  </div>

                  <div className="contentTable">
                    {newComissaoList.map((item, i) => (
                      <div className="rowTable" key={`nc-${i}`}>
                        <span style={{ width: "100%" }}>{item.nomeUsuario}</span>
                        <span style={{ width: "100px" }}>{item.comissao}%</span>
                        <span style={{ width: "100px", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelTempComissao(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                      </div>
                    ))}

                    {listComissao.map((item) => (
                      <div className="rowTable" key={item.id}>
                        <span style={{ width: "100%" }}>{item.usuario.nome}</span>
                        <span style={{ width: "100px" }}>{item.comissao}%</span>
                        <span style={{ width: "100px", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelComissao(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                      </div>
                    ))}
                  </div>

                </>
              )}

            </div>
          </div>

          <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
            <button className="btnSalvar btn-green" onClick={handleSalvar}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>
        </div>
      )
      }

    </Topo >
  )
}