import { faAngleDoubleLeft, faAngleDoubleRight, faPen, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IListRequest, IServico } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";

const limitPage = 25;

export function Servicos() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [pesquisa, setPesquisa] = useState("");

  const [listServicos, setListServicos] = useState<IServico[]>([]);

  const loadServicos = async (canPesquisa = false) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de serviços");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa)
      dataRequest = { ...dataRequest, pesquisa };

    await api.post("servicos/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        setListServicos(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handlePesquisa = () => {
    setPage(1);
    loadServicos(true);
  }

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handleDelete = async (id: string) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir este serviço?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo serviço");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete('servicos', { data: { id } })
      .then((result) => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadServicos(true);
      });
  };

  useEffect(() => {
    loadServicos();
  }, [token]);

  useEffect(() => {
    loadServicos(true);
  }, [page]);

  return (
    <Topo page="Servicos" title="Serviços">
      <>
        <div className="divPesquisa">
          <Input
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa..."
            onKeyUp={handleKeyPesquisa}
          />
          <button onClick={handlePesquisa}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button className="btn-green" onClick={() => { navigate("/servicos/novo") }}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <div className="gridContext">
          <div className="headTable">
            <span style={{ width: "200px" }}>Nome</span>
            <span style={{ width: "150px", textAlign: "right" }}>Valor</span>
            <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
          </div>

          <div className="contentTable">
            {loading && (
              <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
            )}

            {!loading && listServicos.length > 0 && (
              listServicos.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span style={{ width: "200px" }}>{item.nome}</span>
                  <span style={{ width: "150px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                  <span style={{ width: "200px", textAlign: "center" }}>
                    <button className="btn-orange" onClick={() => { navigate(`/servicos/${item.id}`) }}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button className="btn-red" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </span>
                </div>
              ))
            )}

          </div>

          <div className="pagination">
            {page !== 1 && (
              <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
            )}
            <p>
              {page}/{maxPage}
            </p>
            {page !== maxPage && (
              <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
            )}
          </div>

        </div>

      </>
    </Topo>
  )
}