import { ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useCache } from "../../hooks/useCache"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faBars, faTimes, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

type IProps = {
  page:
  "Home"
  | "Clientes"
  | "Produtos"
  | "Variantes"
  | "Pedidos"
  | "Empresas"
  | "Nfe"
  | "Servicos"
  | "Status"
  | "FormaPgto"
  | "OrdemServico"
  | "Agendamento"
  | "Caixa"
  | "Relatorio";
  title: string;
  children: ReactNode;
}

export function Topo(props: IProps) {
  const navigate = useNavigate();
  const { token, refreshToken, usuario, logout, colorAlerta, txtAlerta, setAlert } = useCache();

  const [openLogout, setOpenLogout] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openRelatorios, setOpenRelatorios] = useState(false);

  const handleOpenLogout = () => {
    setOpenLogout(i => !i);
  }

  const handleSair = () => {
    if (!window.confirm("Deseja realmente sair da sua conta?"))
      return;

    logout();
    navigate("/login");
  }

  const handleMenu = () => {
    setOpenMenu(i => !i);
  }

  const fechaAlerta = () => {
    const div = document.getElementById("alerta");

    if (!div)
      return;

    div.style.right = "-465px";
    setTimeout(() => {
      setAlert("", "");
    }, 500)
  }

  const abreAlerta = () => {
    const div = document.getElementById("alerta");

    if (!div)
      return;

    div.style.right = "15px";

    setTimeout(() => {
      fechaAlerta();
    }, 5000);
  }

  const handleClickMenu = (page: string) => {
    switch (page) {
      case "Home": navigate("/"); break;
      case "Empresas": navigate("/empresas"); break;
      case "Clientes": navigate("/clientes"); break;
      case "Produtos": navigate("/produtos"); break;
      case "Variantes": navigate("/variantes"); break;
      case "Pedidos": navigate("/pedidos"); break;
      case "Nfe": navigate("/nfe"); break;
      case "Servicos": navigate("/servicos"); break;
      case "Status": navigate("/status"); break;
      case "FormaPgto": navigate("/formaPgto"); break;
      case "OrdemServico": navigate("/ordemServico"); break;
      case "Agendamento": navigate("/agendamento"); break;
      case "Caixa": navigate("/caixa"); break;
    }
  }

  const handleRelatorio = () => {
    setOpenRelatorios(i => !i);
  }

  useEffect(() => {
    if (token === undefined)
      return;

    if (!refreshToken && !token)
      navigate(`/login`);

  }, [token, refreshToken])

  useEffect(() => {
    if (!txtAlerta)
      return;

    if (txtAlerta.length > 0)
      setTimeout(() => {
        abreAlerta();
      }, 250);
  }, [txtAlerta, colorAlerta]);

  useEffect(() => {
    const content = document.getElementById("menuLogout");
    const icon = document.getElementById(`icon-user`);

    if (content && icon) {
      if (openLogout) {
        content.style.opacity = `1`;
        content.style.height = `70px`;
        icon.style.transform = "rotate(90deg)";
      } else {
        content.style.opacity = `0`;
        content.style.height = `0px`;
        icon.style.transform = "rotate(0deg)";
      }
    }
  }, [openLogout]);

  useEffect(() => {
    const content = document.getElementById("divMenu");

    if (window.innerWidth > 600)
      return;

    if (content) {
      if (openMenu) {
        content.style.width = "90%";
        content.style.opacity = "1";
      } else {
        if (content.style.width != "20%") {
          content.style.width = "0px";
          content.style.opacity = "0";
        }
      }
    }

  }, [openMenu]);

  useEffect(() => {
    const content = document.getElementById("subRelatorios");
    const icon = document.getElementById("icon-relatorios");

    if (content && icon) {
      if (openRelatorios) {
        content.style.opacity = `1`;
        content.style.height = `60px`;
        icon.style.transform = "rotate(90deg)";
      } else {
        content.style.opacity = `0`;
        content.style.height = `0px`;
        icon.style.transform = "rotate(0deg)";
      }
    }

  }, [openRelatorios]);

  return (
    <>
      <div className={`alerta ${colorAlerta && colorAlerta.length > 0 ? `alerta-${colorAlerta}` : ""}`} id="alerta" onClick={() => fechaAlerta()}>
        {txtAlerta.length > 0 && (
          <>
            <span>
              <FontAwesomeIcon icon={faExclamationCircle} style={{ width: "35px", height: "35px" }} />
            </span>
            <span style={{ flex: "1" }}>
              {txtAlerta}
            </span>
            <span>
              <FontAwesomeIcon icon={faTimes} style={{ width: "25px", height: "25px" }} />
            </span>
          </>
        )}
      </div>

      <div className="bgFaixa" />

      <div id="divMenu">
        <nav>
          <div className="contentBars">
            <FontAwesomeIcon icon={faTimes} onClick={handleMenu} />
          </div>

          <h3 onClick={handleOpenLogout}>
            {usuario.nome ? usuario.nome : ""}
            <FontAwesomeIcon id="icon-user" icon={faCaretRight} className="iconUser" />
          </h3>

          <div id="menuLogout">
            {openLogout && (
              <>
                <span>Alterar senha</span>
                <button onClick={handleSair}>SAIR</button>
              </>
            )}
          </div>

          <div className="content">
            <span onClick={() => handleClickMenu("Home")} className={props.page === "Home" ? "selected" : ""}>Início</span>
            <span onClick={() => handleClickMenu("Agendamento")} className={props.page === "Agendamento" ? "selected" : ""}>Agendamento</span>
            <span onClick={() => handleClickMenu("Caixa")} className={props.page === "Caixa" ? "selected" : ""}>Caixa</span>
            <span onClick={() => handleClickMenu("Clientes")} className={props.page === "Clientes" ? "selected" : ""}>Clientes</span>
            <span onClick={() => handleClickMenu("Empresas")} className={props.page === "Empresas" ? "selected" : ""}>Empresas</span>
            <span onClick={() => handleClickMenu("FormaPgto")} className={props.page === "FormaPgto" ? "selected" : ""}>Formas de Pagamentos</span>
            <span onClick={() => handleClickMenu("Nfe")} className={props.page === "Nfe" ? "selected" : ""}>NFe</span>
            <span onClick={() => handleClickMenu("OrdemServico")} className={props.page === "OrdemServico" ? "selected" : ""}>Ordem de Serviço</span>
            <span onClick={() => handleClickMenu("Pedidos")} className={props.page === "Pedidos" ? "selected" : ""}>Pedidos de NFe</span>
            <span onClick={() => handleClickMenu("Produtos")} className={props.page === "Produtos" ? "selected" : ""}>Produtos</span>
            <span onClick={() => handleClickMenu("Servicos")} className={props.page === "Servicos" ? "selected" : ""}>Serviços</span>
            <span onClick={() => handleClickMenu("Status")} className={props.page === "Status" ? "selected" : ""}>Status</span>
            <span onClick={() => handleClickMenu("Variantes")} className={props.page === "Variantes" ? "selected" : ""}>Variantes (produtos)</span>

            <span onClick={() => handleRelatorio()} className={props.page === "Relatorio" ? "selected" : ""}>
              Relatórios
              <FontAwesomeIcon id="icon-relatorios" icon={faCaretRight} className="iconUser" />
            </span>
            <div id="subRelatorios">
              <span onClick={() => navigate("/ordemServico/relatorio")}>Ordem de Serviço</span>
              <span onClick={() => navigate("/nfe/relatorio")}>NFe</span>
            </div>

          </div>

        </nav>
      </div>

      <main>

        <div className="contentBars">
          <FontAwesomeIcon icon={faBars} onClick={handleMenu} />
        </div>

        <h1>{props.title}</h1>

        <div className="context">
          <div className="content">
            {props.children}
          </div>
        </div>

      </main>

    </>
  );
}