import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

import "./style.css";

interface IProps {
  closeModal: () => void;
}

export function NfeModalImportar({ closeModal }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const selectFiles = (e: any) => {
    if (e.target.files && e.target.files.length)
      setSelectedFiles(e.target.files);
  }

  const handleEnviaXml = async () => {
    if (!token)
      return;

    if (selectedFiles.length <= 0)
      return alert("Selecione ao menos um xml para importar.");

    setLoading(true);
    setTxtLoading("Enviando arquivos...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let finalizado = false;
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++)
      formData.append("xml", selectedFiles[i], selectedFiles[i].name);

    await api.post("nfe/importar", formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((res) => {
        console.log(res.data);

        finalizado = true;
        alert("Upload de xml realizado com sucesso. Em instantes a(s) nota(s) estará disponível.");
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");

        if (finalizado)
          closeModal();
      })
  }

  return (
    <div id="divNfeModalImportar">
      <div className="title">
        <p>Importar XMLs</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <div className="divFormModal">
            <span>Selecione o(s) arquivo(s):</span>
            <input type="file" multiple onChange={(e) => selectFiles(e)} accept="text/xml" />

            <button value="Enviar" onClick={() => handleEnviaXml()} className="btn-green">
              <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "5px" }} />
              Enviar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}