import { IResponseCheckError } from "../global/types";
import { api } from "../services/api";
import { checkErro } from "./checkErro";


export async function checkToken(cache: any, pToken: string): Promise<IResponseCheckError> {

  let result: IResponseCheckError = {
    redirectLogin: false,
    refazerRequest: false,
    token: null,
    tokenRefresh: null
  };

  api.defaults.headers.common['Authorization'] = `Bearer ${pToken}`;
  await api.post("verify-token")
    .then(() => { })
    .catch(async (err) => {
      result = await checkErro(err, cache);
    });

  return result;

}