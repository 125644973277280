import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo"
import { IAgendamento, IEmpresa } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import Modal from "react-modal";
import { compareInDays, convertDateSqlToDate, convertToUs, convertToUTC, dateNow } from "../../utils/dateProvider";
import "./style.css"
import { EmpresaModalCertificado } from "../../modal/EmpresaModalCertificado";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: 300,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Home() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [empresaSelected, setEmpresaSelected] = useState<IEmpresa>({} as IEmpresa);

  const [listCertVenc, setListCertVenc] = useState<IEmpresa[]>([]);
  const [listAgendamento, setListAgendamento] = useState<IAgendamento[]>([]);

  const [modalCert, setModalCert] = useState(false);

  const loadEmpresas = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de Empresas");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        const list = result.data.result as IEmpresa[];

        const tempListVencCert: IEmpresa[] = [];

        list.map((item) => {
          const days = compareInDays(dateNow(), convertDateSqlToDate(`${item.venc_cert}`));

          if (days <= 30)
            tempListVencCert.push(item);
        });

        setListCertVenc(tempListVencCert);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        loadAgendamentos();
      });
  }

  const loadAgendamentos = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de Agendamentos");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest = {
      cursor: 0,
      limit: 150,
      orderBy: { data_agendamento: "ASC" },
      pesquisa: {
        concluido: false,
        date_fin: `${convertToUs(`${dateNow()}`)} 23:59:59`,
      }
    }

    await api.post("agendamento/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        setListAgendamento(response.data.result);

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleCertificado = (e: IEmpresa) => {
    setEmpresaSelected(e);
    setModalCert(true);
  }

  const handleCloseModalCertificado = () => {
    loadEmpresas();
    setModalCert(false);
  }

  const handleConcluirAgendamento = async (item: IAgendamento) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente concluir este Agendamento?"))
      return;

    setLoading(true);
    setTxtLoading("Concluindo Agendamento...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("agendamento", { ...item, concluido: true })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadAgendamentos();
      });
  }

  useEffect(() => {
    if (token)
      loadEmpresas();
  }, [token])

  return (
    <>
      <Topo page="Home" title="Dashboard">

        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            <div id="pageHome">
              {listCertVenc.length > 0 && listCertVenc.map((item) => (
                <div className="alert" key={`Venc-${item.id}`}>
                  <p>ATENÇÃO: Certificado da empresa {item.razao} próximo do vencimento</p>
                  <span>Vencimento: {convertToUTC(item.venc_cert).substring(0, 10)}</span>
                  <button className="btn-green" onClick={() => handleCertificado(item)}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "5px" }} />
                    Enviar certificado
                  </button>
                </div>
              ))}

              <h3 style={{ marginTop: "15px" }}>Agendamentos</h3>

              <div className="tableAgendamentos">

                {listAgendamento.length > 0 && listAgendamento.map((item) => (
                  <div className="contentAgendamento" key={item.id}>
                    <div>
                      <button className="btn-green" onClick={() => handleConcluirAgendamento(item)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </div>
                    <div className="lineAgendamento" key={item.id}>
                      <span>
                        <b>Cliente:</b> {item.cliente.fantasia}
                      </span>
                      <span>
                        <b>Agendado para:</b> {convertToUTC(item.data_agendamento)}
                      </span>
                      <span>
                        {item.descricao}
                      </span>

                      <hr />

                    </div>
                  </div>
                ))}

              </div>
            </div>

          </>
        )}


        <Modal isOpen={modalCert} style={styleModal}>
          <EmpresaModalCertificado closeModal={() => handleCloseModalCertificado()} empresa={empresaSelected} />
        </Modal>

      </Topo>
    </>
  )
}