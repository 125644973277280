import { createContext, ReactNode, useState, useEffect } from "react";
import { IUsuarioLogado, IStorage, ICidades, IClientes } from "../global/types";
import { api } from "../services/api";

const codCliente = "default";

type CacheContextType = {
  token: string | undefined;
  refreshToken: string | undefined;
  usuario: IUsuarioLogado;
  cacheCidades: ICidades[];
  txtAlerta: string;
  colorAlerta: "success" | "danger" | "";
  setAlert: (txt: string, color: "success" | "danger" | "") => void;
  cacheTokens: (token: string, refreshToken: string, usuario: IUsuarioLogado) => void;
  saveCacheCidades: (list: ICidades[]) => void;
  logout: () => void;
};

type CacheContextProps = {
  children: ReactNode;
}

export const CacheContext = createContext({} as CacheContextType);

export function CacheContextProvider(props: CacheContextProps) {

  const [token, setToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [usuario, setUsuario] = useState<IUsuarioLogado>({} as IUsuarioLogado);
  const [cacheCidades, setCacheCidades] = useState<ICidades[]>([]);

  const [txtAlerta, setTxtAlerta] = useState("");
  const [colorAlerta, setColorAlerta] = useState<"success" | "danger" | "">("");

  const setAlert = (txt: string, color: "success" | "danger" | "") => {
    setTxtAlerta(txt);
    setColorAlerta(color);
  }

  const cacheTokens = (nToken: string, nRefreshToken: string, nUsuario: IUsuarioLogado) => {
    setToken(nToken);
    setRefreshToken(nRefreshToken);
    setUsuario(nUsuario);

    const slStringify = JSON.stringify({
      token: nToken,
      refreshToken: nRefreshToken,
      user: nUsuario
    });

    api.defaults.headers.common['Authorization'] = `Bearer ${nToken}`;
    localStorage.setItem(`dciFiscal_${codCliente}`, slStringify);
  };

  const logout = () => {
    localStorage.removeItem(`dciFiscal_${codCliente}`);
    setToken("");
    setRefreshToken("");
    setUsuario({} as IUsuarioLogado);
  };

  const saveCacheCidades = (list: ICidades[] = []) => {
    setCacheCidades(list);
  }

  useEffect(() => {
    const storage = localStorage.getItem(`dciFiscal_${codCliente}`);

    if (storage) {
      const slParse = JSON.parse(storage) as IStorage;
      setToken(slParse.token);
      setRefreshToken(slParse.refreshToken);
      setUsuario(slParse.user);
    }
    else {
      setToken("");
      setRefreshToken("");
      setUsuario({} as IUsuarioLogado);
    }

  }, [codCliente]);

  return (
    <CacheContext.Provider value={{
      token,
      refreshToken,
      usuario,
      cacheCidades,
      cacheTokens,
      saveCacheCidades,
      logout,
      txtAlerta,
      colorAlerta,
      setAlert
    }}>
      {props.children}
    </CacheContext.Provider>
  )
}