import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus, faPercent, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { IPedido, ICreatePedidosPgtosDTO, ICreatePedidosDTO } from "../../global/types";
import { checkToken } from "../../utils/checkToken";
import { Load } from "../../components/Load";
import { Input } from "../../components/Input";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";
import { PedidosModalDesconto } from "../PedidosModalDesconto";
import { getNomeFormaPgto } from "../../utils/formaPgto";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IProps = {
  closeModal: () => void;
  refreshPedidos: () => void;
  idPedido: string;
}

export function PedidosModalPgto({ idPedido, closeModal, refreshPedidos }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [formaPgtoSelected, setFormaPgtoSelectes] = useState("");
  const [valorSelected, setValorSelected] = useState("");
  const [inputDesconto, setInputDesconto] = useState("");

  const [requestPgtos, setRequestPgtos] = useState<ICreatePedidosPgtosDTO[]>([]);

  const [totalPago, setTotalPago] = useState(0);
  const [desconto, setDesconto] = useState(0);

  const [pedido, setPedido] = useState<IPedido>({} as IPedido);

  const [modalDesconto, setModalDesconto] = useState(false);

  const loadPedido = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando pedido...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`pedidos/${idPedido}`)
      .then((result) => {
        if (!result.data)
          return;

        const rsPedido = result.data as IPedido;

        setDesconto(rsPedido.desconto);
        setPedido(rsPedido);
        setTotalPago(parseFloat(`${rsPedido.valor_pago}`));
        setInputDesconto(
          currencyFormatter(
            parseFloat(`${rsPedido.desconto}`),
            {
              significantDigits: 2,
              thousandsSeparator: ".",
              decimalSeparator: ",",
              symbol: "",
            }
          )
        );

      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const handleAddFormaPgto = () => {
    if (formaPgtoSelected.length <= 0)
      return;

    const alreadyExists: ICreatePedidosPgtosDTO[] = [];

    requestPgtos.map((item) => {
      if (item.forma_pgto === parseInt(formaPgtoSelected))
        alreadyExists.push(item);
    });

    if (alreadyExists.length > 0) {
      const newList: ICreatePedidosPgtosDTO[] = [];

      requestPgtos.map((item) => {
        if (item.forma_pgto === parseInt(formaPgtoSelected))
          newList.push({ ...item, valor: item.valor + parseFloat(valorSelected) });
        else
          newList.push(item);
      });

      let newTotalPago = 0;

      newList.map((item) => {
        newTotalPago += item.valor;
      });

      if (newTotalPago > (pedido.total - desconto))
        return alert("Valor informado maior do que o valor restante");

      setRequestPgtos(newList);

    } else {
      let nomeFormPgto = "";

      switch (formaPgtoSelected) {
        case "01": nomeFormPgto = "Dinheiro"; break;
        case "02": nomeFormPgto = "Cheque"; break;
        case "03": nomeFormPgto = "Cartão de Crédito"; break;
        case "04": nomeFormPgto = "Cartão de Débito"; break;
        case "05": nomeFormPgto = "Crédito Loja"; break;
        case "10": nomeFormPgto = "Vale Alimentação"; break;
        case "11": nomeFormPgto = "Vale Refeição"; break;
        case "12": nomeFormPgto = "Vale Presente"; break;
        case "13": nomeFormPgto = "Vale Combustível"; break;
        case "14": nomeFormPgto = "Duplicata Mercantil"; break;
        case "15": nomeFormPgto = "Boleto Bancário"; break;
        case "90": nomeFormPgto = "Sem Pagamento"; break;
        case "99": nomeFormPgto = "Outros"; break;
      }

      const valor = parseFloat(valorSelected.replace(".", "").replace(",", "."));
      console.log(valor);


      const temp: ICreatePedidosPgtosDTO = {
        forma_pgto: parseInt(formaPgtoSelected),
        valor,
        nome_forma_pgto: nomeFormPgto,
        id_pedidos: idPedido
      };

      console.log((totalPago + valor), (pedido.total - desconto));

      if ((totalPago + valor) > (pedido.total - desconto))
        return alert("Valor informado maior do que o valor restante");

      setRequestPgtos(e => ([temp, ...e]));
    }

    setValorSelected("");
    setFormaPgtoSelectes("");
  }

  const handleKeyValorPgto = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddFormaPgto();
    }
  }

  const handleFocusOutDesconto = (valor: string | null = null) => {
    const tempValor = valor === null ? inputDesconto : valor;

    let newDesconto = parseFloat(tempValor.replace(".", "").replace(",", "."));

    if (isNaN(newDesconto))
      newDesconto = 0;


    if (newDesconto > parseFloat((pedido.total - totalPago).toFixed(2))) {
      setInputDesconto("");
      setDesconto(0);
      return alert("Valor de desconto maior do que o valor restante");
    }

    setDesconto(newDesconto);
  }

  const handleDescontoPorcentagem = (value: string) => {
    setInputDesconto(value);
    handleFocusOutDesconto(value);
  }

  const handleDelPgto = (pgto: ICreatePedidosPgtosDTO) => {
    if (!window.confirm("Deseja realmente excluir essa forma de pagamento?"))
      return;

    const newList: ICreatePedidosPgtosDTO[] = [];

    requestPgtos.map((item) => {
      if (item.forma_pgto !== pgto.forma_pgto)
        newList.push(item);
    });

    setRequestPgtos(newList);
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Atualizando dados do Pedido...");

    const request: ICreatePedidosDTO = {
      id: idPedido,
      id_empresa: pedido.id_empresa,
      id_cliente: pedido.id_cliente,
      id_cidades: pedido.id_cidades,
      total: pedido.total,
      desconto,
      valor_pago: totalPago,
      endereco: pedido.endereco,
      numero: pedido.numero,
      complemento: pedido.complemento,
      bairro: pedido.bairro,
      cep: pedido.cep,
      local_venda: pedido.local_venda
    };

    await api.post("pedidos", { ...request })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pedido: " + err.response.data.message);
      })
      .finally(() => {
        handleSalvarPgtos();
      });
  }

  const handleSalvarPgtos = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setTxtLoading("Salvando lista de pagamentos no Pedido...");

    await api.post("pedidos/pgtos", { data: requestPgtos })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pgtos: " + err.response.data.message);
      })
      .finally(() => {
        refreshPedidos();
        closeModal();
      });
  }

  useEffect(() => {
    if (!idPedido || !token)
      return;

    loadPedido();

  }, [idPedido, token]);

  useEffect(() => {
    let t = 0;

    requestPgtos.map((item) => {
      t += item.valor;
    });

    t += parseFloat(`${pedido.valor_pago}`);

    if (isNaN(t))
      return;

    setTotalPago(t);
  }, [requestPgtos]);

  return (
    <div className="divModalPedPgto">
      <div className="title">
        <p>Pagamento de Pedido</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <span>Forma de Pagamento</span>
              <select value={formaPgtoSelected} onChange={(e) => setFormaPgtoSelectes(e.target.value)}>
                <option value="">Selecione...</option>
                <option value="01">Dinheiro</option>
                <option value="02">Cheque</option>
                <option value="03">Cartão de Crédito</option>
                <option value="04">Cartão de Débito</option>
                <option value="05">Crédito Loja</option>
                <option value="10">Vale Alimentação</option>
                <option value="11">Vale Refeição</option>
                <option value="12">Vale Presente</option>
                <option value="13">Vale Combustível</option>
                <option value="14">Duplicata Mercantil</option>
                <option value="15">Boleto Bancário</option>
                <option value="90">Sem Pagamento</option>
                <option value="99">Outros</option>
              </select>

              <div style={{ display: "flex", flexDirection: "row", marginTop: "15px", alignItems: "center", gap: "10px" }}>
                <div style={{ width: "100%" }}>
                  <span>Valor</span>
                  <Input
                    mask="currency"
                    value={valorSelected}
                    inputMaskChange={setValorSelected}
                    placeholder="Informe o valor"
                    onKeyUp={handleKeyValorPgto}
                  />
                </div>

                <button value="Enviar" className="btn-orange" style={{ marginTop: "19px", width: "180px" }} onClick={handleAddFormaPgto}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
                  Adicionar
                </button>
              </div>

              <div className="gridPgto">
                <div className="headTable">
                  <span style={{ width: "200px" }}>Forma de Pagamento</span>
                  <span style={{ width: "90px", textAlign: "right" }}>Valor</span>
                  <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                </div>

                <div className="contentTable">

                  {requestPgtos.map((item) => (
                    <div className="rowTable" key={`${item.forma_pgto}`}>
                      <span style={{ width: "200px" }}>{item.nome_forma_pgto}</span>
                      <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                      <span style={{ width: "100px", textAlign: "center" }}>
                        <button className="btn-red" onClick={() => handleDelPgto(item)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </span>
                    </div>
                  ))}

                  {pedido.pgtos?.map((item) => (
                    <div className="rowTable" key={`${item.forma_pgto}`}>
                      <span style={{ width: "200px" }}>{getNomeFormaPgto(item.forma_pgto)}</span>
                      <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                      <span style={{ width: "100px", textAlign: "center" }} />
                    </div>
                  ))}

                  <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                    <div className="divDesconto">
                      <span>DESCONTO:</span>
                      <Input
                        mask="currency"
                        value={inputDesconto}
                        inputMaskChange={setInputDesconto}
                        placeholder="R$ 0,00"
                        style={{ textAlign: "right" }}
                        onBlur={() => handleFocusOutDesconto()}
                      />
                      <button className="btn-orange" onClick={() => setModalDesconto(true)}>
                        <FontAwesomeIcon icon={faPercent} />
                      </button>
                    </div>
                    <span style={{ fontSize: "16px" }}>TOTAL PAGO: {currencyFormatter(parseFloat(`${totalPago}`))}</span>
                    <span style={{ fontSize: "16px" }}>VALOR RESTANTE: {currencyFormatter(parseFloat(`${Math.abs(pedido.total - totalPago - desconto)}`))}</span>
                  </div>
                </div>
              </div>


              <button value="Enviar" className="btn-green" onClick={handleSalvar}>
                <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
                Salvar
              </button>

            </div>
          </>
        )}

      </div>

      <Modal isOpen={modalDesconto} style={{
        content: { ...styleModal.content, width: 335, height: 250 },
        overlay: { ...styleModal.overlay }
      }}>
        <PedidosModalDesconto closeModal={() => setModalDesconto(false)} totalProdutos={pedido.total} handleDescontoPorcentagem={handleDescontoPorcentagem} />
      </Modal>

    </div>
  );
}