import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICaixa, ICaixaCompleto, ICreateCaixaDTO } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertToUTC } from "../../utils/dateProvider";

import "./style.css";

type IParams = {
  id: string;
}

export function CaixaFechamento() {
  const navigate = useNavigate();
  const { id } = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [dinheiro, setDinheiro] = useState(0);
  const [cartaoCredito, setCartaoCredito] = useState(0);
  const [cartaoDebito, setCartaoDebito] = useState(0);
  const [outros, setOutros] = useState(0);
  const [sangria, setSangria] = useState(0);
  const [reforco, setReforco] = useState(0);

  const [dinheiroDigitado, setDinheiroDigitado] = useState("");
  const [cartaoCreditoDigitado, setCartaoCreditoDigitado] = useState("");
  const [cartaoDebitoDigitado, setCartaoDebitoDigitado] = useState("");

  const [difDinheiro, setDifDinheiro] = useState(0);
  const [difCartaoCredito, setDifCartaoCredito] = useState(0);
  const [difCartaoDebito, setDifCartaoDebito] = useState(0);

  const [caixa, setCaixa] = useState<ICaixaCompleto>({} as ICaixaCompleto);

  const loadCaixa = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Verificando login...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    try {
      setTxtLoading("Recuperando movimentação do Caixa...");

      const caixaRequest = await api.get(`caixa/${id}`);

      if (!caixaRequest.data)
        throw new Error("Erro ao recuperar informações do caixa");

      const tempCaixa = caixaRequest.data as ICaixaCompleto;
      setCaixa(tempCaixa);

      setDinheiroDigitado(
        currencyFormatter(parseFloat(`${tempCaixa.dinheiro}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        })
      );

      setCartaoCreditoDigitado(
        currencyFormatter(parseFloat(`${tempCaixa.cartao_credito}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        })
      );

      setCartaoDebitoDigitado(
        currencyFormatter(parseFloat(`${tempCaixa.cartao_debito}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        })
      );


      setLoading(false);
      setTxtLoading("");

    } catch (err) {
      console.log(err);
      const erro: any = err;

      if (erro.response.data.message)
        setAlert(erro.response.data.message, "danger");
      else
        setAlert("Erro ao buscar informações do caixa", "danger");

      setLoading(false);
      setTxtLoading("");
    }

  }

  const Totais = () => {

    if (!caixa.id)
      return;

    let tempDinheiro = 0,
      tempCartaoCredito = 0,
      tempCartaoDebito = 0,
      tempOutros = 0,
      tempSangria = 0,
      tempReforco = 0;

    caixa.financeiro.map((item) => {
      if (item.ordemServicoPgto === null) {
        tempReforco += parseFloat(`${item.credito}`);
        tempSangria += parseFloat(`${item.debito}`);
      } else {
        switch (item.ordemServicoPgto.formaPgto.tipo_caixa) {
          case 1: tempDinheiro += parseFloat(`${item.credito}`); break;
          case 2: tempCartaoCredito += parseFloat(`${item.credito}`); break;
          case 3: tempCartaoDebito += parseFloat(`${item.credito}`); break;
          case 4: tempOutros += parseFloat(`${item.credito}`); break;
        }
      }
    });

    setDinheiro(tempDinheiro);
    setCartaoCredito(tempCartaoCredito);
    setCartaoDebito(tempCartaoDebito);
    setOutros(tempOutros);
    setSangria(tempSangria);
    setReforco(tempReforco);
  }

  const handlePrinterFechamento = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Verificando login...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Gerando PDF...");

    await api.post("relatorios/fechamentoCaixa", { idCaixa: id },
      {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      })
      .then((result) => {
        if (!result.data)
          return;

        const file = new Blob([result.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        if (pdfWindow !== null)
          pdfWindow.location.href = fileURL;
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(`PrinterFechamento: ${err.response.data.message}`, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const handleFecharCaixa = async () => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente fechar o caixa?"))
      return;

    setLoading(true);
    setTxtLoading("Verificando login...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setTxtLoading("Fechando Caixa...");

    const request: ICreateCaixaDTO = {
      id: caixa.id,
      valor_inicial: caixa.valor_inicial,
      cartao_credito: parseFloat(cartaoCreditoDigitado.replace(".", "").replace(",", ".")),
      cartao_debito: parseFloat(cartaoDebitoDigitado.replace(".", "").replace(",", ".")),
      dinheiro: parseFloat(dinheiroDigitado.replace(".", "").replace(",", ".")),
      fechado: true
    };

    await api.post("caixa", { ...request })
      .then((result) => {
        if (!result.data)
          return;

        handlePrinterFechamento();
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        loadCaixa();
      });
  }

  useEffect(() => {
    loadCaixa();
  }, [token]);

  useEffect(() => {
    Totais();
  }, [caixa]);

  useEffect(() => {
    const temp = parseFloat(dinheiroDigitado.replace(".", "").replace(",", ".")) - (dinheiro + reforco + parseFloat(`${caixa.valor_inicial}`) - sangria);
    setDifDinheiro(temp);
  }, [dinheiroDigitado, dinheiro + reforco + caixa.valor_inicial - sangria]);

  useEffect(() => {
    const temp = parseFloat(cartaoCreditoDigitado.replace(".", "").replace(",", ".")) - cartaoCredito;
    setDifCartaoCredito(temp);
  }, [cartaoCreditoDigitado, cartaoCredito]);

  useEffect(() => {
    const temp = parseFloat(cartaoDebitoDigitado.replace(".", "").replace(",", ".")) - cartaoDebito;
    setDifCartaoDebito(temp);
  }, [cartaoDebitoDigitado, cartaoDebito]);

  return (
    <Topo page="Caixa" title="Fechamento de Caixa">
      <div id="caixaFechamentoPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <div className="form">

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Data Abertura:</span>
              {convertToUTC(caixa.created_at)}
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Valor Inicial:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${caixa.valor_inicial}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Valor Inicial"
                style={{ textAlign: "right", width: "25%", background: "#b2d4ff" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Dinheiro:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${dinheiro + reforco + parseFloat(`${caixa.valor_inicial}`) - sangria}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Dinheiro"
                style={{ textAlign: "right", width: "50%" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Dinheiro Conferido:</span>
              <Input
                mask="currency"
                value={dinheiroDigitado}
                inputMaskChange={setDinheiroDigitado}
                placeholder="Dinheiro Conferido"
                style={{ textAlign: "right", width: "50%" }}
                disabled={caixa.fechado}
              />
              <p style={{ color: difDinheiro < 0 ? "red" : difDinheiro === 0 ? "black" : "blue" }} >
                Diferença: {currencyFormatter(difDinheiro)}
              </p>
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Cartão de Crédito:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${cartaoCredito}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Cartão de Crédito"
                style={{ textAlign: "right", width: "50%" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>C. Crédito Conferido:</span>
              <Input
                mask="currency"
                value={cartaoCreditoDigitado}
                inputMaskChange={setCartaoCreditoDigitado}
                placeholder="Cartão de Crédito Conferido"
                style={{ textAlign: "right", width: "50%" }}
                disabled={caixa.fechado}
              />
              <p style={{ color: difCartaoCredito < 0 ? "red" : difCartaoCredito === 0 ? "black" : "blue" }} >
                Diferença: {currencyFormatter(difCartaoCredito)}
              </p>
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Cartão de Débito:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${cartaoDebito}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Cartão de Débito"
                style={{ textAlign: "right", width: "50%" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>C. Débito Conferido:</span>
              <Input
                mask="currency"
                value={cartaoDebitoDigitado}
                inputMaskChange={setCartaoDebitoDigitado}
                placeholder="Cartão de Débito Conferido"
                style={{ textAlign: "right", width: "50%" }}
                disabled={caixa.fechado}
              />
              <p style={{ color: difCartaoDebito < 0 ? "red" : difCartaoDebito === 0 ? "black" : "blue" }} >
                Diferença: {currencyFormatter(difCartaoDebito)}
              </p>
            </div>


            <div className="contentInput" style={{ width: "100%" }}>
              <span>Outros:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${outros}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Outros"
                style={{ textAlign: "right", width: "25%" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Sangria:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${sangria}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Sangria"
                style={{ textAlign: "right", width: "25%", background: "#efb8b8" }}
                disabled
              />
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Reforço:</span>
              <Input
                mask="currency"
                value={
                  currencyFormatter(parseFloat(`${reforco}`), {
                    significantDigits: 2,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                    symbol: "",
                  })
                }
                inputMaskChange={() => { }}
                placeholder="Reforço"
                style={{ textAlign: "right", width: "25%", background: "#a3dba3" }}
                disabled
              />
            </div>

            <div className="contentBtns" style={{ width: "100%", alignItems: "center", marginTop: "25px" }}>
              {!caixa.fechado && (
                <button className="btn-blue-dark" style={{ padding: "0 20px" }} onClick={() => handleFecharCaixa()}>
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: "5px" }} />
                  FECHAR CAIXA
                </button>
              )}
            </div>

            <h4 style={{ textAlign: "center", width: "100%", marginTop: "25px" }}>MOVIMENTAÇÃO</h4>

            <div className="gridContext" style={{ width: "100%" }}>
              <div className="headTable">
                <span style={{ width: "100px", textAlign: "center" }}>Data/Hora</span>
                <span style={{ width: "200px" }}>Tipo</span>
                <span style={{ width: "200px" }}>Descrição</span>
                <span style={{ width: "200px", textAlign: "right" }}>Valor</span>
              </div>

              <div className="contentTable">
                {caixa?.financeiro?.map((item) => (
                  <div className="rowTable" key={item.id} style={{ background: item.debito > 0 ? "#f5d4d4" : "" }}>
                    <span style={{ width: "100px", textAlign: "center" }}>
                      {convertToUTC(item.created_at)}
                    </span>
                    <span style={{ width: "200px" }}>
                      {item.ordemServicoPgto === null && (item.credito > 0 ? "Reforço" : "Sangria")}
                      {item.ordemServicoPgto !== null && ("Pgto")}
                    </span>
                    <span style={{ width: "200px" }}>
                      {item.descricao}
                      {item.ordemServicoPgto !== null && (` ( ${item.ordemServicoPgto.ordemServico.cliente.fantasia} )`)}
                    </span>
                    <span style={{ width: "200px", textAlign: "right" }}>
                      {item.credito > 0 && (currencyFormatter(parseFloat(`${item.credito}`)))}
                      {item.debito > 0 && (currencyFormatter(parseFloat(`${item.debito}`)))}
                    </span>
                  </div>
                ))}
              </div>

            </div>


          </div>
        )}

      </div>
    </Topo>
  )
}