import { faPen, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IFormaPgto } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";

export function FormaPgtos() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pesquisa, setPesquisa] = useState("");

  const [listFormaPgto, setListFormaPgto] = useState<IFormaPgto[]>([]);

  const loadFormaPgtos = async (canPesquisa: boolean = false) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de formas de pagamento");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest = {};

    if (canPesquisa)
      dataRequest = { pesquisa };

    await api.post("formaPgto/findAll", { dataRequest })
      .then((response) => {
        if (!response.data)
          return;

        setListFormaPgto(response.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const handlePesquisa = () => {
    loadFormaPgtos(true);
  }

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  useEffect(() => {
    loadFormaPgtos();
  }, [token]);

  return (
    <Topo page="FormaPgto" title="Formas de Pagamentos">
      <>
        <div className="divPesquisa">
          <Input
            mask="none"
            value={pesquisa}
            inputMaskChange={setPesquisa}
            placeholder="Pesquisa..."
            onKeyUp={handleKeyPesquisa}
          />
          <button onClick={handlePesquisa}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button className="btn-green" onClick={() => { navigate("/formaPgto/novo") }}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <div className="gridContext">
          <div className="headTable">
            <span style={{ width: "200px" }}>Nome</span>
            <span style={{ width: "200px" }}>Tipo Caixa</span>
            <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
          </div>

          <div className="contentTable">
            {loading && (
              <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
            )}

            {!loading && listFormaPgto.length > 0 && (
              listFormaPgto.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span style={{ width: "200px" }}>{item.nome}</span>
                  <span style={{ width: "200px" }}>
                    {item.tipo_caixa === 1 && ("Dinheiro")}
                    {item.tipo_caixa === 2 && ("Cartão de Crédito")}
                    {item.tipo_caixa === 3 && ("Cartão de Débito")}
                  </span>
                  <span style={{ width: "200px", textAlign: "center" }}>
                    <button className="btn-orange" onClick={() => { navigate(`/formaPgto/${item.id}`) }}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button className="btn-red" onClick={() => { }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </span>
                </div>
              ))
            )}

          </div>

        </div>
      </>
    </Topo>
  )
}