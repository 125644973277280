import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
  faUsers,
  faEye,
  faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import "./style.css";

import { useCache } from "../../hooks/useCache";
import { Topo } from "../../components/Topo";

import { api } from "../../services/api";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { checkToken } from "../../utils/checkToken";
import { IClientes, IEmpresa, IListRequest, IListPedido } from "../../global/types";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertToUs, convertToUTC } from "../../utils/dateProvider";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { PedidosModalPgto } from "../../modal/PedidosModalPgto";
import { PedidosModalNfe } from "../../modal/PedidosModalNfe";

type IFiltersPedidos = {
  empresa?: string;
  cliente?: IClientes;
  date_ini?: string;
  date_fin?: string;
  pago?: string; // "s" | "n" | "a"
}

const defaultFilterPedidos: IFiltersPedidos = {
  empresa: "",
  cliente: {} as IClientes,
  date_ini: "",
  date_fin: "",
  pago: "a"
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Pedidos() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [pesquisa, setPesquisa] = useState<IFiltersPedidos>(defaultFilterPedidos);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listPedidos, setListPedidos] = useState<IListPedido[]>([]);

  const [idPedidoSelected, setIdPedidoSelected] = useState("");

  const [modalCliente, setModalCliente] = useState(false);
  const [modalPgto, setModalPgto] = useState(false);
  const [modalNfe, setModalNfe] = useState(false);

  const [limitPage, setLimitPage] = useState(25);
  const [pedidosChecked, setPedidosChecked] = useState<IListPedido[]>([]);
  const [allChecked, setAllChecked] = useState(false);

  const [pesquisandoCliente, setPesquisandoCliente] = useState(false);
  const [pesquisandoEmpresa, setPesquisandoEmpresa] = useState(false);

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setPesquisa(y => ({ ...y, empresa: result.data.result[0].id }))
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => { });
  }

  const loadPedidos = async (canPesquisa = false) => {

    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de pedidos...");
    setPedidosChecked([]);

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa) {
      setPesquisandoCliente(pesquisa.cliente?.id ? true : false);
      setPesquisandoEmpresa(pesquisa.empresa ? true : false);
      dataRequest = {
        ...dataRequest,
        pesquisa: {
          cliente: pesquisa.cliente ? pesquisa.cliente.id : null,
          date_ini: pesquisa.date_ini ? `${convertToUs(pesquisa.date_ini)} 00:00:00` : null,
          date_fin: pesquisa.date_fin ? `${convertToUs(pesquisa.date_fin)} 23:59:59` : null,
          empresa: pesquisa.empresa ? pesquisa.empresa : null,
          pago: pesquisa.pago
        }
      };
    } else {
      setPesquisandoCliente(false);
      setPesquisandoEmpresa(false);
    }

    await api.post("pedidos/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        setListPedidos(response.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");

        if (listEmpresas.length <= 0)
          loadEmpresas();
      });
  }

  const changeDateIni = (value: string) => {
    setPesquisa(e => ({ ...e, date_ini: value }));
  }

  const changeDateFin = (value: string) => {
    setPesquisa(e => ({ ...e, date_fin: value }));
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setPesquisa(e => ({ ...e, cliente }));
  }

  const handleDelPedido = async (id: string) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir este pedido? Essa ação é irreversível"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo pedido...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("pedidos", { data: { id } })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadPedidos(true);
      });

  }

  const handlePedidoPgto = (idPedido: string) => {
    setIdPedidoSelected(idPedido);
    setModalPgto(true);
  }

  const handleCheckbox = (pedido: IListPedido) => {
    const newList: IListPedido[] = [];
    let canAdd = true;

    pedidosChecked.map((item) => {
      if (item.id !== pedido.id)
        newList.push(item);
      else
        canAdd = false;
    });

    if (canAdd)
      newList.push(pedido);

    setPedidosChecked(newList);
  }

  const handleAllChecked = () => {
    if (allChecked) {
      setPedidosChecked([]);
      setAllChecked(false);
      return;
    }

    const newList: IListPedido[] = [];

    listPedidos.map((item) => {
      if ((item.total - item.desconto) <= item.valor_pago)
        newList.push(item);
    })

    setPedidosChecked(newList);
  }

  useEffect(() => {
    loadPedidos();
  }, [token]);

  useEffect(() => {
    if (maxPage > 1)
      loadPedidos(true);
  }, [page, maxPage]);

  useEffect(() => {
    let qtdPedPagos = 0;

    listPedidos.map((item) => {
      if ((item.total - item.desconto) <= item.valor_pago)
        qtdPedPagos++;
    })

    setAllChecked(pesquisandoCliente && pesquisandoEmpresa && pedidosChecked.length >= qtdPedPagos ? true : false);

  }, [pedidosChecked, listPedidos]);

  return (
    <Topo page="Pedidos" title="Pedidos de NFe">
      <div className="PedidosPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            <div className="divPesquisa">

              <div style={{ width: "100%", textAlign: "right", marginTop: "5px" }}>
                <button className="btn-green" onClick={() => navigate("/pedidos/novo")}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} /> NOVO PEDIDO
                </button>
              </div>

              <div className="pesqEmpresa">
                <span>Empresa</span>
                <select value={pesquisa.empresa} onChange={(e) => setPesquisa(y => ({ ...y, empresa: e.target.value }))}>
                  <option value="">Pesquisa por Empresa</option>
                  {listEmpresas.length > 0 && listEmpresas.map((item) => (
                    <option key={item.id} value={item.id}>{item.fantasia}</option>
                  ))}
                </select>
              </div>

              <div className="pesqCliente">
                <span>Cliente</span>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                  <Input
                    mask="none"
                    value={pesquisa.cliente?.fantasia ? pesquisa.cliente?.fantasia : ""}
                    inputMaskChange={null}
                    placeholder="Pesquisa por Cliente"
                    readOnly
                  />
                  {pesquisa.cliente?.fantasia && (
                    <button className="btn-orange" onClick={() => setPesquisa(e => ({ ...e, cliente: {} as IClientes }))}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                    <FontAwesomeIcon icon={faUsers} />
                  </button>
                </div>
              </div>

              <div className="pesqDate">
                <span>Data Início</span>
                <Input
                  mask="date"
                  value={pesquisa.date_ini}
                  inputMaskChange={changeDateIni}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div className="pesqDate">
                <span>Data Final</span>
                <Input
                  mask="date"
                  value={pesquisa.date_fin}
                  inputMaskChange={changeDateFin}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div className="pesqDate">
                <span>Pago?</span>
                <select value={pesquisa.pago} onChange={(e) => setPesquisa(y => ({ ...y, pago: e.target.value }))}>
                  <option value="a">Ambos</option>
                  <option value="s">Sim</option>
                  <option value="n">Não</option>
                </select>
              </div>

              <div>
                <span>Pedidos por Página</span>
                <select value={limitPage} onChange={(e) => setLimitPage(parseInt(e.target.value))}>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
                <button className="btn-blue-dark" onClick={() => loadPedidos(true)}>
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: "5px" }} /> PESQUISAR
                </button>
              </div>

            </div>

            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "25px" }}>
                  {pesquisandoCliente && pesquisandoEmpresa && (
                    <input
                      type="checkbox"
                      style={{ width: "20px", height: "20px" }}
                      checked={allChecked}
                      onChange={handleAllChecked}
                    />
                  )}
                </span>
                <span style={{ width: "200px" }}>Cliente</span>
                <span style={{ width: "200px" }}>Empresa</span>
                <span style={{ width: "50px", textAlign: "center" }}>Data/Hora</span>
                <span style={{ width: "100px", textAlign: "right" }}>Valor</span>
                <span style={{ width: "70px", textAlign: "center" }}>Pago</span>
                <span style={{ width: "150px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable">
                {listPedidos.length > 0 && listPedidos.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "25px" }}>
                      {pesquisandoCliente && pesquisandoEmpresa && (item.total - item.desconto) <= item.valor_pago && (
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          checked={pedidosChecked.find(i => i.id === item.id)?.id ? true : false}
                          onChange={() => handleCheckbox(item)}
                        />
                      )}
                    </span>

                    <span style={{ width: "200px" }}>
                      {item.cliente.fantasia}
                    </span>
                    <span style={{ width: "200px" }}>{item.empresa.fantasia}</span>
                    <span style={{ width: "50px", textAlign: "right" }}>{convertToUTC(item.created_at)}</span>
                    <span style={{ width: "100px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.total - item.desconto}`))}</span>
                    <span style={{ width: "70px", textAlign: "center" }}>
                      {(item.total - item.desconto) <= item.valor_pago && ("Sim")}
                      {(item.total - item.desconto) > item.valor_pago && (
                        <>
                          Não
                          <br />
                          ( Rest.: {currencyFormatter((item.total - item.desconto) - item.valor_pago)} )
                        </>
                      )}

                    </span>
                    <span style={{ width: "150px", textAlign: "center" }}>
                      <button className="btn-green" onClick={() => navigate(`/pedidos/${item.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </button>

                      {(item.total - item.desconto) > item.valor_pago && (
                        <button onClick={() => handlePedidoPgto(item.id)}>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </button>
                      )}

                      <button className="btn-red" onClick={() => handleDelPedido(item.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </span>
                  </div>
                ))}
              </div>

              {pedidosChecked.length > 0 && (
                <button style={{ padding: "5px 15px", marginTop: "10px" }} onClick={() => setModalNfe(true)}>
                  Emitir NFe ( {pedidosChecked.length} )
                </button>
              )}

              <div className="pagination">
                {page !== 1 && (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
                )}
                <p>
                  {page}/{maxPage}
                </p>
                {page !== maxPage && (
                  <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
                )}
              </div>

            </div>

          </>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>

        <Modal isOpen={modalPgto} style={styleModal}>
          <PedidosModalPgto idPedido={idPedidoSelected} closeModal={() => setModalPgto(false)} refreshPedidos={() => loadPedidos(true)} />
        </Modal>

        <Modal isOpen={modalNfe} style={styleModal}>
          <PedidosModalNfe closeModal={() => setModalNfe(false)} pedidos={pedidosChecked} />
        </Modal>

      </div>
    </Topo>
  )
}