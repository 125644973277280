export function getNomeFormaPgto(value: number): string {
  switch (value) {
    case 1: return "Dinheiro";
    case 2: return "Cheque";
    case 3: return "Cartão";
    case 4: return "Cartão";
    case 5: return "Crédito";
    case 10: return "Vale";
    case 11: return "Vale";
    case 12: return "Vale";
    case 13: return "Vale";
    case 14: return "Duplicata";
    case 15: return "Boleto";
    case 90: return "Sem";
    case 99: return "Outros";
    default: return "";
  }
}