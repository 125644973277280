import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";

import { Topo } from "../../components/Topo";
import { IPedido } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";

import { convertToUTC } from "../../utils/dateProvider";
import { getNomeFormaPgto } from "../../utils/formaPgto";

import "./style.css";

type IParams = {
  id: string;
}

export function PedidosDetalhe() {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pedido, setPedido] = useState<IPedido>({} as IPedido);

  const [totalPago, setTotalPago] = useState(0);

  const loadPedido = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando Pedido...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`pedidos/${params.id}`)
      .then((result) => {
        if (!result.data)
          return;

        const data = result.data as IPedido;
        setPedido(data);

        let pgtos = 0;
        data.pgtos.map((item) => {
          pgtos += parseFloat(`${item.valor}`);
        });

        setTotalPago(pgtos);

      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  };

  useEffect(() => {
    loadPedido();
  }, [token]);

  return (
    <Topo page="Pedidos" title="Detalhes do Pedido">

      {loading && (
        <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
      )}

      {!loading && (
        <div className="PedDetalhePage">

          <div className="abas">INFORMAÇÕES</div>
          <div className="abasContent">

            <div style={{ width: "49%" }}>
              <span>Data/Hora:</span>
              <p>{convertToUTC(pedido.created_at)}</p>
            </div>

            <div style={{ width: "49%" }}>
              <span>Loca da venda:</span>
              <p>{pedido.local_venda}</p>
            </div>

            <div style={{ width: "49%" }}>
              <span>Empresa:</span>
              <p>{pedido.empresa?.fantasia}</p>
            </div>

            <div style={{ width: "49%" }}>
              <span>Cliente:</span>
              <p>{pedido.cliente?.fantasia}</p>
            </div>

            <div style={{ width: "100%" }}>
              <span>Endereço:</span>
              <p>{pedido.endereco}, nº {pedido.numero} {pedido.complemento} - {pedido.bairro} - {pedido.cidade?.nome}/{pedido.cidade?.uf.uf}</p>
            </div>
          </div>

          <h3>PRODUTOS</h3>

          <div className="gridContext">
            <div className="headTable">
              <span style={{ width: "200px" }}>Produto</span>
              <span style={{ width: "50px", textAlign: "right" }}>Quantidade</span>
              <span style={{ width: "70px", textAlign: "right" }}>V. Unit.</span>
              <span style={{ width: "90px", textAlign: "right" }}>Total</span>
            </div>

            <div className="contentTable">
              {pedido.pedidos?.map((item) => (
                <div className="rowTable" key={item.id_produto}>
                  <span style={{ width: "200px" }}>{item.produto.nome}</span>
                  <span style={{ width: "50px", textAlign: "right" }}>{parseFloat(`${item.qtd}`)}</span>
                  <span style={{ width: "70px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit}`))}</span>
                  <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit * item.qtd}`))}</span>
                </div>
              ))}

              <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", justifyContent: "flex-end" }}>
                <span style={{ fontSize: "18px" }}>TOTAL: {currencyFormatter(parseFloat(`${pedido.total}`))}</span>
              </div>
            </div>
          </div>

          <h3>PAGAMENTOS</h3>

          <div className="gridContext">
            <div className="headTable">
              <span style={{ width: "200px" }}>Forma de Pagamento</span>
              <span style={{ width: "90px", textAlign: "right" }}>Valor</span>
            </div>

            <div className="contentTable">
              {pedido.pgtos?.map((item) => (
                <div className="rowTable" key={`${item.forma_pgto}`}>
                  <span style={{ width: "200px" }}>{getNomeFormaPgto(item.forma_pgto)}</span>
                  <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                </div>
              ))}

              <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                <div className="divDesconto">
                  <span>DESCONTO: {currencyFormatter(parseFloat(`${pedido.desconto}`))}</span>
                </div>
                <span style={{ fontSize: "16px" }}>TOTAL PAGO: {currencyFormatter(parseFloat(`${totalPago}`))}</span>
              </div>
            </div>

          </div>

        </div>
      )}
    </Topo>
  )
}