import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faPen } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IEmpresa } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { maskCpfCnpj } from "../../utils/masks";
import { checkToken } from "../../utils/checkToken";
import { api } from "../../services/api";
import { EmpresaModalCertificado } from "../../modal/EmpresaModalCertificado";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: 300,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Empresas() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCert, setModalCert] = useState(false);
  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);

  const [empresaSelected, setEmpresaSelected] = useState<IEmpresa>({} as IEmpresa);

  const loadEmpresas = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de empresas");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleCertificado = (e: IEmpresa) => {
    setEmpresaSelected(e);
    setModalCert(true);
  }

  useEffect(() => {
    if (token)
      loadEmpresas();
  }, [token])

  return (
    <Topo page="Empresas" title="Empresas">
      <div className="EmpresasPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>

            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "200px" }}>Fantasia</span>
                <span style={{ width: "200px" }}>CNPJ</span>
                <span style={{ width: "200px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable">
                {listEmpresas.length > 0 && listEmpresas.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "200px" }}>{item.fantasia}</span>
                    <span style={{ width: "200px" }}>{maskCpfCnpj(`${item.cnpj}`)}</span>
                    <span style={{ width: "200px", textAlign: "center" }}>
                      <button className="btn-green" onClick={() => handleCertificado(item)}>
                        <FontAwesomeIcon icon={faKey} />
                      </button>
                      <button className="btn-orange" onClick={() => { navigate(`/empresas/${item.id}`) }}>
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </span>
                  </div>
                ))}
              </div>

            </div>
          </>
        )}

        <Modal isOpen={modalCert} style={styleModal}>
          <EmpresaModalCertificado closeModal={() => setModalCert(false)} empresa={empresaSelected} />
        </Modal>

      </div>
    </Topo>
  )
}