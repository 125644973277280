import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Topo } from "../../components/Topo";
import { useCache } from "../../hooks/useCache";
import Modal from "react-modal";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { IAgendamento, IClientes, ICreateAgendamentoDTO } from "../../global/types";
import { convertDataHora, convertDateToBr, dateNow, getHourMin } from "../../utils/dateProvider";
import { api } from "../../services/api";
import { Load } from "../../components/Load";
import { Input } from "../../components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo, faUsers } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import { checkToken } from "../../utils/checkToken";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit"
}

const defaultRequest: ICreateAgendamentoDTO = {
  id_cliente: "",
  descricao: "",
  data_agendamento: dateNow(),
  urgente: false,
  concluido: false
};

export function AgendamentoForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, txtAlerta, colorAlerta, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCliente, setModalCliente] = useState(false);

  const [clienteSelected, setClienteSelected] = useState<IClientes>({} as IClientes);

  const [request, setRequest] = useState<ICreateAgendamentoDTO>(defaultRequest);
  const [dataAgendamento, setDataAgendamento] = useState(convertDateToBr(dateNow()));
  const [horaAgendamento, setHoraAgendamento] = useState(getHourMin(dateNow()));

  const handleSelectCliente = (cliente: IClientes) => {
    setClienteSelected(cliente);
    setRequest(e => ({
      ...e,
      id_cliente: cliente.id
    }));
  }

  const loadCadastro = async () => {
    if (!token)
      return;

    if (tipo === "Novo" || !params.id || params.id.length <= 0) {
      setLoading(false);
      setTxtLoading("");
      return;
    }

    setTxtLoading("Carregando cadastro...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`agendamento/${params.id}`)
      .then(async (result) => {
        if (!result.data)
          return;

        const data = result.data as IAgendamento;

        const requestCliente = await api.get(`clientes/${data.id_cliente}`);

        if (!requestCliente.data)
          return;

        setClienteSelected(requestCliente.data);

        setRequest({
          id_cliente: data.id_cliente,
          descricao: data.descricao,
          data_agendamento: data.data_agendamento,
          urgente: data.urgente,
          concluido: data.concluido
        });

        setDataAgendamento(convertDateToBr(data.data_agendamento));
        setHoraAgendamento(getHourMin(data.data_agendamento));

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleKeyClientes = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalCliente(true);
    }
  }

  const handleSalvar = async () => {
    if (request.descricao.length <= 5) {
      setAlert("Preecha o campo descrição corretamente", "danger");
      return;
    }

    if (dataAgendamento.length < 10) {
      setAlert("Preecha o campo data de agendamento corretamente", "danger");
      return;
    }

    if (horaAgendamento.length < 5) {
      setAlert("Preecha o campo hora de agendamento corretamente", "danger");
      return;
    }

    setLoading(true);
    setTxtLoading("Salvando Agendamento...");

    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let data: ICreateAgendamentoDTO = { ...request, data_agendamento: convertDataHora(`${dataAgendamento} ${horaAgendamento}`) };

    if (tipo === "Edit")
      data = { ...data, id: params.id };

    console.log(data);


    await api.post("agendamento", { ...data })
      .then((result) => {
        setAlert("Agendamento salvo com sucesso", "success");
        navigate("/agendamento");
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  useEffect(() => {
    setLoading(true);
    setTxtLoading("Carregando...");
    loadCadastro();
  }, [token]);

  return (
    <Topo page="Agendamento" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Agendamento`}>
      <div id="AgendamentoFormPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <div className="form">

            <div className="contentInput" style={{ width: "75%" }}>
              <span>Cliente:</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                <Input
                  mask="none"
                  value={clienteSelected.fantasia}
                  inputMaskChange={null}
                  placeholder="Selecione o Cliente"
                  readOnly
                  onKeyUp={handleKeyClientes}
                />
                <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                  <FontAwesomeIcon icon={faUsers} />
                </button>
              </div>
            </div>

            <div className="contentInput" style={{ width: "25%" }}>
              <span>Urgente?</span>
              <select value={request.urgente ? "1" : "0"} onChange={(e) => setRequest(y => ({ ...y, urgente: e.target.value === "1" }))}>
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>

            <div className="contentInput" style={{ width: "33%" }}>
              <span>Data Agendamento:</span>
              <Input
                mask="date"
                value={dataAgendamento}
                inputMaskChange={setDataAgendamento}
                placeholder="dd/mm/aaaa"
                maxLength={10}
              />
            </div>
            <div className="contentInput" style={{ width: "33%" }}>
              <span>Hora Agendamento:</span>
              <Input
                mask="hour"
                value={horaAgendamento}
                inputMaskChange={setHoraAgendamento}
                placeholder="HH:MM"
                maxLength={5}
              />
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Descrição</span>
              <textarea
                placeholder="Digite aqui a descrição do Agendamento"
                value={request.descricao}
                onChange={e => setRequest(y => ({ ...y, descricao: e.target.value }))}
              />
            </div>

            <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
              <button className="btnSalvar btn-green" onClick={handleSalvar}>
                <FontAwesomeIcon icon={faSave} />
                SALVAR
              </button>

              <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faUndo} />
                CANCELAR
              </button>
            </div>

          </div>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={(e) => handleSelectCliente(e)} />
        </Modal>
      </div>
    </Topo>
  )
}