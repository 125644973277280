import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICreateFormaPgtoBandTaxasDTO, IFormaPgtoBand, IFormaPgtoBandTaxa } from "../../global/types";
import { dynamicSort } from "../../utils/dynamicSort";

import "./style.css";

type IProps = {
  bandeira: any;
  closeModal: () => void;
  addTempTaxa: (taxa: ITempBandTaxas) => void;
  addTaxa: (taxa: ICreateFormaPgtoBandTaxasDTO) => void;
  delTaxa: (taxa: IFormaPgtoBandTaxa | null, tempTaxa: ICreateFormaPgtoBandTaxasDTO | null) => Promise<void>;
  delTempTaxa: (taxa: ITempBandTaxas) => void;
}

type ITempBandTaxas = {
  nrParcela: number;
  porcentagem: number;
  taxaAdiantamento: number;
}

export function FormaPgtoModalTaxas({ closeModal, bandeira, addTaxa, addTempTaxa, delTaxa, delTempTaxa }: IProps) {

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [nrParcela, setNrParcela] = useState(1);
  const [porcentagem, setPorcentagem] = useState(0);
  const [taxaAdiantamento, setTaxaAdiantamento] = useState(0);

  const handleFocusOut = (min: number, value: number, campo: any) => {
    if (value < min)
      campo(min);
  }

  const handleAdd = () => {
    if (bandeira.id) {
      addTaxa({
        id_forma_pgto_band: "",
        nr_parcela: nrParcela,
        porcentagem,
        taxa_adiantamento: taxaAdiantamento
      });
    } else {
      addTempTaxa({
        nrParcela,
        porcentagem,
        taxaAdiantamento
      });
    }

    setNrParcela(i => parseInt(`${i}`) + 1);
    setPorcentagem(0);
    setTaxaAdiantamento(0);
  }

  const handleDelTaxa = async (taxa: IFormaPgtoBandTaxa | null, tempTaxa: ICreateFormaPgtoBandTaxasDTO | null) => {
    if (!window.confirm("Deseja realmente excluir?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo parcela...");

    await delTaxa(taxa, tempTaxa);

    setLoading(false);
    setTxtLoading("");
  }

  const handleDelTempTaxa = (taxa: ITempBandTaxas) => {
    if (!window.confirm("Deseja realmente remover?"))
      return;

    delTempTaxa(taxa);
  }

  return (
    <div id="modalFormaPgtoBandTaxas">
      <div className="title">
        <p>Bandeira: {bandeira.nome}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <div className="divForm">
            <div style={{ width: "32%" }}>
              <span>Nrº da Parcela:</span>
              <Input
                mask="none"
                value={nrParcela}
                inputMaskChange={setNrParcela}
                placeholder="0"
                style={{ textAlign: "right" }}
                type="number"
                min={1}
                onBlur={(e) => handleFocusOut(1, parseInt(e.target.value), setNrParcela)}
              />
            </div>
            <div style={{ width: "32%" }}>
              <span>Porcentagem:</span>
              <Input
                mask="none"
                value={porcentagem}
                inputMaskChange={setPorcentagem}
                placeholder="0%"
                style={{ textAlign: "right" }}
                type="number"
                min={0}
                onBlur={(e) => handleFocusOut(0, parseInt(e.target.value), setPorcentagem)}
              />
            </div>
            <div style={{ width: "32%" }}>
              <span>Taxa de Adiantamento:</span>
              <Input
                mask="none"
                value={taxaAdiantamento}
                inputMaskChange={setTaxaAdiantamento}
                placeholder="0%"
                style={{ textAlign: "right" }}
                type="number"
                min={1}
                onBlur={(e) => handleFocusOut(1, parseInt(e.target.value), setTaxaAdiantamento)}
              />
            </div>

            <div style={{ width: "100%", textAlign: "center" }}>
              <button className="btn-green" onClick={() => handleAdd()}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
                ADICIONAR
              </button>
            </div>


            <div className="divGrid">
              <div className="headTable">
                <span style={{ minWidth: "15%", textAlign: "center" }}></span>
                <span style={{ minWidth: "27%", textAlign: "right" }}>Nrº Parcela</span>
                <span style={{ minWidth: "28%", textAlign: "right" }}>Porcetagem</span>
                <span style={{ minWidth: "30%", textAlign: "right" }}>Taxa de Adiantamento</span>
              </div>

              <div className="contentTable">
                {bandeira.id && (
                  <>
                    {bandeira.newTaxas && bandeira.newTaxas.sort(dynamicSort("nr_parcela")).map((item: any, i: number) => (
                      <div className="rowTable" key={`${bandeira.nome}-nT-${i}`}>
                        <span style={{ minWidth: "15%", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelTaxa(null, item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                        <span style={{ minWidth: "27%", textAlign: "right" }}>{item.nr_parcela}</span>
                        <span style={{ minWidth: "28%", textAlign: "right" }}>{item.porcentagem}%</span>
                        <span style={{ minWidth: "30%", textAlign: "right" }}>{item.taxa_adiantamento}%</span>
                      </div>
                    ))}

                    {bandeira.taxas.sort(dynamicSort("nr_parcela")).map((item: any) => (
                      <div className="rowTable" key={item.id}>
                        <span style={{ minWidth: "15%", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelTaxa(item, null)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                        <span style={{ minWidth: "27%", textAlign: "right" }}>{item.nr_parcela}</span>
                        <span style={{ minWidth: "28%", textAlign: "right" }}>{item.porcentagem}%</span>
                        <span style={{ minWidth: "30%", textAlign: "right" }}>{item.taxa_adiantamento}%</span>
                      </div>
                    ))}
                  </>
                )}

                {bandeira && !bandeira.id && (
                  <>
                    {bandeira.taxas && bandeira.taxas.sort(dynamicSort("nrParcela")).map((item: any, i: number) => (
                      <div className="rowTable" key={`tBand-${i}`}>
                        <span style={{ minWidth: "15%", textAlign: "center" }}>
                          <button className="btn-red" onClick={() => handleDelTempTaxa(item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </span>
                        <span style={{ minWidth: "27%", textAlign: "right" }}>{item.nrParcela}</span>
                        <span style={{ minWidth: "28%", textAlign: "right" }}>{item.porcentagem}%</span>
                        <span style={{ minWidth: "30%", textAlign: "right" }}>{item.taxaAdiantamento}%</span>
                      </div>
                    ))}
                  </>
                )}
              </div>

            </div>

          </div>
        )}
      </div>

    </div>
  )
}