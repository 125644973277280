import { faPrint, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Topo } from "../../components/Topo";
import { IClientes, IEmpresa, IFiltersOrdemServico, IStatus } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { dynamicSort } from "../../utils/dynamicSort";
import Modal from "react-modal";

import "./style.css";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { Load } from "../../components/Load";
import { convertToUs } from "../../utils/dateProvider";

const defaultFilterOrdemServico: IFiltersOrdemServico = {
  empresa: "",
  usuario: "",
  cliente: {} as IClientes,
  date_ini: "",
  date_fin: "",
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function RelatorioOrdemServico() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [pesquisa, setPesquisa] = useState<IFiltersOrdemServico>(defaultFilterOrdemServico);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listStatus, setListStatus] = useState<IStatus[]>([]);

  const [modalCliente, setModalCliente] = useState(false);

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setPesquisa(y => ({ ...y, empresa: result.data.result[0].id }))
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadStatus();
      });
  }

  const loadStatus = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("status/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListStatus(result.data.result);

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
      });
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setPesquisa(e => ({ ...e, cliente }));
  }

  const changeDateIni = (value: string) => {
    setPesquisa(e => ({ ...e, date_ini: value }));
  }

  const changeDateFin = (value: string) => {
    setPesquisa(e => ({ ...e, date_fin: value }));
  }

  const handleImprimir = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Verificando login...");

    const rsCheckToken = await checkToken(cacheError, token);

    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Gerando Relatório...");

    await api.post("relatorios/ordemServico", {
      cursor: 0,
      limit: 5000,
      pesquisa: {
        cliente: pesquisa.cliente ? pesquisa.cliente.id : null,
        date_ini: pesquisa.date_ini ? `${convertToUs(pesquisa.date_ini)} 00:00:00` : null,
        date_fin: pesquisa.date_fin ? `${convertToUs(pesquisa.date_fin)} 23:59:59` : null,
        empresa: pesquisa.empresa ? pesquisa.empresa : null,
        id_status: pesquisa.status ? pesquisa.status : null
      }
    },
      {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" }
      })
      .then((result) => {
        if (!result.data)
          return;

        const file = new Blob([result.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        if (pdfWindow !== null)
          pdfWindow.location.href = fileURL;
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status !== 401)
          setAlert(`${err.response.data.message}`, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  useEffect(() => {
    loadEmpresas();
  }, [token]);

  return (
    <Topo page="Relatorio" title="Relatório de Ordem de Serviço">
      <div id="RelOrdemServicoPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <div className="divPesquisa">
            <div className="pesqEmpresa">
              <span>Empresa</span>
              <select value={pesquisa.empresa} onChange={(e) => setPesquisa(y => ({ ...y, empresa: e.target.value }))}>
                <option value="">Pesquisa por Empresa</option>
                {listEmpresas.length > 0 && listEmpresas.map((item) => (
                  <option key={item.id} value={item.id}>{item.fantasia}</option>
                ))}
              </select>
            </div>

            <div className="pesqCliente">
              <span>Cliente</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                <Input
                  mask="none"
                  value={pesquisa.cliente?.fantasia ? pesquisa.cliente?.fantasia : ""}
                  inputMaskChange={null}
                  placeholder="Pesquisa por Cliente"
                  readOnly
                />
                {pesquisa.cliente?.fantasia && (
                  <button className="btn-orange" onClick={() => setPesquisa(e => ({ ...e, cliente: {} as IClientes }))}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}

                <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                  <FontAwesomeIcon icon={faUsers} />
                </button>
              </div>
            </div>

            <div className="pesqDate">
              <span>Status</span>
              <select value={pesquisa.status} onChange={(e) => setPesquisa(y => ({ ...y, status: e.target.value }))}>
                <option value="">Pesquisa por Stauts</option>
                {listStatus.length > 0 && listStatus.sort(dynamicSort("ordem")).map((item) => (
                  <option key={item.id} value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>

            <div className="pesqDate">
              <span>Data Início</span>
              <Input
                mask="date"
                value={pesquisa.date_ini}
                inputMaskChange={changeDateIni}
                placeholder="dd/mm/aaaa"
                maxLength={10}
              />
            </div>

            <div className="pesqDate">
              <span>Data Final</span>
              <Input
                mask="date"
                value={pesquisa.date_fin}
                inputMaskChange={changeDateFin}
                placeholder="dd/mm/aaaa"
                maxLength={10}
              />
            </div>

            <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
              <button className="btn-blue-dark" onClick={() => handleImprimir()}>
                <FontAwesomeIcon icon={faPrint} style={{ marginRight: "5px" }} /> IMPRIMIR
              </button>
            </div>

          </div>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>

      </div>
    </Topo>
  );

}