import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IListRequest, IProduto } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { currencyFormatter } from "../../utils/currencyFormatter";

type IProps = {
  handleSelectedProduto: (produto: IProduto) => void;
  closeModal: () => void;
}

export function PedidosModalProdutos({ closeModal, handleSelectedProduto }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pesquisa, setPesquisa] = useState("");

  const [listProdutos, setListProdutos] = useState<IProduto[]>([]);

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handlePesquisa = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de produtos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: 0,
      limit: 25,
      pesquisa
    }

    await api.post("produtos/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        setListProdutos(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (produto: IProduto) => {
    handleSelectedProduto(produto);
    closeModal();
  }

  const loadFavoritos = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de produtos favoritos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("produtos/favoritos")
      .then((response) => {
        if (!response.data)
          return;

        setListProdutos(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadFavoritos();
  }, []);

  return (
    <div className="modal">
      <div className="title">
        <p>Lista de Produtos</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="info">
              <h4>Pesquisa</h4>
            </div>

            <div className="divFormModal">
              <Input
                autoFocus
                mask="none"
                value={pesquisa}
                inputMaskChange={setPesquisa}
                placeholder="Pesquisa..."
                onKeyUp={handleKeyPesquisa}
              />

              <button className="btn-green" onClick={handlePesquisa}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>

            <div className="headTable">
              <span>Nome</span>
              <span>Valor</span>
            </div>
            <div className="contentTable">
              {listProdutos.map((item) => (
                <div className="rowTable" key={item.id} onClick={() => handleClick(item)}>
                  <span>{item.nome}</span>
                  <span>{currencyFormatter(parseFloat(`${item.preco}`))}</span>
                </div>
              ))}

              {listProdutos.length === 0 && (
                <h4 style={{ color: "#7E7E7E", fontWeight: "500", textAlign: "center", marginTop: "10px" }}>Pesquise o nome ou código de barras do produto</h4>
              )}

            </div>
          </>
        )}

      </div>

    </div>
  );
}