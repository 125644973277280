import { IResponseCheckError } from "../global/types";
import { api } from "../services/api";

export async function checkErro(err: any, cache: any): Promise<IResponseCheckError> {
  const { refreshToken, usuario, cacheTokens } = cache;

  let refazerRequest = false;
  let redirectLogin = false;
  let nToken = null;
  let nRefreshToken = null;

  if (err.response.status === 401) {
    if (!refreshToken)
      redirectLogin = true;
    else {
      try {
        const response = await api.post("refresh-token", { token: refreshToken });
        nToken = response.data.token
        nRefreshToken = response.data.refreshToken;

        cacheTokens(response.data.token, response.data.refreshToken, usuario);
        api.defaults.headers.common['Authorization'] = `Bearer ${nToken}`;
        refazerRequest = true;

      } catch (error) {
        redirectLogin = true;
      }
    }
  } else
    alert(err.response.data.message);

  return {
    refazerRequest,
    redirectLogin,
    token: nToken,
    tokenRefresh: nRefreshToken
  };
};