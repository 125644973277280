import { faAngleDoubleLeft, faAngleDoubleRight, faCheck, faEye, faPlus, faSearch, faTimes, faTrash, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { IAgendamento, IClientes, IListRequest } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { checkToken } from "../../utils/checkToken";
import Modal from "react-modal";

import "./style.css";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { convertToUs, convertToUTC } from "../../utils/dateProvider";
import { api } from "../../services/api";

export type IFiltersAgendamento = {
  cliente?: IClientes;
  date_ini?: string;
  date_fin?: string;
}

const defaultFiltersAgendamento: IFiltersAgendamento = {
  cliente: {} as IClientes,
  date_ini: "",
  date_fin: "",
}

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

export function Agendamentos() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [pesquisa, setPesquisa] = useState<IFiltersAgendamento>(defaultFiltersAgendamento);

  const [listAgendamento, setListAgendamento] = useState<IAgendamento[]>([]);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCliente, setModalCliente] = useState(false);

  const [limitPage, setLimitPage] = useState(25);

  const changeDateIni = (value: string) => {
    setPesquisa(e => ({ ...e, date_ini: value }));
  }

  const changeDateFin = (value: string) => {
    setPesquisa(e => ({ ...e, date_fin: value }));
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setPesquisa(e => ({ ...e, cliente }));
  }

  const loadAgendamento = async (canPesquisa = false) => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de Agendamentos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    }

    if (canPesquisa) {
      dataRequest = {
        ...dataRequest,
        pesquisa: {
          cliente: pesquisa.cliente ? pesquisa.cliente.id : null,
          date_ini: pesquisa.date_ini ? `${convertToUs(pesquisa.date_ini)} 00:00:00` : null,
          date_fin: pesquisa.date_fin ? `${convertToUs(pesquisa.date_fin)} 23:59:59` : null,
        }
      };
    }

    await api.post("agendamento/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        const newMaxPage = Math.ceil(response.data.total / limitPage);

        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        setListAgendamento(response.data.result);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleDelAgendamento = async (item: IAgendamento) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir este Agendamento?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo Agendamento...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("agendamento", { data: { id: item.id } })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadAgendamento(true);
      });
  }

  const handleConcluirAgendamento = async (item: IAgendamento) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente concluir este Agendamento?"))
      return;

    setLoading(true);
    setTxtLoading("Concluindo Agendamento...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("agendamento", { ...item, concluido: true })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadAgendamento(true);
      });
  }

  useEffect(() => {
    loadAgendamento();
  }, [token]);

  useEffect(() => {
    if (maxPage > 1)
      loadAgendamento(true);
  }, [page, maxPage]);

  return (
    <Topo page="Agendamento" title="Agendamento">
      <div id="agendamentoPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <>
            <div className="divPesquisa">

              <div style={{ width: "100%", textAlign: "right", marginTop: "5px" }}>
                <button className="btn-green" onClick={() => navigate("/agendamento/novo")}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} /> NOVO AGENDAMENTO
                </button>
              </div>

              <div className="pesqCliente">
                <span>Cliente</span>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                  <Input
                    mask="none"
                    value={pesquisa.cliente?.fantasia ? pesquisa.cliente?.fantasia : ""}
                    inputMaskChange={null}
                    placeholder="Pesquisa por Cliente"
                    readOnly
                  />
                  {pesquisa.cliente?.fantasia && (
                    <button className="btn-orange" onClick={() => setPesquisa(e => ({ ...e, cliente: {} as IClientes }))}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                    <FontAwesomeIcon icon={faUsers} />
                  </button>
                </div>
              </div>

              <div className="pesqDate">
                <span>Data Início</span>
                <Input
                  mask="date"
                  value={pesquisa.date_ini}
                  inputMaskChange={changeDateIni}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div className="pesqDate">
                <span>Data Final</span>
                <Input
                  mask="date"
                  value={pesquisa.date_fin}
                  inputMaskChange={changeDateFin}
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                />
              </div>

              <div>
                <span>Pedidos por Página</span>
                <select value={limitPage} onChange={(e) => setLimitPage(parseInt(e.target.value))}>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
                <button className="btn-blue-dark" onClick={() => loadAgendamento(true)}>
                  <FontAwesomeIcon icon={faSearch} style={{ marginRight: "5px" }} /> PESQUISAR
                </button>
              </div>

            </div>

            <div className="gridContext">
              <div className="headTable">
                <span style={{ width: "200px" }}>Cliente</span>
                <span style={{ width: "200px" }}>Descrição</span>
                <span style={{ width: "90px", textAlign: "center" }}>Data/Hora</span>
                <span style={{ width: "80px", textAlign: "center" }}>Urgente?</span>
                <span style={{ width: "80px", textAlign: "center" }}>Concluído?</span>
                <span style={{ width: "150px", textAlign: "center" }}>Ações</span>
              </div>

              <div className="contentTable">
                {listAgendamento.length > 0 && listAgendamento.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "200px" }}>{item.cliente.fantasia}</span>
                    <span style={{ width: "200px" }}>{item.descricao.length > 25 ? `${item.descricao.substring(0, 25)}...` : item.descricao}</span>
                    <span style={{ width: "90px", textAlign: "center" }}>{convertToUTC(item.data_agendamento)}</span>
                    <span style={{ width: "80px", textAlign: "center" }}>{item.urgente ? "SIM" : "NÃO"}</span>
                    <span style={{ width: "80px", textAlign: "center" }}>{item.concluido ? "SIM" : "NÃO"}</span>
                    <span style={{ width: "150px", textAlign: "center" }}>
                      <button onClick={() => navigate(`/agendamento/${item.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      <button className="btn-red" onClick={() => handleDelAgendamento(item)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button className="btn-green" onClick={() => handleConcluirAgendamento(item)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="pagination">
              {page !== 1 && (
                <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
              )}
              <p>
                {page}/{maxPage}
              </p>
              {page !== maxPage && (
                <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "22px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
              )}
            </div>

          </>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>
      </div>
    </Topo>
  )
}