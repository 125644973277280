import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faBox, faPlus, faTrash, faPercent, faSave, faUndo, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICidades, IClientes, ICreateNfeDTO, ICreateNfePgtosDTO, ICreateNfeProdutosDTO, ICreatePedidosDTO, ICreatePedidosPgtosDTO, ICreatePedidosProdutosDTO, IEmpresa, IPedido, IProduto } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import { api } from "../../services/api";
import { checkToken } from "../../utils/checkToken";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { Input } from "../../components/Input";

import "./style.css";
import { PedidosModalProdutos } from "../../modal/PedidosModalProdutos";
import { ModalCidades } from "../../modal/ModalCidades";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { PedidosModalDesconto } from "../../modal/PedidosModalDesconto";
import { maskCep } from "../../utils/masks";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

const defaultRequest: ICreatePedidosDTO = {
  id_empresa: "",
  id_cliente: "",
  id_cidades: 0,
  total: 0,
  desconto: 0,
  valor_pago: 0,
  endereco: "",
  numero: 0,
  complemento: "",
  bairro: "",
  cep: "",
  local_venda: ""
}

export function PedidosForm() {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, cacheCidades, saveCacheCidades, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCliente, setModalCliente] = useState(false);
  const [modalProdutos, setModalProdutos] = useState(false);
  const [modalDesconto, setModalDesconto] = useState(false);
  const [modalCidades, setModalCidades] = useState(false);
  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listCidades, setListCidades] = useState<ICidades[]>([]);

  const [clienteSelected, setClienteSelected] = useState<IClientes>({} as IClientes);
  const [cidadeSelected, setCidadeSelected] = useState<ICidades>({} as ICidades);

  const [produtoSelected, setProdutoSelected] = useState<IProduto>({} as IProduto);
  const [qtdSelected, setQtdSelected] = useState<number>(1);

  const [formaPgtoSelected, setFormaPgtoSelectes] = useState("");
  const [valorSelected, setValorSelected] = useState("");
  const [inputDesconto, setInputDesconto] = useState("");

  const [request, setRequest] = useState<ICreatePedidosDTO>(defaultRequest);
  const [requestProdutos, setRequestProdutos] = useState<ICreatePedidosProdutosDTO[]>([]);
  const [requestPgtos, setRequestPgtos] = useState<ICreatePedidosPgtosDTO[]>([]);

  const [totalProdutos, setTotalProdutos] = useState(0);
  const [totalPago, setTotalPago] = useState(0);
  const [desconto, setDesconto] = useState(0);

  const handleSelectCliente = (cliente: IClientes) => {
    setClienteSelected(cliente);

    let tempCidade: ICidades = {} as ICidades;

    cacheCidades.filter(i => i.id === cliente.id_cidades).map((item) => {
      tempCidade = item;
    });

    if (tempCidade.nome)
      handleSelectCidade(tempCidade);

    setRequest(e => ({
      ...e,
      id_cliente: cliente.id,
      endereco: cliente.endereco,
      bairro: cliente.bairro,
      cep: maskCep(`${cliente.cep}`),
      complemento: cliente.complemento,
      id_cidades: cliente.id_cidades,
      numero: cliente.numero
    }));
  }

  const handleSelectedProduto = (produto: IProduto) => {
    setProdutoSelected(produto);
  }

  const handleSelectCidade = (cidade: ICidades) => {
    setCidadeSelected(cidade);
    setRequest(e => ({ ...e, id_cidades: cidade.id }));
  }

  const loadCidades = async () => {
    if (!token)
      return;

    if (cacheCidades.length > 0) {
      setListCidades(cacheCidades);
      return;
    }

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("cidades/list")
      .then((result) => {
        if (!result.data)
          return;

        setListCidades(result.data);
        saveCacheCidades(result.data);
      })
      .catch((err) => {
        if (err.response.status != 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => { })
  }

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setRequest(y => ({ ...y, id_empresa: result.data.result[0].id }));

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => { });
  }

  const handleAddProduto = () => {
    if (!produtoSelected.nome)
      return;

    const alredyExists: ICreatePedidosProdutosDTO[] = [];
    requestProdutos.map((item) => {
      if (item.id_produto === produtoSelected.id)
        alredyExists.push(item);
    });

    if (alredyExists.length > 0) {
      const newList: ICreatePedidosProdutosDTO[] = [];

      requestProdutos.map((item) => {
        if (item.id_produto === produtoSelected.id)
          newList.push({ ...item, qtd: item.qtd + qtdSelected });
        else
          newList.push(item);
      });

      setRequestProdutos(newList);
    } else {
      const temp: ICreatePedidosProdutosDTO = {
        id_produto: produtoSelected.id,
        nome_produto: produtoSelected.nome,
        qtd: qtdSelected,
        valor_unit: produtoSelected.preco
      };

      setRequestProdutos(e => ([temp, ...e]));
    }

    setProdutoSelected({} as IProduto);
    setQtdSelected(1);
  }

  const handleDelProduto = (produto: ICreatePedidosProdutosDTO) => {
    if (!window.confirm("Deseja realmente excluir este produto do pedido?"))
      return;

    const newList: ICreatePedidosProdutosDTO[] = [];

    requestProdutos.map((item) => {
      if (item.id_produto !== produto.id_produto)
        newList.push(item);
    })

    setRequestProdutos(newList);
  }

  const handleAddFormaPgto = () => {
    if (formaPgtoSelected.length <= 0)
      return;

    const alreadyExists: ICreatePedidosPgtosDTO[] = [];

    requestPgtos.map((item) => {
      if (item.forma_pgto === parseInt(formaPgtoSelected))
        alreadyExists.push(item);
    });

    if (alreadyExists.length > 0) {
      const newList: ICreatePedidosPgtosDTO[] = [];

      requestPgtos.map((item) => {
        if (item.forma_pgto === parseInt(formaPgtoSelected))
          newList.push({ ...item, valor: item.valor + parseFloat(valorSelected) });
        else
          newList.push(item);
      });

      let newTotalPago = 0;

      newList.map((item) => {
        newTotalPago += item.valor;
      });

      if (parseFloat(`${newTotalPago.toFixed(2)}`) > parseFloat(`${(totalProdutos - desconto).toFixed(2)}`))
        return alert("Valor informado maior do que o valor restante");

      setRequestPgtos(newList);

    } else {

      let nomeFormPgto = "";

      switch (formaPgtoSelected) {
        case "01": nomeFormPgto = "Dinheiro"; break;
        case "02": nomeFormPgto = "Cheque"; break;
        case "03": nomeFormPgto = "Cartão de Crédito"; break;
        case "04": nomeFormPgto = "Cartão de Débito"; break;
        case "05": nomeFormPgto = "Crédito Loja"; break;
        case "10": nomeFormPgto = "Vale Alimentação"; break;
        case "11": nomeFormPgto = "Vale Refeição"; break;
        case "12": nomeFormPgto = "Vale Presente"; break;
        case "13": nomeFormPgto = "Vale Combustível"; break;
        case "14": nomeFormPgto = "Duplicata Mercantil"; break;
        case "15": nomeFormPgto = "Boleto Bancário"; break;
        case "90": nomeFormPgto = "Sem Pagamento"; break;
        case "99": nomeFormPgto = "Outros"; break;
      }

      const valor = parseFloat(valorSelected.replace(".", "").replace(",", "."));

      const temp: ICreatePedidosPgtosDTO = {
        forma_pgto: parseInt(formaPgtoSelected),
        valor,
        nome_forma_pgto: nomeFormPgto
      };

      if (parseFloat(`${(totalPago + valor)}`) > parseFloat(`${(totalProdutos - desconto).toFixed(2)}`))
        return alert("Valor informado maior do que o valor restante");

      setRequestPgtos(e => ([temp, ...e]));
    }

    setValorSelected("");
    setFormaPgtoSelectes("");
  }

  const handleDelPgto = (pgto: ICreatePedidosPgtosDTO) => {
    if (!window.confirm("Deseja realmente excluir essa forma de pagamento?"))
      return;

    const newList: ICreatePedidosPgtosDTO[] = [];

    requestPgtos.map((item) => {
      if (item.forma_pgto !== pgto.forma_pgto)
        newList.push(item);
    });

    setRequestPgtos(newList);
  }

  const handleSalvar = async (emitir: boolean) => {
    if (!token)
      return;

    if (!request.id_empresa || request.id_empresa.length <= 0)
      return alert("Selecione a Empresa");

    if (!request.id_cliente || request.id_cliente.length <= 0)
      return alert("Selecione um Cliente");

    if (requestProdutos.length <= 0)
      return alert("Adicione ao menos um produto");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Salvando pedido...");

    let idPedido = "";

    await api.post("pedidos", { ...request, desconto, total: totalProdutos, valor_pago: totalPago })
      .then((result) => {
        if (!result.data)
          return;

        idPedido = result.data.id;
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pedido: " + err.response.data.message);
      })
      .finally(() => {
        if (idPedido.length > 0)
          handleSalvaProdutos(idPedido, emitir);
        else {
          setLoading(false);
          setTxtLoading("");
        }
      });
  }

  const handleSalvaProdutos = async (idPedido: string, emitir: boolean) => {
    setTxtLoading("Salvando produtos ao pedido...");

    const newListProdutos: ICreatePedidosProdutosDTO[] = [];

    requestProdutos.map((item) => {
      newListProdutos.push({ ...item, id_pedidos: idPedido });
    });

    await api.post("pedidos/produtos", { data: newListProdutos })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Produtos: " + err.response.data.message);
      })
      .finally(() => {
        if (totalPago > 0)
          handleSalvaPgtos(idPedido, emitir);
        else
          navigate(-1);
      });
  }

  const handleSalvaPgtos = async (idPedido: string, emitir: boolean) => {
    setTxtLoading("Salvando pagamentos ao pedido...");

    const newListPgtos: ICreatePedidosPgtosDTO[] = [];

    requestPgtos.map((item) => {
      newListPgtos.push({ ...item, id_pedidos: idPedido });
    });

    await api.post("pedidos/pgtos", { data: newListPgtos })
      .then(() => { })
      .catch((err) => {
        if (err.response.status !== 401)
          alert("Pgtos: " + err.response.data.message);
      })
      .finally(() => {
        if (emitir)
          handleEmitirNFe(idPedido);
        else
          navigate(-1);
      });
  }

  const handleEmitirNFe = async (idPedido: string) => {
    setTxtLoading(`Solicitando emissão de NFe`);

    try {
      const reqPed = await api.get(`pedidos/${idPedido}`);

      if (!reqPed.data)
        throw new Error("Erro ao recuperar dados do Servidor");

      const p: IPedido = reqPed.data;

      const request: ICreateNfeDTO = {
        id_cliente: p.id_cliente,
        id_empresa: p.id_empresa,
        desconto: parseFloat(`${p.desconto}`),
        total: parseFloat(`${p.total}`)
      };

      const resNfe = await api.post("nfe", { ...request });

      if (!resNfe.data) throw new Error("Erro ao recuperar resposta do servidor.");

      const idNFe = resNfe.data.id;

      const newListProdutos: ICreateNfeProdutosDTO[] = [];

      p.pedidos.map((item) => {
        newListProdutos.push({
          id_nfe: idNFe,
          id_produto: item.id_produto,
          qtd: item.qtd,
          valor_unit: parseFloat(`${item.valor_unit}`)
        });
      });

      await api.post("nfe/produtos", { data: newListProdutos });

      const newListPgtos: ICreateNfePgtosDTO[] = [];

      p.pgtos.map((item) => {
        newListPgtos.push({
          id_nfe: idNFe,
          forma_pgto: item.forma_pgto,
          valor: parseFloat(`${item.valor}`)
        });
      });

      await api.post("nfe/pgtos", { data: newListPgtos });

      await api.post("nfe/emitir", { id: idNFe })

    } catch (err) {
      console.log(err);
      alert(`Erro ao enviar NFe`);
    }
    finally {
      navigate(-1);
    }

  }

  const handleFocusOutDesconto = (valor: string | null = null) => {
    const tempValor = valor === null ? inputDesconto : valor;

    let newDesconto = parseFloat(tempValor.replace(".", "").replace(",", "."));

    if (isNaN(newDesconto))
      newDesconto = 0;


    if (newDesconto > parseFloat((totalProdutos - totalPago).toFixed(2))) {
      setInputDesconto("");
      setDesconto(0);
      return alert("Valor de desconto maior do que o valor restante");
    }

    setDesconto(newDesconto);
  }

  const handleDescontoPorcentagem = (value: string) => {
    setInputDesconto(value);
    handleFocusOutDesconto(value);
  }

  const handleKeyClientes = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalCliente(true);
    }
  }

  const handleKeyProdutos = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalProdutos(true);
    }
  }

  const handleKeyCidades = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalCidades(true);
    }
  }

  const handleKeyQtdProduto = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddProduto();
    }
  }

  const handleKeyValorPgto = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddFormaPgto();
    }
  }

  useEffect(() => {
    loadEmpresas();
    loadCidades();
  }, [token]);

  useEffect(() => {
    if (qtdSelected < 1)
      setQtdSelected(1);
  }, [qtdSelected]);

  useEffect(() => {
    let t = 0;

    requestProdutos.map((item) => {
      t += item.valor_unit * item.qtd;
    });

    setTotalProdutos(t);

  }, [requestProdutos]);

  useEffect(() => {
    let t = 0;

    requestPgtos.map((item) => {
      t += item.valor;
    });

    setTotalPago(t);
  }, [requestPgtos]);

  return (
    <Topo page="Pedidos" title="Novo Pedido de NFe">

      <div className="pedidosForm">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <div className="form">
            <div className="contentInput" style={{ width: "50%" }}>
              <span>Empresa:</span>
              <select value={request.id_empresa} onChange={(e) => setRequest(y => ({ ...y, id_empresa: e.target.value }))}>
                <option value="">Selecione...</option>
                {listEmpresas.map((item) => (
                  <option key={item.id} value={item.id}>{item.fantasia}</option>
                ))}
              </select>
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Cliente:</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                <Input
                  mask="none"
                  value={clienteSelected.fantasia}
                  inputMaskChange={null}
                  placeholder="Selecione o Cliente"
                  readOnly
                  onKeyUp={handleKeyClientes}
                />
                <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                  <FontAwesomeIcon icon={faUsers} />
                </button>
              </div>
            </div>

            <div className="contentInput" style={{ width: "33%" }}>
              <span>Cidade:</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                <Input
                  mask="none"
                  value={cidadeSelected.nome ? `${cidadeSelected.nome}/${cidadeSelected.uf.uf}` : ""}
                  inputMaskChange={null}
                  placeholder="Cidade/SP"
                  onKeyUp={handleKeyCidades}
                  readOnly
                />
                <button className="btn-blue-dark" onClick={() => setModalCidades(true)}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </button>
              </div>
            </div>

            <div className="contentInput" style={{ width: "67%" }}>
              <span>Endereço:</span>
              <Input
                mask="none"
                value={request.endereco}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, endereco: v }))}
                placeholder="Endereço"
              />
            </div>

            <div className="contentInput" style={{ width: "20%" }}>
              <span>Número:</span>
              <Input
                mask="none"
                type="number"
                min={0}
                value={request.numero}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, numero: parseInt(v) }))}
                placeholder="Número"
              />
            </div>

            <div className="contentInput" style={{ width: "30%" }}>
              <span>Complemento:</span>
              <Input
                mask="none"
                value={request.complemento}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, complemento: v }))}
                placeholder="Complemento"
              />
            </div>

            <div className="contentInput" style={{ width: "25%" }}>
              <span>Bairro:</span>
              <Input
                mask="none"
                value={request.bairro}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, bairro: v }))}
                placeholder="Bairro"
              />
            </div>

            <div className="contentInput" style={{ width: "25%" }}>
              <span>CEP:</span>
              <Input
                mask="cep"
                value={request.cep}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, cep: v }))}
                placeholder="CEP"
                maxLength={9}
              />
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Local de venda</span>
              <Input
                mask="none"
                value={request.local_venda}
                inputMaskChange={(v: string) => setRequest(e => ({ ...e, local_venda: v }))}
                placeholder="Local de venda"
              />
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <div className="abas">Produtos</div>
              <div className="abasContent">

                <div className="form">

                  <div className="contentInput" style={{ width: "50%" }}>
                    <span>Produto:</span>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                      <Input
                        mask="none"
                        value={produtoSelected.nome ? produtoSelected.nome : ""}
                        inputMaskChange={null}
                        placeholder="Selecione o Produto"
                        onKeyUp={handleKeyProdutos}
                        readOnly
                      />
                      <button className="btn-blue-dark" onClick={() => setModalProdutos(true)}>
                        <FontAwesomeIcon icon={faBox} />
                      </button>
                    </div>
                  </div>

                  <div className="contentInput" style={{ width: "20%" }}>
                    <span>Quantidade</span>
                    <Input
                      mask="none"
                      value={qtdSelected}
                      inputMaskChange={setQtdSelected}
                      placeholder="Selecione a quantidade"
                      type="number"
                      min={1}
                      onKeyUp={handleKeyQtdProduto}
                    />
                  </div>

                  <div className="contentInput" style={{ width: "30%", textAlign: "left" }}>
                    {produtoSelected.nome && (
                      <p>{currencyFormatter(parseFloat(`${produtoSelected.preco}`))} x {qtdSelected} =  {currencyFormatter(parseFloat(`${produtoSelected.preco * qtdSelected}`))} </p>
                    )}
                  </div>

                  <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                    <button className="btn-green" style={{ width: "150px" }} onClick={handleAddProduto}>
                      <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                    </button>
                  </div>

                  <div className="gridContext">
                    <div className="headTable">
                      <span style={{ width: "200px" }}>Nome</span>
                      <span style={{ width: "50px", textAlign: "right" }}>Quantidade</span>
                      <span style={{ width: "70px", textAlign: "right" }}>V. Unit.</span>
                      <span style={{ width: "90px", textAlign: "right" }}>Total</span>
                      <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                    </div>

                    <div className="contentTable">
                      {requestProdutos.map((item) => (
                        <div className="rowTable" key={item.id_produto}>
                          <span style={{ width: "200px" }}>{item.nome_produto}</span>
                          <span style={{ width: "50px", textAlign: "right" }}>{item.qtd}</span>
                          <span style={{ width: "70px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit}`))}</span>
                          <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit * item.qtd}`))}</span>
                          <span style={{ width: "100px", textAlign: "center" }}>
                            <button className="btn-red" onClick={() => handleDelProduto(item)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </span>
                        </div>
                      ))}

                      <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", justifyContent: "flex-end" }}>
                        <span style={{ fontSize: "18px" }}>TOTAL: {currencyFormatter(parseFloat(`${totalProdutos}`))}</span>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <div className="abas">Pagamentos</div>
              <div className="abasContent">
                <div className="form">

                  <div className="contentInput" style={{ width: "50%" }}>
                    <span>Forma de Pagamento</span>
                    <select value={formaPgtoSelected} onChange={(e) => setFormaPgtoSelectes(e.target.value)}>
                      <option value="">Selecione...</option>
                      <option value="01">Dinheiro</option>
                      <option value="02">Cheque</option>
                      <option value="03">Cartão de Crédito</option>
                      <option value="04">Cartão de Débito</option>
                      <option value="05">Crédito Loja</option>
                      <option value="10">Vale Alimentação</option>
                      <option value="11">Vale Refeição</option>
                      <option value="12">Vale Presente</option>
                      <option value="13">Vale Combustível</option>
                      <option value="14">Duplicata Mercantil</option>
                      <option value="15">Boleto Bancário</option>
                      <option value="16">Depósito Bancário</option>
                      <option value="17">PIX</option>
                      <option value="18">Transferência Bancária, Carteira Digital</option>
                      <option value="19">Fidelidade(CASHBACK), Crédito Virtual</option>
                      <option value="90">Sem Pagamento</option>
                      <option value="99">Outros</option>
                    </select>
                  </div>

                  <div className="contentInput" style={{ width: "30%" }}>
                    <span>Valor</span>
                    <Input
                      mask="currency"
                      value={valorSelected}
                      inputMaskChange={setValorSelected}
                      placeholder="Informe o valor"
                      onKeyUp={handleKeyValorPgto}
                    />
                  </div>

                  <div className="contentInput" style={{ width: "17%", marginTop: "17px" }}>
                    <button className="btn-green" onClick={handleAddFormaPgto}>
                      <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                    </button>
                  </div>

                  <div className="gridContext">
                    <div className="headTable">
                      <span style={{ width: "200px" }}>Forma de Pagamento</span>
                      <span style={{ width: "90px", textAlign: "right" }}>Valor</span>
                      <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                    </div>

                    <div className="contentTable">
                      {requestPgtos.map((item) => (
                        <div className="rowTable" key={`${item.forma_pgto}`}>
                          <span style={{ width: "200px" }}>{item.nome_forma_pgto}</span>
                          <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                          <span style={{ width: "100px", textAlign: "center" }}>
                            <button className="btn-red" onClick={() => handleDelPgto(item)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </span>
                        </div>
                      ))}

                      <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
                        <div className="divDesconto">
                          <span>DESCONTO:</span>
                          <Input
                            mask="currency"
                            value={inputDesconto}
                            inputMaskChange={setInputDesconto}
                            placeholder="R$ 0,00"
                            style={{ textAlign: "right" }}
                            onBlur={() => handleFocusOutDesconto()}
                          />
                          <button className="btn-orange" onClick={() => setModalDesconto(true)}>
                            <FontAwesomeIcon icon={faPercent} />
                          </button>
                        </div>
                        <span style={{ fontSize: "16px" }}>TOTAL PAGO: {currencyFormatter(parseFloat(`${totalPago}`))}</span>
                        <span style={{ fontSize: "16px" }}>VALOR RESTANTE: {currencyFormatter(parseFloat(`${Math.abs(totalProdutos - totalPago - desconto)}`))}</span>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
              <button className="btnSalvar btn-blue-dark" onClick={() => handleSalvar(true)}>
                <FontAwesomeIcon icon={faSave} />
                SALVAR E EMITIR
              </button>

              <button className="btnSalvar btn-green" onClick={() => handleSalvar(false)}>
                <FontAwesomeIcon icon={faSave} />
                SALVAR
              </button>

              <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faUndo} />
                CANCELAR
              </button>
            </div>

          </div>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={handleSelectCliente} />
        </Modal>

        <Modal isOpen={modalProdutos} style={styleModal}>
          <PedidosModalProdutos closeModal={() => setModalProdutos(false)} handleSelectedProduto={handleSelectedProduto} />
        </Modal>

        <Modal isOpen={modalDesconto} style={{
          content: { ...styleModal.content, width: 335, height: 250 },
          overlay: { ...styleModal.overlay }
        }}>
          <PedidosModalDesconto closeModal={() => setModalDesconto(false)} totalProdutos={totalProdutos} handleDescontoPorcentagem={handleDescontoPorcentagem} />
        </Modal>

        <Modal isOpen={modalCidades} style={styleModal}>
          <ModalCidades closeModal={() => setModalCidades(false)} handleSelectCidade={handleSelectCidade} />
        </Modal>

      </div>
    </Topo>
  );
}