import { useState, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";

import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { api } from "../../services/api";

import bgLogin from "../../assets/bg_login.jpg";
import "./style.css";
import { useCache } from "../../hooks/useCache";

export function Login() {
  const { cacheTokens } = useCache();
  const navigate = useNavigate();

  const [usuario, setUsuario] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleEnviar = async () => {

    if (!usuario || usuario?.length < 3)
      return alert("Usuário não informado");

    if (!senha || senha?.length < 3)
      return alert("Senha não informado");

    setLoading(true);
    let liberado = false;

    await api
      .post("login", {
        user: usuario,
        senha
      })
      .then((response) => {
        liberado = true;
        cacheTokens(response.data.token, response.data.refresh_token, response.data.user);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      })
      .catch((err) => {
        // console.log(err.response.status);
        alert(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        if (liberado)
          navigate('/');
      });
  }

  const handleKeySenha = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleEnviar();
  }

  return (
    <div id="loginPage" style={{
      background: `url('${bgLogin}')`
    }}>
      <div className="form">

        {loading && (
          <Load txtLoading="Efetuando login..." options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <span>Usuário:</span>
            <Input
              mask="none"
              value={usuario}
              inputMaskChange={setUsuario}
              placeholder="Seu usuário"
            />

            <span>Senha:</span>
            <Input
              mask="none"
              value={senha}
              inputMaskChange={setSenha}
              placeholder="Sua senha"
              type="password"
              onKeyUp={handleKeySenha}
            />

            <button onClick={handleEnviar}>ENVIAR</button>
          </>
        )}

      </div>
    </div>
  );
}