import { faTimes, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { useCache } from "../../hooks/useCache";
import "./style.css";
import { IClientes, IListRequest } from "../../global/types";
import { checkToken } from "../../utils/checkToken";
import { api } from "../../services/api";
import { maskCpfCnpj } from "../../utils/masks";
import Modal from "react-modal";
import { CadClientes } from "../../components/CadClientes";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth - 50,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

type IProps = {
  closeModal: () => void;
  handleSelectCliente: (cliente: IClientes) => void;
}

export function PedidosModalCliente({ closeModal, handleSelectCliente }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [pesquisa, setPesquisa] = useState("");
  const [listClientes, setListClientes] = useState<IClientes[]>([]);

  const [modalCadCliente, setModalCadCliente] = useState(false);

  const handleKeyPesquisa = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handlePesquisa();
  }

  const handlePesquisa = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de clientes...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: IListRequest = {
      cursor: 0,
      limit: 25,
      pesquisa
    }

    await api.post("clientes/findAll", dataRequest)
      .then((response) => {
        if (!response.data)
          return;

        setListClientes(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleClick = (cliente: IClientes) => {
    handleSelectCliente(cliente);
    closeModal();
  }

  const loadFavoritos = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando lista de clientes favoritos...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("clientes/favoritos")
      .then((response) => {
        if (!response.data)
          return;

        setListClientes(response.data.result);
      })
      .catch(async (err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSaveCadCliente = (cliente: IClientes) => {
    setModalCadCliente(false);
    handleClick(cliente);
  }

  useEffect(() => {
    loadFavoritos();
  }, []);

  return (
    <div className="modal">
      <div className="title">
        <p>Lista de Clientes</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="info">
              <h4>Pesquisa</h4>
            </div>

            <div className="divFormModal">
              <Input
                autoFocus
                mask="none"
                value={pesquisa}
                inputMaskChange={setPesquisa}
                placeholder="Pesquisa..."
                onKeyUp={handleKeyPesquisa}
              />

              <button className="btn-blue-dark" onClick={handlePesquisa}>
                <FontAwesomeIcon icon={faSearch} />
              </button>

              <button className="btn-green" onClick={() => setModalCadCliente(true)}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>

            <div className="headTable">
              <span>Nome</span>
              <span>CNPJ/CPF</span>
            </div>
            <div className="contentTable">
              {listClientes.length > 0 && listClientes.map((item) => (
                <div className="rowTable" key={item.id} onClick={() => handleClick(item)}>
                  <span>{item.fantasia}</span>
                  <span>{maskCpfCnpj(`${item.cpf_cnpj}`)}</span>
                </div>
              ))}

              {listClientes.length === 0 && (
                <h4 style={{ color: "#7E7E7E", fontWeight: "500", textAlign: "center", marginTop: "10px" }}>Pesquise o nome do cliente</h4>
              )}

            </div>
          </>
        )}

      </div>

      <Modal isOpen={modalCadCliente} style={styleModal}>
        <div className="modal">
          <div className="title">
            <p>Cadastro de Cliente</p>
            <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={() => setModalCadCliente(false)} />
          </div>

          <div className="body" style={{ padding: "0px 15px" }}>
            <CadClientes
              onSave={(e) => handleSaveCadCliente(e)}
              tipo="Novo"
              showBtnVoltar={false}
            />
          </div>
        </div>
      </Modal>

    </div >
  );
}