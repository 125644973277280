import { faBox, faPlus, faSave, faTrash, faUndo, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Topo } from "../../components/Topo";
import { ICidades, IClientes, ICreateOrdemServicoDTO, ICreateOrdemServicoProdutosDTO, ICreateOrdemServicoServicosDTO, ICreateOrdemServicoStatusDTO, IEmpresa, IOrdemServico, IProduto, IServico, IStatus, IUsuario } from "../../global/types";
import { useCache } from "../../hooks/useCache";
import Modal from "react-modal";

import "./style.css";
import { PedidosModalCliente } from "../../modal/PedidosModalClientes";
import { PedidosModalProdutos } from "../../modal/PedidosModalProdutos";
import { checkToken } from "../../utils/checkToken";
import { api } from "../../services/api";
import { dynamicSort } from "../../utils/dynamicSort";
import { ModalSelectServicos } from "../../modal/ModalSelectServicos";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { maskPhone } from "../../utils/masks";
import { convertDateToBr, convertToDate, dateNow } from "../../utils/dateProvider";

const styleModal = {
  content: {
    padding: "0",
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerHeight - 50,
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "0px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "4"
  }
};

const defaultRequest: ICreateOrdemServicoDTO = {
  id_empresa: "",
  id_user: "",
  id_cliente: "",
  id_status: "",
  descricao: "",
  previsao: dateNow()
};

type IParams = {
  id: string;
}

type IProps = {
  tipo: "Novo" | "Edit";
}

export function OrdemServicoForm({ tipo }: IProps) {
  const navigate = useNavigate();
  const params = useParams() as IParams;

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [modalCliente, setModalCliente] = useState(false);
  const [modalProdutos, setModalProdutos] = useState(false);
  const [modalServicos, setModalServicos] = useState(false);

  const [listEmpresas, setListEmpresas] = useState<IEmpresa[]>([]);
  const [listStatus, setListStatus] = useState<IStatus[]>([]);
  const [listUsuarios, setListUsuarios] = useState<IUsuario[]>([]);

  const [clienteSelected, setClienteSelected] = useState<IClientes>({} as IClientes);
  const [statusSelected, setStatusSelected] = useState("");

  const [abaSelected, setAbaSelected] = useState("Produtos");

  const [produtoSelected, setProdutoSelected] = useState<IProduto>({} as IProduto);
  const [servicoSelected, setServicoSelected] = useState<IServico>({} as IServico);

  const [qtdProdutoSelected, setQtdProdutoSelected] = useState<number>(1);
  const [qtdServicoSelected, setQtdServicoSelected] = useState<number>(1);

  const [previsao, setPrevisao] = useState(convertDateToBr(dateNow()));

  const [request, setRequest] = useState<ICreateOrdemServicoDTO>(defaultRequest);
  const [requestProdutos, setRequestProdutos] = useState<ICreateOrdemServicoProdutosDTO[]>([]);
  const [requestServicos, setRequestServicos] = useState<ICreateOrdemServicoServicosDTO[]>([]);

  const [totalProdutos, setTotalProdutos] = useState(0);
  const [totalServicos, setTotalServicos] = useState(0);

  const loadEmpresas = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("empresas/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListEmpresas(result.data.result);

        if (result.data.total === 1)
          setRequest(y => ({ ...y, id_empresa: result.data.result[0].id }));

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadStatus();
      });
  }

  const loadStatus = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.post("status/findAll")
      .then((result) => {
        if (!result.data)
          return;

        setListStatus(result.data.result);

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadUsuarios();
      });
  }

  const loadUsuarios = async () => {
    if (!token)
      return;

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get("users/list")
      .then((result) => {
        if (!result.data)
          return;

        setListUsuarios(result.data.result);

      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        loadCadastro();
      });
  }

  const loadCadastro = async () => {
    if (tipo === "Novo" || !params.id || params.id.length <= 0) {
      setLoading(false);
      setTxtLoading("");
      return;
    }

    setTxtLoading("Carregando cadastro...");

    await api.get(`ordemServico/${params.id}`)
      .then(async (result) => {
        if (!result.data)
          return;

        const data = result.data as IOrdemServico;

        const requestCliente = await api.get(`clientes/${data.id_cliente}`);

        if (!requestCliente.data)
          return;

        setClienteSelected(requestCliente.data);

        setRequest({
          id_user: data.id_user,
          descricao: data.descricao,
          id_cliente: data.id_cliente,
          id_empresa: data.id_empresa,
          id_status: data.id_status,
          previsao: data.previsao
        });

        setPrevisao(convertDateToBr(data.previsao));

        const newListProd: ICreateOrdemServicoProdutosDTO[] = [];
        const newListServ: ICreateOrdemServicoServicosDTO[] = [];

        data.produtos.map((item) => {
          newListProd.push({
            id: item.id,
            id_ordem_servico: params.id,
            id_produto: item.id_produto,
            quantidade: item.quantidade,
            valor_unit: item.valor_unit,
            nome_produto: item.produto.nome
          });
        });

        data.servicos.map((item) => {
          newListServ.push({
            id: item.id,
            id_ordem_servico: params.id,
            id_servico: item.id_servico,
            quantidade: item.quantidade,
            valor_unit: item.valor_unit,
            nome_servico: item.servico.nome,
          });
        });

        setRequestProdutos(newListProd);
        setRequestServicos(newListServ);

      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleKeyClientes = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalCliente(true);
    }
  }

  const handleSelectCliente = (cliente: IClientes) => {
    setClienteSelected(cliente);
    setRequest(e => ({
      ...e,
      id_cliente: cliente.id
    }));
  }

  const handleSelectedProduto = (produto: IProduto) => {
    setProdutoSelected(produto);
  }

  const handleSelectedServico = (servico: IServico) => {
    setServicoSelected(servico);
  }

  const handleKeyProdutos = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      setModalProdutos(true);
    }
  }

  const handleKeyQtdProduto = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddProduto();
    }
  }

  const handleKeyQtdServico = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddServico();
    }
  }

  const handleAddProduto = () => {
    if (!produtoSelected.nome)
      return;

    const alredyExists: ICreateOrdemServicoProdutosDTO[] = [];
    requestProdutos.map((item) => {
      if (item.id_produto === produtoSelected.id && !item.id)
        alredyExists.push(item);
    });

    if (alredyExists.length > 0) {
      const newList: ICreateOrdemServicoProdutosDTO[] = [];

      requestProdutos.map((item) => {
        if (item.id_produto === produtoSelected.id && !item.id)
          newList.push({ ...item, quantidade: parseFloat(`${item.quantidade}`) + parseFloat(`${qtdProdutoSelected}`) });
        else
          newList.push(item);
      });

      setRequestProdutos(newList);
    } else {
      const temp: ICreateOrdemServicoProdutosDTO = {
        id_produto: produtoSelected.id,
        nome_produto: produtoSelected.nome,
        quantidade: qtdProdutoSelected,
        valor_unit: produtoSelected.preco,
        id_ordem_servico: ""
      };

      setRequestProdutos(e => ([temp, ...e]));
    }

    setProdutoSelected({} as IProduto);
    setQtdProdutoSelected(1);
  }

  const handleAddServico = () => {
    if (!servicoSelected.nome)
      return;

    const alredyExists: ICreateOrdemServicoServicosDTO[] = [];
    requestServicos.map((item) => {
      if (item.id_servico === servicoSelected.id && !item.id)
        alredyExists.push(item);
    });

    if (alredyExists.length > 0) {
      const newList: ICreateOrdemServicoServicosDTO[] = [];

      requestServicos.map((item) => {
        if (item.id_servico === servicoSelected.id && !item.id)
          newList.push({ ...item, quantidade: item.quantidade + qtdProdutoSelected });
        else
          newList.push(item);
      });

      setRequestServicos(newList);
    } else {
      const temp: ICreateOrdemServicoServicosDTO = {
        id_servico: servicoSelected.id,
        nome_servico: servicoSelected.nome,
        quantidade: qtdServicoSelected,
        valor_unit: servicoSelected.valor,
        id_ordem_servico: ""
      };

      setRequestServicos(e => ([temp, ...e]));
    }

    setServicoSelected({} as IServico);
    setQtdServicoSelected(1);
  }

  const handleDelProduto = (produto: ICreateOrdemServicoProdutosDTO) => {
    if (!window.confirm("Deseja realmente excluir este produto?"))
      return;

    const newList: ICreateOrdemServicoProdutosDTO[] = [];

    requestProdutos.map((item) => {
      if (item.id_produto !== produto.id_produto)
        newList.push(item);
    })

    setRequestProdutos(newList);
  }

  const handleDelServico = (servico: ICreateOrdemServicoServicosDTO) => {
    if (!window.confirm("Deseja realmente excluir este serviço?"))
      return;

    const newList: ICreateOrdemServicoServicosDTO[] = [];

    requestServicos.map((item) => {
      if (item.id_servico !== servico.id_servico)
        newList.push(item);
    })

    setRequestServicos(newList);
  }

  const handleSalvar = async () => {
    if (!token)
      return;

    if (previsao.length < 10)
      return alert("Preencha corretamente a previsão");

    if (!request.id_empresa || request.id_empresa.length <= 0)
      return alert("Selecione a Empresa");

    if (!request.id_cliente || request.id_cliente.length <= 0)
      return alert("Selecione um Cliente");

    if (!request.id_user || request.id_user.length <= 0)
      return alert("Selecione um Responsável");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    setLoading(true);
    setTxtLoading("Salvando ordem de serviço...");

    try {

      let data: ICreateOrdemServicoDTO = {
        ...request,
        previsao: convertToDate(previsao),
        id_status: statusSelected
      };

      if (tipo === "Edit")
        data = { ...data, id: params.id };

      console.log(data);

      const requestOrdemServico = await api.post("ordemServico", { ...data });

      if (!requestOrdemServico)
        return;

      const ordemServico: IOrdemServico = requestOrdemServico.data;

      const dataReqProdutos: ICreateOrdemServicoProdutosDTO[] = [];
      const dataReqServicos: ICreateOrdemServicoServicosDTO[] = [];
      const dataReqStatus: ICreateOrdemServicoStatusDTO[] = [];

      dataReqStatus.push({
        id_ordem_servico: ordemServico.id,
        id_status: statusSelected
      });

      requestProdutos.map((item) => {
        dataReqProdutos.push({ ...item, id_ordem_servico: ordemServico.id });
      });

      requestServicos.map((item) => {
        dataReqServicos.push({ ...item, id_ordem_servico: ordemServico.id });
      });

      if (tipo === "Novo") {
        setTxtLoading("Salvando status da Ordem de Serviço...");
        await api.post("ordemServico/status", { data: dataReqStatus });
      }

      setTxtLoading("Salvando Produtos...");
      await api.post("ordemServico/produto", { data: dataReqProdutos });

      setTxtLoading("Salvando Serviços...");
      await api.post("ordemServico/servico", { data: dataReqServicos });

      navigate("/ordemServico");
      setAlert("Ordem de Serviço salva com sucesso", "success");

    } catch (err) {
      console.log(err);
      const teste: any = err;

      if (teste.response.data.message)
        setAlert(teste.response.data.message, "danger");
      else
        setAlert("Erro ao salvar Ordem de Serviço", "danger");

      setLoading(false);
      setTxtLoading("");
    }
  }

  useEffect(() => {
    setLoading(true);
    setTxtLoading("Carregando...");
    loadEmpresas();
  }, [token]);

  useEffect(() => {
    if (qtdProdutoSelected < 1)
      setQtdProdutoSelected(1);
  }, [qtdProdutoSelected]);

  useEffect(() => {
    if (qtdServicoSelected < 1)
      setQtdServicoSelected(1);
  }, [qtdServicoSelected]);

  useEffect(() => {
    let t = 0;

    requestProdutos.map((item) => {
      t += item.valor_unit * item.quantidade;
    });

    setTotalProdutos(t);

  }, [requestProdutos]);

  useEffect(() => {
    let t = 0;

    requestServicos.map((item) => {
      t += item.valor_unit * item.quantidade;
    });

    setTotalServicos(t);

  }, [requestServicos]);

  return (
    <Topo page="OrdemServico" title={`${tipo === "Novo" ? "Cadastro" : "Edição"} de Ordem de Serviço`}>
      <div id="OrdemServicoFormPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 120, width: 120 }} />
        )}

        {!loading && (
          <div className="form">

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Empresa:</span>
              <select value={request.id_empresa} onChange={(e) => setRequest(y => ({ ...y, id_empresa: e.target.value }))}>
                <option value="">Selecione...</option>
                {listEmpresas.map((item) => (
                  <option key={item.id} value={item.id}>{item.fantasia}</option>
                ))}
              </select>
            </div>

            <div className="contentInput" style={{ width: "50%" }}>
              <span>Cliente:</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                <Input
                  mask="none"
                  value={clienteSelected.fantasia}
                  inputMaskChange={null}
                  placeholder="Selecione o Cliente"
                  readOnly
                  onKeyUp={handleKeyClientes}
                />
                <button className="btn-blue-dark" onClick={() => setModalCliente(true)}>
                  <FontAwesomeIcon icon={faUsers} />
                </button>
              </div>
            </div>

            {clienteSelected.id && (
              <div className="infoCliente">
                <span style={{ width: "50%" }}>
                  <b>Nome Fantasia:</b>
                  {clienteSelected.fantasia}
                </span>
                <span style={{ width: "50%" }}>
                  <b>Razão Social:</b>
                  {clienteSelected.razao_social}
                </span>
                <span style={{ width: "34%" }}>
                  <b>E-mail:</b>
                  {clienteSelected.email}
                </span>
                <span style={{ width: "33%" }}>
                  <b>Telefone:</b>
                  {maskPhone(`${clienteSelected.telefone}`)}
                </span>
                <span style={{ width: "33%" }}>
                  <b>Celular:</b>
                  {maskPhone(`${clienteSelected.celular}`)}
                </span>
                <span style={{ width: "100%" }}>
                  <b>Endereço:</b>
                  {clienteSelected.endereco}, nº {clienteSelected.numero} - {clienteSelected.bairro} - {clienteSelected.cidade.nome}/{clienteSelected.cidade.uf.uf}
                </span>
              </div>
            )}

            <div className="contentInput" style={{ width: "33%" }}>
              <span>Responsável:</span>
              <select value={request.id_user} onChange={(e) => setRequest(y => ({ ...y, id_user: e.target.value }))}>
                <option value="">Selecione...</option>
                {listUsuarios.map((item) => (
                  <option key={item.id} value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>

            <div className="contentInput" style={{ width: "33%" }}>
              <span>Previsão:</span>
              <Input
                mask="date"
                value={previsao}
                inputMaskChange={setPrevisao}
                placeholder="dd/mm/aaaa"
                maxLength={10}
              />
            </div>

            {tipo === "Novo" && (
              <div className="contentInput" style={{ width: "33%" }}>
                <span>Status Inicial:</span>
                <select value={statusSelected} onChange={(e) => setStatusSelected(e.target.value)}>
                  <option value="">Selecione...</option>
                  {listStatus.length > 0 && listStatus.sort(dynamicSort("ordem")).map((item) => (
                    <option key={item.id} value={item.id}>{item.nome}</option>
                  ))}
                </select>
              </div>
            )}

            <div className="contentInput" style={{ width: "100%" }}>
              <span>Descrição</span>
              <textarea
                placeholder="Digite aqui a descrição da ordem de serviço"
                value={request.descricao}
                onChange={e => setRequest(y => ({ ...y, descricao: e.target.value }))}
              />
            </div>

            <div className="contentInput" style={{ width: "100%" }}>
              <div className="headerAbas">
                <div className={`abas ${abaSelected === "Produtos" ? 'selected' : ''}`} onClick={() => setAbaSelected("Produtos")}>Produtos</div>
                <div className={`abas ${abaSelected === "Serviços" ? 'selected' : ''}`} onClick={() => setAbaSelected("Serviços")}>Serviços</div>
              </div>
              <div className="abasContent">

                <div className="form">

                  {abaSelected === "Produtos" && (
                    <>
                      <div className="contentInput" style={{ width: "50%" }}>
                        <span>Produto:</span>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                          <Input
                            mask="none"
                            value={produtoSelected.nome ? produtoSelected.nome : ""}
                            inputMaskChange={null}
                            placeholder="Selecione o Produto"
                            onKeyUp={handleKeyProdutos}
                            readOnly
                          />
                          <button className="btn-blue-dark" onClick={() => setModalProdutos(true)}>
                            <FontAwesomeIcon icon={faBox} />
                          </button>
                        </div>
                      </div>

                      <div className="contentInput" style={{ width: "20%" }}>
                        <span>Quantidade</span>
                        <Input
                          mask="none"
                          value={qtdProdutoSelected}
                          inputMaskChange={setQtdProdutoSelected}
                          placeholder="Selecione a quantidade"
                          type="number"
                          min={1}
                          onKeyUp={handleKeyQtdProduto}
                        />
                      </div>

                      <div className="contentInput" style={{ width: "30%", textAlign: "left" }}>
                        {produtoSelected.nome && (
                          <p>{currencyFormatter(parseFloat(`${produtoSelected.preco}`))} x {qtdProdutoSelected} =  {currencyFormatter(parseFloat(`${produtoSelected.preco * qtdProdutoSelected}`))} </p>
                        )}
                      </div>

                      <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                        <button className="btn-green" style={{ width: "150px" }} onClick={handleAddProduto}>
                          <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                        </button>
                      </div>

                      <div className="gridContext">
                        <div className="headTable">
                          <span style={{ width: "200px" }}>Nome</span>
                          <span style={{ width: "50px", textAlign: "right" }}>Quantidade</span>
                          <span style={{ width: "70px", textAlign: "right" }}>V. Unit.</span>
                          <span style={{ width: "90px", textAlign: "right" }}>Total</span>
                          <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                        </div>

                        <div className="contentTable">
                          {requestProdutos.map((item) => (
                            <div className="rowTable" key={item.id_produto}>
                              <span style={{ width: "200px" }}>{item.nome_produto}</span>
                              <span style={{ width: "50px", textAlign: "right" }}>{item.quantidade}</span>
                              <span style={{ width: "70px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit}`))}</span>
                              <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit * item.quantidade}`))}</span>
                              <span style={{ width: "100px", textAlign: "center" }}>
                                {!item.id && (
                                  <button className="btn-red" onClick={() => handleDelProduto(item)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                )}
                              </span>
                            </div>
                          ))}

                          <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", justifyContent: "flex-end" }}>
                            <span style={{ fontSize: "18px" }}>TOTAL: {currencyFormatter(parseFloat(`${totalProdutos}`))}</span>
                          </div>

                        </div>
                      </div>

                    </>
                  )}

                  {abaSelected === "Serviços" && (
                    <>
                      <div className="contentInput" style={{ width: "50%" }}>
                        <span>Serviço:</span>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "10px" }}>
                          <Input
                            mask="none"
                            value={servicoSelected.nome ? servicoSelected.nome : ""}
                            inputMaskChange={null}
                            placeholder="Selecione o Serviço"
                            onKeyUp={handleKeyProdutos}
                            readOnly
                          />
                          <button className="btn-blue-dark" onClick={() => setModalServicos(true)}>
                            <FontAwesomeIcon icon={faBox} />
                          </button>
                        </div>
                      </div>

                      <div className="contentInput" style={{ width: "20%" }}>
                        <span>Quantidade</span>
                        <Input
                          mask="none"
                          value={qtdServicoSelected}
                          inputMaskChange={setQtdServicoSelected}
                          placeholder="Selecione a quantidade"
                          type="number"
                          min={1}
                          onKeyUp={handleKeyQtdServico}
                        />
                      </div>

                      <div className="contentInput" style={{ width: "30%", textAlign: "left" }}>
                        {servicoSelected.nome && (
                          <p>{currencyFormatter(parseFloat(`${servicoSelected.valor}`))} x {qtdServicoSelected} =  {currencyFormatter(parseFloat(`${servicoSelected.valor * qtdServicoSelected}`))} </p>
                        )}
                      </div>

                      <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                        <button className="btn-green" style={{ width: "150px" }} onClick={handleAddServico}>
                          <FontAwesomeIcon icon={faPlus} /> ADICIONAR
                        </button>
                      </div>

                      <div className="gridContext">
                        <div className="headTable">
                          <span style={{ width: "200px" }}>Nome</span>
                          <span style={{ width: "50px", textAlign: "right" }}>Quantidade</span>
                          <span style={{ width: "70px", textAlign: "right" }}>V. Unit.</span>
                          <span style={{ width: "90px", textAlign: "right" }}>Total</span>
                          <span style={{ width: "100px", textAlign: "center" }}>Ações</span>
                        </div>

                        <div className="contentTable">
                          {requestServicos.map((item) => (
                            <div className="rowTable" key={item.id_servico}>
                              <span style={{ width: "200px" }}>{item.nome_servico}</span>
                              <span style={{ width: "50px", textAlign: "right" }}>{item.quantidade}</span>
                              <span style={{ width: "70px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit}`))}</span>
                              <span style={{ width: "90px", textAlign: "right" }}>{currencyFormatter(parseFloat(`${item.valor_unit * item.quantidade}`))}</span>
                              <span style={{ width: "100px", textAlign: "center" }}>
                                {!item.id && (
                                  <button className="btn-red" onClick={() => handleDelServico(item)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                )}
                              </span>
                            </div>
                          ))}

                          <div className="rowTable" style={{ background: "#477ff4", color: "#FFF", justifyContent: "flex-end" }}>
                            <span style={{ fontSize: "18px" }}>TOTAL: {currencyFormatter(parseFloat(`${totalServicos}`))}</span>
                          </div>

                        </div>
                      </div>

                    </>
                  )}

                </div>

              </div>
            </div>


            <div className="contentBtns" style={{ width: "100%", alignItems: "center" }}>
              <button className="btnSalvar btn-green" onClick={handleSalvar}>
                <FontAwesomeIcon icon={faSave} />
                SALVAR
              </button>

              <button className="btnSalvar btn-orange" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faUndo} />
                CANCELAR
              </button>
            </div>

          </div>
        )}

        <Modal isOpen={modalCliente} style={styleModal}>
          <PedidosModalCliente closeModal={() => setModalCliente(false)} handleSelectCliente={(e) => handleSelectCliente(e)} />
        </Modal>

        <Modal isOpen={modalProdutos} style={styleModal}>
          <PedidosModalProdutos closeModal={() => setModalProdutos(false)} handleSelectedProduto={(e) => handleSelectedProduto(e)} />
        </Modal>

        <Modal isOpen={modalServicos} style={styleModal}>
          <ModalSelectServicos closeModal={() => setModalServicos(false)} handleSelectedServico={(e) => handleSelectedServico(e)} />
        </Modal>

      </div>
    </Topo>
  )
}