import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Input } from "../../components/Input";

import "./style.css";

type IProps = {
  closeModal: () => void;
  handleEnviar: (motivo: string) => void;
}

export function NfeModalMotivo({ closeModal, handleEnviar }: IProps) {

  const [motivo, setMotivo] = useState("");

  const handleEnviaCancelamento = () => {
    if (motivo.length < 15)
      return alert("Informe o motivo do cancelamento com no mínimo 15 caracteres");

    if (!window.confirm("Deseja realmente cancelar essa NFe?"))
      return;

    handleEnviar(motivo);
  }

  return (
    <div id="modalMotivo">
      <div className="title">
        <p>Motivo do cancelamento</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        <span>Motivo</span>
        <Input
          mask="none"
          value={motivo}
          inputMaskChange={setMotivo}
          placeholder="Motivo do cancelamento"
        />

        <button className="btn-green" onClick={() => handleEnviaCancelamento()}>
          <FontAwesomeIcon icon={faCheck} /> ENVIAR
        </button>

      </div>

    </div>
  );
}