import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faSave, faPlus, faPen } from "@fortawesome/free-solid-svg-icons";

import { ICreateVariantesValoresDTO, IVariantesValores } from "../../global/types";

import { Load } from "../../components/Load";
import { api } from "../../services/api";
import { useCache } from "../../hooks/useCache";
import { checkToken } from "../../utils/checkToken";

import "./style.css";
import { Input } from "../../components/Input";

type IProps = {
  id: string;
  title: string;
  closeModal: () => void;
}

export function VariantesValoresModal({ id, title, closeModal }: IProps) {
  const navigate = useNavigate();

  const { refreshToken, usuario, cacheTokens, token, setAlert } = useCache();
  const cacheError = { refreshToken, usuario, cacheTokens };

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");
  const [tipoForm, setTipoForm] = useState<"" | "Novo" | "Editar">("");

  const [nome, setNome] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [nomeEdit, setNomeEdit] = useState("");

  const [listValores, setListValores] = useState<IVariantesValores[]>([]);

  const loadValores = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Carregando dados...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.get(`variantes/${id}`)
      .then((result) => {
        if (!result.data)
          return;

        const list: IVariantesValores[] = result.data.variante_valores.map((item: IVariantesValores) => {
          return item;
        });

        setListValores(list);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleDelete = async (itemId: string) => {
    if (!token)
      return;

    if (!window.confirm("Deseja realmente excluir?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    await api.delete("variantes/valores", { data: { id: itemId } })
      .then(() => {
        const newList: IVariantesValores[] = [];

        listValores.map((item) => {
          if (item.id !== itemId)
            newList.push(item);
        })

        setListValores(newList);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          setAlert(err.response.data.message, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const handleKeyNome = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleSave();
  }

  const handleEdit = (itemId: string, itemNome: string) => {
    setNome(itemNome);
    setTipoForm("Editar");
    setNomeEdit(itemNome);
    setIdEdit(itemId);
  }

  const handleNovo = () => {
    setNome("");
    setTipoForm("Novo");
  }

  const handleCancel = () => {
    setNome("");
    setTipoForm("");
    setIdEdit("");
    setNomeEdit("");
  }

  const handleSave = async () => {
    if (!token)
      return;

    setLoading(true);
    setTxtLoading("Salvando...");

    const rsCheckToken = await checkToken(cacheError, token);
    if (rsCheckToken.redirectLogin)
      navigate("/login");

    let dataRequest: ICreateVariantesValoresDTO = {
      id_variante: id,
      nome
    };

    if (tipoForm === "Editar")
      dataRequest = { ...dataRequest, id: idEdit };

    await api.post("variantes/valores", { data: [{ ...dataRequest }] })
      .then(() => {
        loadValores();
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert(`Erro - ${err.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
        handleCancel();
      })

  }

  useEffect(() => {
    loadValores();
  }, [token]);

  return (
    <div className="modal">
      <div className="title">
        <p>{title}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="info">
              <h4>
                {tipoForm === "Novo" && (`Cadastrar`)}
                {tipoForm === "Editar" && (`Editando ${nomeEdit}`)}
              </h4>

              {tipoForm === "" && (
                <button className="btn-blue-dark btnNovo" onClick={handleNovo}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}

            </div>

            {tipoForm !== "" && (
              <div className="divFormModal">
                <Input
                  mask="none"
                  value={nome}
                  inputMaskChange={setNome}
                  placeholder="Nome"
                  onKeyUp={handleKeyNome}
                />

                <button className="btn-green" onClick={handleSave}>
                  <FontAwesomeIcon icon={faSave} />
                </button>

                {tipoForm === "Editar" && (
                  <button className="btn-orange" onClick={handleCancel}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}

              </div>
            )}

            <div className="headTable">
              <span>Nome</span>
            </div>
            <div className="contentTable">
              {listValores.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span>

                    <button className="btn-orange" onClick={() => handleEdit(item.id, item.nome)}>
                      <FontAwesomeIcon icon={faPen} />
                    </button>

                    <button className="btn-red" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {item.nome}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

    </div >
  );
}